import "../../styles/animations/wickedcss.min.css";
import logo from '../../images/logo/auteco-logo.png'
import logo_upb from '../../images/logo/logo-upb.png'
import TermsModal from "../../components/modals/Terms";

const Login = ({text}) => {
  return (
    <div className="">
      <div className="fadeIn welcome">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ marginTop: "50px", fontSize: "50px" }}
        >
          <img src={logo} width={280} alt="Logo Auteco" />
        </div>
        <div
          className="d-flex justify-content-center align-items-center subtitle"
          style={{ marginTop: "20px", fontSize: "50px", fontWeight: "bold", textAlign: "center" }}
        >
          {text}
        </div>
        <div>
          <div className="mt-4 d-flex align-items-center justify-content-center">
            <a href="https://lucia.com.co/">
              <button>Iniciar sesión</button>
            </a>
          </div>
          <br /><br /><br />
        </div>
      </div>
      <div className="main-footer">
        <div className="terms"><TermsModal text={'Términos y condiciones'} title={'Términos y condiciones'} /></div>
        <div className="upb-logo"><img src={logo_upb} width={50} alt="" /></div>
      </div>
    </div>

  );
};

export default Login;
