import React, { useState, useEffect } from 'react';
import UpdateService from '../../../../services/admin/updates/updateService';
import GetDataDetail from '../../../../services/loaderService';
import { BiCheck } from 'react-icons/bi';
import config from '../../../../config';

const UpdateAxes = ({ id }) => {

    const [datas, setDatas] = useState([]);
    const domain = config.api_domain.domain
    const url = `${domain}/axes`

    useEffect(() => {
        GetDataDetail(setDatas, url, id)
    }, [setDatas, url, id]);

    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (datas.length > 0) {
            setFormData({
                tractor_axis: datas[0].tractor_axis || "",
                front_axle_brand: datas[0].front_axle_brand || "",
                front_axle_ref: datas[0].front_axle_ref || "",
                max_cap_front_axle: datas[0].max_cap_front_axle,
                cap_spsn_front_axle: datas[0].cap_spsn_front_axle,
                back_axle_brand: datas[0].back_axle_brand || "",
                back_axle_ref: datas[0].back_axle_ref || "",
                max_cap_back_axle: datas[0].max_cap_back_axle,
                cap_spsn_back_axle: datas[0].cap_spsn_back_axle,
                tractor_axle_relation: datas[0].tractor_axle_relation,
            });
        }
    }, [datas]);

    const [showForm, setShowForm] = useState(false);
    const [showUpdatedText, setShowUpdatedText] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        UpdateService('updateAxes', id, formData)
            .then(() => {
                setShowForm(false);
                setShowUpdatedText(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleOpenForm = () => {
        setShowForm(true);
        setShowUpdatedText(false);
    };

    return (
        <div>
            {showForm ? (
                <>
                    <div>
                        <label className='modal-lavel'>
                            Eje tractor
                            <input
                                type="text"
                                className='form-control'
                                name="tractor_axis"
                                value={formData.tractor_axis}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Relación eje tractor
                            <input
                                type="number"
                                className='form-control'
                                name="tractor_axle_relation"
                                value={formData.tractor_axle_relation}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Marca eje delantero
                            <input
                                type="text"
                                className='form-control'
                                name="front_axle_brand"
                                value={formData.front_axle_brand}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Referencia eje delantero
                            <input
                                type="text"
                                className='form-control'
                                name="front_axle_ref"
                                value={formData.front_axle_ref}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Capacidad máxima eje delantero [kg]
                            <input
                                type="number"
                                className='form-control'
                                name="max_cap_front_axle"
                                value={formData.max_cap_front_axle}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Capacidad de supensión eje delantero [kg]
                            <input
                                type="number"
                                className='form-control'
                                name="cap_spsn_front_axle"
                                value={formData.cap_spsn_front_axle}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Marca eje trasero
                            <input
                                type="text"
                                className='form-control'
                                name="back_axle_brand"
                                value={formData.back_axle_brand}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Referencia eje trasero
                            <input
                                type="text"
                                className='form-control'
                                name="back_axle_ref"
                                value={formData.back_axle_ref}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Capacidad máxima eje trasero [kg]
                            <input
                                type="number"
                                className='form-control'
                                name="max_cap_back_axle"
                                value={formData.max_cap_back_axle}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Capacidad de supensión eje trasero [kg]
                            <input
                                type="number"
                                className='form-control'
                                name="cap_spsn_back_axle"
                                value={formData.cap_spsn_back_axle}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        <br />
                        <button onClick={handleSubmit}>Actualizar Ejes</button>
                    </div>
                </>
            ) : (
                <>
                    <p>Ejes actualizados <BiCheck style={{ fontSize: '30px', color: 'green' }} /></p>
                    <button onClick={handleOpenForm}>Editar Ejes</button>
                </>
            )}
        </div>
    );
};

export default UpdateAxes;