import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GetEnvData from "../../services/loaderEnvData";
import GetConsumptionData from "../../services/loaderConsumption";
import GetBateryData from "../../services/loaderBateryData";
import LoaderFuelByType from "../../services/loaderFuelByType";
import GetFinancialData from "../../services/loaderFinancialData";
import GetMaintenanceData from "../../services/loaderMaintenance";
import LoaderAssurance from "../../services/loaderAssurance";
import loaderProductivity from "../../services/loaderProductivity";
import LoaderScraping from "../../services/scrapingLoader";
import TooltipModal from "./ToolTipModal";
import Swal from "sweetalert2";
import UpperFormatt from "../UpperFormatt";

import "../../styles/modal.css";
import "../../styles/global.css"

const ModalParams = ({ text, icon }) => {
  const params = useParams();
  const id = params.id;
  const type = params.type;

  const [yearsUse, setYearsUse] = useState();
  const [annualUse, setAnnualUse] = useState();
  const [fuel, setFuel] = useState();
  const [residualFactor, setResidualFactor] = useState();

  const [priceVariating, setPriceVariating] = useState();
  const [city, setCity] = useState()

  const [fuelVehicle, setFuelVehicle] = useState([]);
  const [fuelVehicleInput, setFuelVehicleInput] = useState(' ');
  const [loading, setLoading] = useState();
  const [progress, setProgress] = useState(0);
  const [isLoan, setIsLoan] = useState(true);

  // To delete
  const [isChecked, setIsChecked] = useState(true);
  const [isValue, setValue] = useState(false);
  const [loan, setLoan] = useState();
  const [inputValueLoan, setInputValueLoan] = useState();

  const [inputValueFinancing, setInputValueFinancing] = useState(100);
  const [financing, setFinancing] = useState(100);
  const [interestEM, setInterestEM] = useState();
  const [interestEM_fuel, setInterestEM_fuel] = useState();
  const [installments, setInstallments] = useState();
  const [assuranceRisk, setAssuranceRisk] = useState();
  const [assuranceRiskFuel, setAssuranceRiskFuel] = useState();
  const [hourValue, setHourValue] = useState();
  const [ppDays, setPpDays] = useState();
  const [pphours, setPpHours] = useState();
  const [opRange, setOpRange] = useState();
  const [energyPrice, setEnergyPrice] = useState();
  const [fuelPrice, setFuelPrice] = useState();

  const dataParams = {
    id: id,
    type: type,
    yearsUse: yearsUse,
    annualUse: annualUse,
    fuel: fuel,
    residualFactor: residualFactor,
    priceVariating: priceVariating,
    city: city,
    idFuelVehicle: fuelVehicleInput,
    loan: loan,
    financing: financing,
    interestEM: interestEM,
    interestEM_fuel: interestEM_fuel,
    installments: installments,
    taxes: true,
    assuranceRisk: assuranceRisk,
    assuranceRiskFuel: assuranceRiskFuel,
    hourValue: hourValue,
    ppDays: ppDays,
    pphours: pphours,
    opRange: opRange,
    energyPrice: energyPrice,
    fuelPrice: fuelPrice,
  };

  useEffect(() => {
    //get params to set in the inputs
    const storedParams = JSON.parse(localStorage.getItem("dataParams"));
    if (storedParams) {
      setYearsUse(storedParams.yearsUse);
      setAnnualUse(storedParams.annualUse);
      setFuel(storedParams.fuel);
      setResidualFactor(storedParams.residualFactor);

      setPriceVariating(storedParams.priceVariating);
      
      setCity(storedParams.city)
      setInterestEM(storedParams.interestEM);
      setInterestEM_fuel(storedParams.interestEM_fuel);
      setHourValue(storedParams.hourValue);
      setAssuranceRisk(storedParams.assuranceRisk);
      setAssuranceRiskFuel(storedParams.assuranceRiskFuel);
      setOpRange(storedParams.opRange);
      setInputValueFinancing(storedParams.financing)
      setFinancing(storedParams.financing)
      setPpDays(storedParams.ppDays);
      setPpHours(storedParams.pphours);
      setInstallments(storedParams.installments);
      setEnergyPrice(storedParams.energyPrice);
      setFuelPrice(storedParams.fuelPrice);
    }
  }, []);

  //Progress bar
  useEffect(() => {
    const progressBar = document.querySelector(".progress-bar");
    progressBar.style.width = `${progress}%`;
    progressBar.setAttribute("aria-valuenow", progress);
    progressBar.textContent = `${progress}%`;
  }, [progress]);

  const handleButtonClick = async (event) => {
    event.preventDefault();
    try {
      const confirmed = await Swal.fire({
        title: "¿Estás seguro de que deseas calcular?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "my-confirm-button",
        },
      }).then((result) => result.isConfirmed);

      if (confirmed) {
        setLoading(true);

        const dataScraping = await LoaderScraping(fuelPrice, energyPrice);

        localStorage.setItem("dataParams", JSON.stringify(dataParams));
        setProgress(10);

        const dataEnvironment = await GetEnvData(
          id,
          fuelVehicleInput,
          annualUse,
          JSON.stringify(dataScraping)
        );

        localStorage.setItem("environment", JSON.stringify(dataEnvironment));
        setProgress(20);

        const dataConsuption = await GetConsumptionData(
          id,
          fuelVehicleInput,
          annualUse,
          yearsUse,
          JSON.stringify(dataScraping)
        );

        localStorage.setItem("consuption", JSON.stringify(dataConsuption));
        setProgress(30);

        const dataBatery = await GetBateryData(
          id,
          type,
          residualFactor,
          annualUse,
          yearsUse,
          JSON.stringify(dataScraping)
        );
        localStorage.setItem("batery", JSON.stringify(dataBatery));
        setProgress(40);

        const dataFinancial = await GetFinancialData(
          id,
          financing,
          loan,
          interestEM,
          interestEM_fuel,
          installments,
          fuelVehicleInput
        );
        localStorage.setItem("financial", JSON.stringify(dataFinancial));
        setProgress(60);

        const dataMaintenance = await GetMaintenanceData(
          id,
          type,
          annualUse,
          yearsUse,
          fuelVehicleInput,
          JSON.stringify(dataScraping)
        );
        localStorage.setItem("maintenance", JSON.stringify(dataMaintenance));
        setProgress(70);

        const dataAssurance = await LoaderAssurance(
          id,
          type,
          true,
          assuranceRisk,
          assuranceRiskFuel,
          yearsUse,
          city,
          JSON.stringify(dataScraping)
        );
        localStorage.setItem("assurance", JSON.stringify(dataAssurance));
        setProgress(80);

        const dataProductivity = await loaderProductivity(
          id,
          fuel,
          annualUse,
          yearsUse,
          opRange,
          hourValue,
          pphours,
          ppDays,
          fuelVehicleInput,
          JSON.stringify(dataScraping)
        );

        localStorage.setItem("productivity", JSON.stringify(dataProductivity));
        setProgress(90);

        setLoading(false);
        setProgress(100);

        Swal.fire({
          title: "Cálculos terminados",
          text: "Los cálculos han finalizado",
          icon: "success",
          position: "bottom-end",
          showConfirmButton: false,
        });

        setTimeout(function () {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleYearsUse = (e) => {
    setYearsUse(e.target.value);
  };
  const handleAnnualUse = (e) => {
    setAnnualUse(e.target.value);
  };

  const handleChangeResidualFactore = (e) => {
    setResidualFactor(e.target.value);
  };
  const handleChangePriceVariating = (e) => {
    setPriceVariating(e.target.value);
  };

  const handleChangeCity = (e) => {
    setCity(e.target.value);
  };


  const handleSelectFuelVehicles = (e) => {
    setFuelVehicleInput(parseInt(e.target.value));
    setFuel(e.target.options[e.target.selectedIndex].getAttribute('fuel') );
  };


  const GetFuelVehicles = async () => {
    const data = await LoaderFuelByType(type);
    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await GetFuelVehicles();
      setFuelVehicle(data);
      setFuelVehicleInput(data[0].id)
    };
    fetchData();
  }, []);

  const handleChangeFinancing = (e) => {
    setInputValueFinancing(e.target.value);
    setFinancing(e.target.value);
  };
  const handleChangeLoan = (e) => {
    setInputValueLoan(e.target.value);
    setLoan(e.target.value);
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked === true) {
      setIsLoan(true);
      setValue(false);
    } else {
      setIsLoan(false);
      setValue(true);
    }

    setInputValueLoan("");
    setInputValueFinancing("");
  };
  const handleChangeInsterestEM = (e) => {
    setInterestEM(e.target.value);
  };
  const handleChangeInsterestEM_fuel = (e) => {
    setInterestEM_fuel(e.target.value);
  };
  const handleChangeInstallments = (e) => {
    setInstallments(e.target.value);
  };
  const handleChangeAssuranceRisk = (e) => {
    setAssuranceRisk(e.target.value);
  };
  const handleChangeAssuranceRiskFuel = (e) => {
    setAssuranceRiskFuel(e.target.value);
  };
  const handleHourValue = (e) => {
    setHourValue(e.target.value);
  };
  const handlePpDays = (e) => {
    setPpDays(e.target.value);
  };
  const handlePpHours = (e) => {
    setPpHours(e.target.value);
  };
  const handleOpRange = (e) => {
    setOpRange(e.target.value);
  };

  //update params
  const handleEnergyPrice = (e) => {
    setEnergyPrice(e.target.value);
  };

  const handleFuelPrice = (e) => {
    setFuelPrice(e.target.value);
  };

  const handleClearFields = () => {
    localStorage.clear();
    setYearsUse("");
    setAnnualUse("");
    setLoan("");
    setFinancing("");
    setInterestEM("");
    setInterestEM_fuel("");
    setInstallments("");
    setAssuranceRisk("");
    setAssuranceRiskFuel("");
    setHourValue("");
    setPpDays("");
    setPpHours("");
    setResidualFactor("");
    setPriceVariating("");
  };

  return (
    <div>
      <p
        className=""
        data-bs-toggle="modal"
        data-bs-target={`#ModalParams-${id}`}
        data-bs-whatever="@mdo"
      >
        {icon}
        {text}
      </p>
      <div
        className="modal fade"
        id={`ModalParams-${id}`}
        tabIndex="-1"
        aria-labelledby={`ModalParamsLabel-${id}`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title subtitle" id={`ModalParamsLabel-${id}`}>
                PARÁMETROS <TooltipModal text={'Todos los valores numéricos utilizan formato de punto decimal (##.##)'} title={'Decimales'}/>
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleButtonClick}>

                <h4 className="subtitle" >Generales</h4>
                <div className="row">
                  <div class="col-lg-4">
                    <label for="yearsUse" class="col-form-label modal-lavel">
                      Años de uso <TooltipModal text={'Cantidad de años para la operación del vehículo'} title={'Años de uso'}/>
                    </label>

                    <input
                      type="number"
                      class="form-control"
                      id="yearsUse"
                      onChange={handleYearsUse}
                      required
                      min={1}
                      max={10}
                      value={yearsUse}
                    />
                  </div>
                  <div class="col-lg-4">
                    <label for="annualUse" class="col-form-label modal-lavel">
                      Uso al año [km] <TooltipModal text={'Recorrido anual del vehículo en kilómetros'} title={'Uso al año [km]'}/>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="annualUse"
                      onChange={handleAnnualUse}
                      required
                      min={5000}
                      max={150000}
                      value={annualUse}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label for="fuelSelect" class="col-form-label modal-lavel">
                      Ciudad de operación
                    </label>
                    <select
                      class="form-select mb-3"
                      id="fuelSelect"
                      value={city}
                      onChange={handleChangeCity}
                      required
                    >
                      <option>Selecciona la ciudad</option>
                      <option value="medellin">Medellín</option>
                      <option value="bogota">Bogotá</option>
                      <option value="cali">Cali</option>
                      <option value="cartagena">Cartagena</option>
                    </select>
                  </div>
                </div>
                <br />
                <h4 className="subtitle">Vehículo a comparar</h4>
                <div className="row">
                  <div class="col-lg-12">
                    <label for="select-fuel-vehicles" class="col-form-label modal-lavel">
                      Vehículo a comparar
                    </label>
                    <select
                      class="form-select mb-3"
                      id="select-fuel-vehicles"
                      required
                      onChange={handleSelectFuelVehicles}
                      value={fuelVehicleInput}
                    >
                      <option value={' '} selected>Selecciona un vehículo</option>
                      {fuelVehicle.map((vehicle) => (
                        <option value={vehicle.id} key={vehicle.id} fuel={vehicle.fuel}>
                          {vehicle.name} (<UpperFormatt string={vehicle.type} /> {vehicle.fuel})
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <br />
                <h4 className="subtitle">Valores de referencia</h4>
                <div className="row">
                  <div className="col-lg-6">
                    <label for="energy-price" class="col-form-label modal-lavel">
                      Valor de la energía [$] <TooltipModal text={'Costo de la energía eléctrica por kWh'} title={'Valor de la energía [$]'}/>
                    </label>
                    <input
                      type="number"
                      id="energy-price"
                      class="form-control"
                      onChange={handleEnergyPrice}
                      value={energyPrice}
                      min={1}
                      step={0.1}
                      required
                    />
                  </div>
                  <div className="col-lg-6">
                    <label for="fuel-price" class="col-form-label modal-lavel">
                      Valor del combustible [$] <TooltipModal text={'Costo del galón de combustible, diésel o gasolina'} title={'Valor del combustible [$]'}/>
                    </label>
                    <input
                      type="number"
                      id="fuel-price"
                      class="form-control"
                      onChange={handleFuelPrice}
                      value={fuelPrice}
                      min={1}
                      step={0.1}
                      required
                    />
                  </div>
                </div>
                <br />
                <h4 className="subtitle">Compra</h4>
                <div className="row">
                  <div className="row align-items-center">
                  </div>
                  <div className="col-lg-6">
                    <label for="interestEM" class="col-form-label modal-lavel">
                      Interés EM Eléctrico [%] <TooltipModal text={'Porcentaje de Interés Efectivo Mensual otorgado para el vehículo eléctrico'} title={'Interés EM Eléctrico [%]'}/>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="interestEM"
                      onChange={handleChangeInsterestEM}
                      min={0}
                      step={0.1}
                      value={interestEM}
                      required
                    />
                  </div>
                  <div className="col-lg-6">
                    <label for="interestEM" class="col-form-label modal-lavel">
                      Interés EM Combustión [%] <TooltipModal text={'Porcentaje de Interés Efectivo Mensual otorgado para el vehículo de combustión'} title={'Interés EM Combustión [%]'}/>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="interestEM_fuel"
                      onChange={handleChangeInsterestEM_fuel}
                      min={0}
                      step={0.1}
                      value={interestEM_fuel}
                      required
                    />
                  </div>
                  <div className="col-lg-6">
                    <label for="input-value-financing" class="col-form-label modal-lavel">
                      Porcentaje financiado [%] <TooltipModal text={'Porcentaje financiado con respecto al precio oficial del vehículo'} title={'Porcentaje financiado [%]'}/>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="input-value-financing"
                      onChange={handleChangeFinancing}
                      value={financing}
                      disabled={isValue}
                      required
                      min={0}
                      max={100}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label for="installments" class="col-form-label modal-lavel">
                      Número de cuotas <TooltipModal text={'Cantidad de cuotas mensuales en la financiación'} title={'Número de cuotas'}/>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="installments"
                      onChange={handleChangeInstallments}
                      min={1}
                      step={1}
                      value={installments}
                      required
                    />
                  </div>

                </div>
                <br />
                <br />
                <h4 className="subtitle">Aseguramiento</h4>
                <div className="row">
                  <div className="col-lg-6">
                    <label for="all-risk" class="col-form-label modal-lavel">
                      Seguro todo riesgo vehículo eléctrico [$] <TooltipModal text={'Seguro adquirido con entidad aseguradora. Este valor se debe calcular de manera externa'} title={'Seguro todo riesgo vehículo eléctrico [$]'}/>
                    </label>
                    <input
                      type="number"
                      id="all-risk"
                      class="form-control"
                      onChange={handleChangeAssuranceRisk}
                      value={assuranceRisk}
                      min={1}
                      required
                    />
                  </div>
                  <div className="col-lg-6">
                    <label for="all-risk" class="col-form-label modal-lavel">
                      Seguro todo riesgo vehículo de combustión [$] <TooltipModal text={'Seguro adquirido con entidad aseguradora. Este valor se debe calcular de manera externa'} title={'Seguro todo riesgo vehículo a combustión [$]'}/>
                    </label>
                    <input
                      type="number"
                      id="all-risk"
                      class="form-control"
                      onChange={handleChangeAssuranceRiskFuel}
                      value={assuranceRiskFuel}
                      min={1}
                      required
                    />
                  </div>
                </div>
                <br />
                <br />
                <h4 className="subtitle">Productividad</h4>
                <div className="row">
                  <div className="col-lg-6">
                    <label for="hour-worker" class="col-form-label modal-lavel">
                      Valor por hora del conductor [$] <TooltipModal text={'Costo base por hora para pago al conductor'} title={'Valor por hora del conductor [$]'}/>
                    </label>
                    <input
                      type="number"
                      id="hour-worker"
                      class="form-control"
                      onChange={handleHourValue}
                      value={hourValue}
                      min={4833}
                      required
                    />
                  </div>
                  <div className="col-lg-6">
                    <label for="pp-days" class="col-form-label modal-lavel">
                      Número de días de pico y placa <TooltipModal text={'Cantidad de días de pico y placa por semana'} title={'Número de días de pico y placa'}/>
                    </label>
                    <input
                      type="number"
                      id="pp-days"
                      class="form-control"
                      onChange={handlePpDays}
                      value={ppDays}
                      min={0}
                      max={7}
                      required
                    />
                  </div>
                  <div className="col-lg-6">
                    <label for="pp-hours" class="col-form-label modal-lavel">
                      Número de horas de pico y placa <TooltipModal text={'Cantidad de horas de pico y placa por día aplicable'} title={'Número de horas de pico y placa'}/>
                    </label>
                    <input
                      type="number"
                      id="pp-hours"
                      class="form-control"
                      onChange={handlePpHours}
                      value={pphours}
                      min={0}
                      max={24}
                      step={0.1}
                      required
                    />
                  </div>
                  <div class="col-lg-6">
                    <label for="op-range" class="col-form-label modal-lavel">
                      Rango de operación <TooltipModal text={'Días laborables en la operación del vehículo'} title={'Rango de operación'}/>
                    </label>
                    <select
                      class="form-select mb-3"
                      id="op-range"
                      onChange={handleOpRange}
                      value={opRange}
                      required
                    >
                      <option selected>Selecciona el rango de operación</option>
                      <option value="LD">Lunes a Domingo</option>
                      <option value="LS">Lunes a Sábado</option>
                      <option value="LV">Lunes a Viernes</option>
                    </select>
                  </div>
                </div>
                
                <br />
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary btn-lg"
                    onClick={handleClearFields}
                  >
                    Limpiar Campos
                  </button>
                  {loading ? (
                    <button
                      class="btn btn-primary btn-lg"
                      type="button"
                      disabled
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <button type="submit" class="btn btn-primary btn-lg">
                      Calcular
                    </button>
                  )}
                </div>

                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >{`${progress}%`}</div>
                </div>
                <br />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalParams;
