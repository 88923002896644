import { useState, useEffect } from 'react';
import Spinner from '../../components/spinner/Spinner';
import TableConsumption from './components/TableConsumption';
import TitleComponent from '../../components/titleComponent/TitleComponent';

const Consumption = () => {
    
const [cleanDataDetail, setCleanDataDetail] = useState([])
const [loading, setLoading] = useState(true)

useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("consuption"));
    if (savedData) {
      setCleanDataDetail(savedData);
      setLoading(false)
    } else {
      setCleanDataDetail([]);
      setLoading(false)
    }
  }, []);

return (
    <div>
        <TitleComponent text={'Consumo de energía'} />
        {loading ? <Spinner /> : <TableConsumption data={cleanDataDetail} />}
    </div>
)
}

export default Consumption;