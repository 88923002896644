import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const TooltipModalConsts = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="link" className='tooltip-btn' onClick={handleShow}>
        <i className="tooltip-question">i</i>
      </Button>

      <Modal className='modal-tooltip' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sistema de constantes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Generales</h5>
          <p>
            - Factor de autonomía [%] (Factor que representa la autonomía nominal del vehículo adaptada a la operación real de este)
            <br />
            - Factor de operación [%] (Factor que representa el  cumplimiento en el recorrido diario establecido en la sección de Productividad)
            <br />
            - Factor residual [%] (Factor de límite de depreciación en el costo de la batería del vehículo eléctrico)
            <br />
            - Velocidad promedio [km/h] (Velocidad límite promedio en autopistas de las principales ciudades del país, establecida por el Ministerio de Transporte)
          </p>
          <br />
          <h5>Combustión</h5>
          <p>
            - Eficiencia de motor de combustión [%] (Eficiencia teórica de un motor de combustión en la conversión de energía)
            <br />
            - Energía por galón de gasolina [kWh/gl] (Cantidad de energía por unidad en un (1) galón de gasolina)
            <br />
            - Energía por galón de diesel [kWh/gl] (Cantidad de energía por unidad en un (1) galón de diesel)
            <br />
            - Factor de emisión de la gasolina [kg CO2/GJ] (Factor de emisión de CO2 a la atmósfera para la gasolina)
            <br />
            - Factor de emisión del diesel [kg CO2/GJ] (Factor de emisión de CO2 a la atmósfera para el diesel)
            <br />
            - Porcentaje de revisión [%] (Factor de precio utilizado para establecer el costo de revisión y/o reparación de un motor de combustión)
            <br />
            - Kilometraje de revisión [km] (Kilometraje base para las revisiones y/o reparaciones periódicas en un motor de combustión)
          </p>
          <br />
          <h5>Factores de absorción de árboles (Factores de árbol que representan la cantidad de CO2 absorbido de la atmósfera, emitido por diferentes fuentes de energía)</h5>
          <p>
            - Factor de árbol joven [kg CO2/año]
            <br />
            - Factor de árbol maduro [kg CO2/año]
          </p>
          <br />
          <h5>Consumibles (Valores base utilizados para el cálculo de los consumibles aplicados para cada vehículo. Nota: Se recomienda no cambiar estos valores)</h5>
          <p>
            - Kilometraje base [km]
            <br />
            - Peso base [km]
          </p>
          <br />
          <h5>Hidrógeno verde (Parámetros empleados en la potencial conversión de un vehículo eléctrico a uno con tracción impulsada por hidrógeno verde como fuente de energía principal)</h5>
          <p>
            - Factor de eficiencia en celda de combustible [%]
            <br />
            - Factor de eficiencia en compresión - suministro de H2 [%]
            <br />
            - Factor de eficiencia en la electrólisis [%]
            <br />
            - Densidad energética del hidrógeno [kWh/kg]
            <br />
            - Factor de cantidad de agua por peso de H2 [lt/kg]
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TooltipModalConsts;
