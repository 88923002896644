import React from 'react';
import NumberFormatter from '../../../components/Formarts';
import TooltipModal from '../../../components/modals/ToolTipModal';
import young_tree from '../../../images/trees/arbol-nuevo.jpg'
import old_tree from '../../../images/trees/arbol-maduro.jpg'
import field from '../../../images/trees/Cancha.jpeg'
import woods from '../../../images/trees/Hectareas.jpeg'
import EnvPDF from '../../downloader/EnvPDF'

import '../../../styles/environtment.css'

const TableEnv = ({ data }) => {

  const options = {
    useGrouping: true,
    minimumFractionDigits: 0,
  };

  if (!data || data.length <= 0 || data.error || data === null || data == [] ) {
    return <p className="subtitle">Sin parámetros asignados</p>;
  }

  return (
    <div>
<div id='environtment'>
      {data.length > 0 && (
        <>
          <div className="father-box">
            <div className="child-sm-box">
            <h4 className='subtitle' >Vehículo Eléctrico</h4>
            <p className='result-state'>Consumo de energía [kWh/km] <span className='result-text' ><NumberFormatter number={data[0].consumo_electrico} /></span> <TooltipModal text={'Cantidad de energía consumida por el vehículo en un (1) kilómetro recorrido'} title={'Consumo de energía [kWh/km]'} /></p>
            <p>Costo de energía por kilómetro [$/km]  <span className='result-text' >$ <NumberFormatter number={data[0].costo_electrico_km} /></span></p>

            <br />
            <h4 className='subtitle' >Ahorros <TooltipModal text={'Beneficios de uso del vehículo eléctrico'} title={'Ahorros'} /></h4>
            <p>Energía anual ahorrada [kWh] <span className='result-text' ><NumberFormatter number={data[0].energia_ahorrada} /></span></p>
            <p>Emisiones evitadas [TCO2] <span className='result-text' ><NumberFormatter number={data[0].emisiones_evitadas} /> </span> <TooltipModal text={'Cantidad de CO2 que no se emitió a la atmósfera'} title={'Emisiones evitadas [TCO2]'} /></p>
            <p>Ahorro mensual [COP] <span className='result-text' >$ <NumberFormatter number={data[0].ahorro_mensual} /></span> </p>
            <p>Ahorro Anual [COP] <span className='result-text' >$ <NumberFormatter number={data[0].ahorro_anual} /></span> </p>
            <br />
            <h4 className='subtitle'>Conversión a hidrógeno verde <TooltipModal text={'Parámetros de estimación de hidrógeno y agua en una potencial conversión del vehículo a hidrógeno verde como fuente de energía'} title={'Conversión a hidrógeno verde'} /></h4>
              <p>Energía requerida para tracción [kWh] <span className='result-text' ><NumberFormatter number={data[0].nominal_energy} /></span>  </p>
              <p>Energía en forma de H2 en cilindros [kWh] <span className='result-text' ><NumberFormatter number={data[0].energia_h2_cilindros} /></span>  </p>
              <p>Energía en forma de H2 en baja presión [kWh] <span className='result-text' ><NumberFormatter number={data[0].energia_h2_baja_presion} /></span>  </p>
              <p>Energía consumida en electrólisis [kWh] <span className='result-text' ><NumberFormatter number={data[0].energia_consumida} /> </span> </p>
              <p>Masa de hidrógeno requerida [kg] <span className='result-text' ><NumberFormatter number={data[0].masa_hydrogeno} /></span> </p>
              <p>Mínimo de litros de agua requeridos [lt] <span className='result-text' ><NumberFormatter number={data[0].litros_agua_requeridos} /></span>  </p>
            </div>
            <div className="child-sm-box">

              <h4 className='subtitle'>Vehículo equivalente de combustión</h4>
              <p>Consumo de energía [kWh/km] <span className='result-text' ><NumberFormatter number={data[0].consumo_combustion} /></span> </p>
              <p>Consumo de combustible [gl/km] <span className='result-text' ><NumberFormatter number={data[0].consumo_combustible} /></span> <TooltipModal text={'Cantidad de galones de combustible consumidos por el vehículo durante un (1) kilómetro de recorrido'} title={'Consumo de combustible [gl/km] '} /></p>
              <p>Rendimiento de combustible [km/gl] <span className='result-text' ><NumberFormatter number={data[0].eficiencia_combustible} /></span> <TooltipModal text={'Cantidad de kilómetros posibles de recorrer con un (1) galón de combustible'} title={'Rendimiento de combustible [km/gl]'} /></p>
              <p>Costo de combustible por kilómetro [$/km] <span className='result-text' >$ <NumberFormatter number={data[0].costo_km_cosmbustible} /></span> </p>
              <br />
              <h4 className='subtitle'>Emisiones</h4>
              <p>Factor de emisión de combustible [kgCO2/GJ] <span className='result-text' ><NumberFormatter number={data[0].factor_emision} /></span> </p>
              <p>Emisiones por Kilómetros [gCO2/km] <span className='result-text' ><NumberFormatter number={data[0].emisiones_km} /> <TooltipModal text={'Cantidad de CO2 emitido a la atmósfera'} title={'Emisiones por Kilómetros [gCO2/km]'} /></span> </p>
            </div>
          </div>
          <hr />
          <br />
          <h4 className='subtitle'>Árboles <TooltipModal text={'Cantidad de árboles equivalentes plantados, requeridos para la absorción del CO2 emitido. El uso de este vehículo eléctrico equivale a plantar esta cantidad de árboles jóvenes y maduros'} title={'Árboles'} /></h4>
          <div className="tree-sec">
            <div className="tree">
              <p className='subtitle'>Árboles Jóvenes</p>
              <h4 className='big-result-text'>{data[0].arboles_jovenes.toLocaleString(undefined, options).replace(/,/g, '.')}</h4>
              <img src={young_tree} alt="" />
            </div>
            <div className="tree">
              <p className='subtitle'>Árboles Maduros</p>
              <h4 className='big-result-text'>{data[0].arboles_maduros.toLocaleString(undefined, options).replace(/,/g, '.')}</h4>
              <img src={old_tree} alt="" />
            </div>
            <div className="tree">
            <p className='subtitle'>Maduros</p>
              <h4 className='big-result-text'><NumberFormatter number={((10000*(data[0].arboles_maduros)/200)/7500)}/> canchas</h4>
              <p className='subtitle'>Jovenes</p>
              <h4 className='big-result-text'><NumberFormatter number={((10000*(data[0].arboles_jovenes/2500))/7500)}/> canchas</h4>
              <img src={field} alt="" className='field-img' />
            </div>
            <div className="tree">
              <p className='subtitle'>Maduros</p>
              <h4 className='big-result-text'><NumberFormatter number={(data[0].arboles_maduros)/200}/> héctareas</h4>
              <p className='subtitle'>Jovenes</p> 
              <h4 className='big-result-text'><NumberFormatter number={(data[0].arboles_jovenes)/2500}/> héctareas</h4>
              <img src={woods} alt="" className='field-img' />
            </div>
          </div>
          <br />
        </>
      )}
    </div>
    <EnvPDF />
    <br /><br />
    </div>
    
  );
};

export default TableEnv;
