import React, { useState, useEffect } from 'react';
import UpdateService from '../../../../services/admin/updates/updateService';
import GetDataDetail from '../../../../services/loaderService';
import { BiCheck } from 'react-icons/bi';
import config from '../../../../config';
import TooltipModal from '../../../../components/modals/ToolTipModal';

const UpdatePrice = ({ id }) => {

    const [datas, setDatas] = useState([]);
    const domain = config.api_domain.domain;
    const url = `${domain}/features`;

    useEffect(() => {
        GetDataDetail(setDatas, url, id);
    }, [setDatas, url, id]);

    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (datas.length > 0) {
            setFormData({
                price: (datas[0].price === null ? 0 : datas[0].price),
                compressor: (datas[0].compressor === null ? false : datas[0].compressor),
                emissions: (datas[0].emissions === null ? " " : datas[0].emissions)
            });
        }
        console.log(formData)
    }, [datas]);

    const [showForm, setShowForm] = useState(false);
    const [showUpdatedText, setShowUpdatedText] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleChangeCheck = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormData({ ...formData, [e.target.name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        UpdateService('updatePrice', id, formData)
            .then(() => {
                setShowForm(false);
                setShowUpdatedText(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleOpenForm = () => {
        setShowForm(true);
        setShowUpdatedText(false);
    };

    return (
        <div>
            {showForm ? (
                <>
                    <div>
                        <label className='modal-lavel'>
                            Precio [$]
                            <input
                                type="number"
                                className='form-control'
                                name="price"
                                value={formData.price}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <div className="form-check form-switch" >
                        <label className='modal-lavel'>
                            ¿Posee compresor?
                            <input
                                className='form-check-input'
                                type="checkbox"
                                name="compressor"
                                checked={formData.compressor === true}
                                onChange={handleChangeCheck}
                            />
                        <br /><br />
                        </label>
                        </div>
                        <label className='modal-lavel'>
                            Nivel de emisiones <TooltipModal text={'Los vehículos eléctricos son Cero emisiones'} title={'Nivel de emisiones'} />
                            <input
                                type="text"
                                className='form-control'
                                name="emissions"
                                value={formData.emissions}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        <button onClick={handleSubmit}>Actualizar Parámetros</button>
                    </div>
                </>
            ) : (
                <>
                    <p>Parámetros actualizados <BiCheck style={{ fontSize: '30px', color: 'green' }} /></p>
                    <button onClick={handleOpenForm}>Editar Parámetros</button>
                </>
            )}
        </div>
    );
};

export default UpdatePrice;
