import { useState, useEffect } from 'react';
import Spinner from '../../components/spinner/Spinner';
import TitleComponent from '../../components/titleComponent/TitleComponent';
import TableBatery from './components/TableBatery';

const Batery = () => {

const [cleanDataDetail, setCleanDataDetail] = useState([])
const [loading, setLoading] = useState(true)

useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("batery"));
    if (savedData) {
      setCleanDataDetail(savedData);
      setLoading(false)
    } else {
      setCleanDataDetail([]);
      setLoading(false)
    }
  }, []);

return (
    <div>
        <TitleComponent text={'Batería'} />
        {loading ? <Spinner /> : <TableBatery data={cleanDataDetail} />}
    </div>
)
}

export default Batery;