import axios from 'axios';
import config from '../config';

const domain = config.api_domain.domain

const LoaderAssurance = async (id, type, taxes, assurance_risk, assurance_risk_fuel, years_use, city, scraping) => {

    try {
        const url = (`${domain}/assurance/${id}/${type}/${taxes}/${assurance_risk}/${assurance_risk_fuel}/${years_use}/${city}/${scraping}`)
        const res = await axios.get(url)
        return (res.data.body)
        
    } catch (error) {
        console.error(error);
        return null;
    }
}

export default LoaderAssurance