import React, { useState, useEffect } from "react";
import Spinner from "../../../components/spinner/Spinner";
import LoaderUtilsService from "../../../services/admin/utils/loaderUtilsService"
import TitleComponent from "../../../components/titleComponent/TitleComponent";
import TooltipModal from "../../../components/modals/ToolTipModal";
import UpdateService from "../../../services/admin/updates/updateUtils";

const ListSoat = () => {
    const [loading, setLoading] = useState(true);
    const [soat, setSoat] = useState([])

    const options = {
        useGrouping: false,
        minimumFractionDigits: 0
    };

    const setDatas = async () => {
        try {
            const soat_data = await LoaderUtilsService('soat')
            setSoat(soat_data)
            setLoading(false)
        } catch (error) {
            console.error(error)
        }
    }

    const handleEdit = (index) => {
        const updatedSoat = [...soat];
        updatedSoat[index].editMode = true;
        setSoat(updatedSoat);
    };

    const handleUpdate = async (index) => {
        const updatedSoat = [...soat];
        const { id, price } = updatedSoat[index];

        try {
            let dataValue = { price: price }
            await UpdateService("update_soat", id, dataValue);
            updatedSoat[index].editMode = false;
            setSoat(updatedSoat);
        } catch (error) {
            console.error(error);
        }
    };

    const handleValue = (index, e) => {
        const updatedSoat = [...soat];
        updatedSoat[index].price = e.target.value;
        setSoat(updatedSoat);
    };

    const handleCancel = (index) => {
        const updatedSoat = [...soat];
        updatedSoat[index].editMode = false;
        setSoat(updatedSoat);
    };


    useEffect(() => {
        setDatas()
    }, [])

    if (loading) {
        return <Spinner />;
    }

    return (
        <div>
            <TitleComponent text={'SOAT'} />
            <br />
            <div className="container father-box">
                <div className="child-box">
                    <table>
                        <thead>
                            <th className="col-2">Clase</th>
                            <th>Descripción</th>
                            <th>Código</th>
                            <th>Descripción</th>
                            <th>Precio             <TooltipModal
                                title={'SOAT'}
                                text={'Lista de precios SOAT para 2023 por categoría, tomado de la compañía Sura'}
                            /></th>
                            <th>Opciones</th>
                        </thead>
                        <tbody>
                            {soat.map((data, index) => (
                                <tr key={index}>
                                    <td>{data.class}</td>
                                    <td>{data.class_description}</td>
                                    <td>{data.code}</td>
                                    <td>{data.code_description}</td>
                                    <td>
                                        <input
                                            type="number"
                                            className='form-control'
                                            onChange={(e) => handleValue(index, e)}
                                            value={data.price}
                                            disabled={!data.editMode}
                                        />
                                    </td>
                                    <td className="col-options">
                                        {data.editMode ? (
                                            <>
                                                <button
                                                    className="btn btn-primary m-1"
                                                    onClick={() => handleUpdate(index)}
                                                >
                                                    Actualizar
                                                </button>
                                                <button
                                                    className="btn btn-secondary m-1"
                                                    onClick={() => handleCancel(index)}
                                                >
                                                    Cancelar
                                                </button>
                                            </>
                                        ) : (
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => handleEdit(index)}
                                            >
                                                Editar
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    );
};

export default ListSoat