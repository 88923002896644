import React, { useState, useEffect } from 'react';
import UpdateService from '../../../../services/admin/updates/updateService';
import GetDataDetail from '../../../../services/loaderService';
import { BiCheck } from 'react-icons/bi';
import config from '../../../../config';
import { useParams } from "react-router-dom";

const UpdateBodywork = ({ id }) => {
    const [datas, setDatas] = useState([]);
    const domain = config.api_domain.domain;
    const url = `${domain}/bodywork`;

    const params = useParams()
    const category = params.category
    const [showField, setShowField] = useState('false')

    useEffect(() => {
        GetDataDetail(setDatas, url, id);

    }, [setDatas, url, id]);

    useEffect(()=> {
        if (category === 'van de carga') {
          setShowField(true)
        }
        else {
          setShowField(false)
        }
      }, [category])

    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (datas.length > 0) {
            setFormData({
                chassis_approval: datas[0].chassis_approval || "",
                bodywork_brand: datas[0].bodywork_brand || "",
                bodywork_ref: datas[0].bodywork_ref || "",
                bodywork_type: datas[0].bodywork_type || "",
                action_ratio: datas[0].action_ratio || "",
                bodywork_service: datas[0].bodywork_service || "",
                stand_num_passengers: datas[0].stand_num_passengers || "",
                front_axle_loaded: (datas[0].front_axle_loaded === null ? 0 : datas[0].front_axle_loaded),
                back_axle_loaded: (datas[0].back_axle_loaded === null ? 0 : datas[0].back_axle_loaded),
                num_doors: (datas[0].num_doors === null ? 0 : datas[0].num_doors),
                free_door_width: (datas[0].free_door_width === null ? 0 : datas[0].free_door_width),
                free_door_height: (datas[0].free_door_height === null ? 0 : datas[0].free_door_height),
                internal_free_height: datas[0].internal_free_height === null ? 0 : datas[0].internal_free_height,
                top_visibility_height: datas[0].top_visibility_height === null ? 0 : datas[0].top_visibility_height,
                bottom_visibility_height: datas[0].bottom_visibility_height === null ? 0 : datas[0].bottom_visibility_height,
                corridor_width: datas[0].corridor_width === null ? 0 : datas[0].corridor_width,
                back_weels_width: datas[0].back_weels_width === null ? 0 : datas[0].back_weels_width,
                engine_location: datas[0].engine_location || "",
                direction_location: datas[0].direction_location || "",
                seating_arrangement: datas[0].seating_arrangement || "",
                space_facing_seatings: datas[0].space_facing_seatings === null ? 0 : datas[0].space_facing_seatings,
                seating_separating: datas[0].seating_separating === null ? 0 : datas[0].seating_separating,
                seating_deep: datas[0].seating_deep === null ? 0 : datas[0].seating_deep,
                seating_width: datas[0].seating_width === null ? 0 : datas[0].seating_width,
                seating_height: datas[0].seating_height === null ? 0 : datas[0].seating_height,
                backrest_height: datas[0].backrest_height === null ? 0 : datas[0].backrest_height,
                glass_door_area: datas[0].glass_door_area === null ? 0 : datas[0].glass_door_area,
                num_skylights: datas[0].num_skylights === null ? 0 : datas[0].num_skylights,
                cap_storage: datas[0].cap_storage === null ? 0 : datas[0].cap_storage,
                cap_load: datas[0].cap_load === null ? 0 : datas[0].cap_load,
                gravity_center_device: datas[0].gravity_center_device === null ? 0 : datas[0].gravity_center_device,
                gravity_center_load: datas[0].gravity_center_load === null ? 0 : datas[0].gravity_center_load
            });
        }
    }, [datas]);

    const [showForm, setShowForm] = useState(false);
    const [showUpdatedText, setShowUpdatedText] = useState(true);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        UpdateService('updateBodywork', id, formData)
            .then(() => {
                setShowForm(false);
                setShowUpdatedText(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleOpenForm = () => {
        setShowForm(true);
        setShowUpdatedText(false);
    };

    return (
        <div>
            {showForm ? (
                <>
                    <div>
                        <label className='modal-lavel'>
                            Número de homologación del chasis
                            <input
                                type="text"
                                className='form-control'
                                name="chassis_approval"
                                value={formData.chassis_approval}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Marca
                            <input
                                type="text"
                                className='form-control'
                                name="bodywork_brand"
                                value={formData.bodywork_brand}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Referencia
                            <input
                                type="text"
                                className='form-control'
                                name="bodywork_ref"
                                value={formData.bodywork_ref}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Tipo
                            <input
                                type="text"
                                className='form-control'
                                name="bodywork_type"
                                value={formData.bodywork_type}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField} >
                            Radio de acción
                            <input
                                type="text"
                                className='form-control'
                                name="action_ratio"
                                value={formData.action_ratio}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}>
                            Servicio
                            <input
                                type="text"
                                className='form-control'
                                name="bodywork_service"
                                value={formData.bodywork_service}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Número de pasajeros sentados
                            <input
                                type="text"
                                className='form-control'
                                name="stand_num_passengers"
                                value={formData.stand_num_passengers}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel'>
                        Peso vehículo cargado eje delantero [kg]
                            <input
                                type="number"
                                className='form-control'
                                name="front_axle_loaded"
                                value={formData.front_axle_loaded}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel'>
                        Peso vehículo cargado eje trasero [kg]
                            <input
                                type="number"
                                className='form-control'
                                name="back_axle_loaded"
                                value={formData.back_axle_loaded}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}>
                            Número de puertas
                            <input
                                type="number"
                                className='form-control'
                                name="num_doors"
                                value={formData.num_doors}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}>
                            Ancho libre de puerta <span className='units-lowercase'>[mm] </span>
                            <input
                                type="number"
                                className='form-control'
                                name="free_door_width"
                                value={formData.free_door_width}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}>
                            Alto libre de puerta <span className='units-lowercase'>[mm] </span>
                            <input
                                type="number"
                                className='form-control'
                                name="free_door_height"
                                value={formData.free_door_height}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}>
                            Alto libre interno <span className='units-lowercase'>[mm] </span>
                            <input
                                type="number"
                                className='form-control'
                                name="internal_free_height"
                                value={formData.internal_free_height}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}> 
                            Altura de visibilidad superior <span className='units-lowercase'>[mm] </span>
                            <input
                                type="number"
                                className='form-control'
                                name="top_visibility_height"
                                value={formData.top_visibility_height}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}>
                            Altura de visibilidad inferior <span className='units-lowercase'>[mm] </span>
                            <input
                                type="number"
                                className='form-control'
                                name="bottom_visibility_height"
                                value={formData.bottom_visibility_height}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}>
                            Ancho del corredor <span className='units-lowercase'>[mm] </span>
                            <input
                                type="number"
                                className='form-control'
                                name="corridor_width"
                                value={formData.corridor_width}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}>
                        Ancho de las ruedas traseras <span className='units-lowercase'>[mm]</span>
                            <input
                                type="number"
                                className='form-control'
                                name="back_weels_width"
                                value={formData.back_weels_width}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}>
                            Ubicación del motor
                            <input
                                type="text"
                                className='form-control'
                                name="engine_location"
                                value={formData.engine_location}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}>
                            Ubicación de la dirección
                            <input
                                type="text"
                                className='form-control'
                                name="direction_location"
                                value={formData.direction_location}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}>
                            Disposición de los asientos
                            <input
                                type="text"
                                className='form-control'
                                name="seating_arrangement"
                                value={formData.seating_arrangement}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}>
                            Separación de asientos <span className='units-lowercase'>[mm]</span>
                            <input
                                type="number"
                                className='form-control'
                                name="seating_separating"
                                value={formData.seating_separating}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}>
                            Espacio entre los asientos enfrentados <span className='units-lowercase'>[mm]</span>
                            <input
                                type="number"
                                className='form-control'
                                name="space_facing_seatings"
                                value={formData.space_facing_seatings}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}>
                            Profundidad de los asientos <span className='units-lowercase'>[mm]</span>
                            <input
                                type="number"
                                className='form-control'
                                name="seating_deep"
                                value={formData.seating_deep}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}> 
                            Ancho de los asientos <span className='units-lowercase'>[mm]</span>
                            <input
                                type="number"
                                className='form-control'
                                name="seating_width"
                                value={formData.seating_width}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}>
                            Altura de los asientos <span className='units-lowercase'>[mm]</span>
                            <input
                                type="number"
                                className='form-control'
                                name="seating_height"
                                value={formData.seating_height}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}> 
                            Altura del espaldar <span className='units-lowercase'>[mm]</span>
                            <input
                                type="number"
                                className='form-control'
                                name="backrest_height"
                                value={formData.backrest_height}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}>
                            Área del vidrio de la puerta <span className='units-lowercase'>[%]</span>
                            <input
                                type="number"
                                className='form-control'
                                name="glass_door_area"
                                value={formData.glass_door_area}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel' hidden={showField}>
                            Número de claraboyas
                            <input
                                type="number"
                                className='form-control'
                                name="num_skylights"
                                value={formData.num_skylights}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Capacidad de almacenaje <span className='units-lowercase'>[m³]</span>
                            <input
                                type="number"
                                className='form-control'
                                name="cap_storage"
                                value={formData.cap_storage}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Capacidad de carga <span className='units-lowercase'>[kg]</span>
                            <input
                                type="number"
                                className='form-control'
                                name="cap_load"
                                value={formData.cap_load}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Centro de gravedad del equipo <span className='units-lowercase'>[mm]</span>
                            <input
                                type="number"
                                className='form-control'
                                name="gravity_center_device"
                                value={formData.gravity_center_device}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Centro de gravedad de la carga <span className='units-lowercase'>[mm]</span>
                            <input
                                type="number"
                                className='form-control'
                                name="gravity_center_load"
                                value={formData.gravity_center_load}
                                onChange={handleChange}
                            />
                        <br />
                        </label>
                        
                        <button onClick={handleSubmit}>Actualizar Carrocería</button>
                    </div>
                </>
            ) : null}

            {showUpdatedText ? (
                <>
                    <p>Carrocería actualizada <BiCheck style={{ fontSize: '30px', color: 'green' }} /></p>
                    <button onClick={handleOpenForm}>Editar Carrocería</button>
                </>
            ) : null}
        </div>
    );
};

export default UpdateBodywork;
