import React, { useState, useEffect } from 'react';
import UpdateService from '../../../../services/admin/updates/updateService';
import GetDataDetail from '../../../../services/loaderService';
import { BiCheck } from 'react-icons/bi';
import config from '../../../../config';

const UpdateImages = ({ id, hide }) => {

    const [datas, setDatas] = useState([]);
    const domain = config.api_domain.domain;
    const url = `${domain}/features`;

    useEffect(() => {
        GetDataDetail(setDatas, url, id);
    }, [setDatas, url, id]);

    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (datas.length > 0) {
            setFormData({
                front: datas[0].front || "",
                back: datas[0].back || "",
                right_diagonal: datas[0].right_diagonal || "",
                left_diagonal: datas[0].left_diagonal || "",
                left_side: datas[0].left_side || "",
                right_side: datas[0].right_side || "",
                back_left: datas[0].back_left || "",
                back_right: datas[0].back_right || "",
                inside: datas[0].inside || "",
            });
        }
    }, [datas]);

    const [showForm, setShowForm] = useState(false);
    const [showUpdatedText, setShowUpdatedText] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        UpdateService('update_images', id, formData)
            .then(() => {
                setShowForm(false);
                setShowUpdatedText(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleOpenForm = () => {
        setShowForm(true);
        setShowUpdatedText(false);
    };

    return (
        <div>
            {showForm ? (
                <>
                    <div>
                        <label className='modal-lavel' hidden={hide} >
                            Frente
                            <input
                                type="text"
                                className='form-control'
                                name="front"
                                value={formData.front || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>


                        <label className='modal-lavel' hidden={hide} >
                            Diagonal derecho
                            <input
                                type="text"
                                className='form-control'
                                name="right_diagonal"
                                value={formData.right_diagonal || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>


                        <label className='modal-lavel' hidden={hide} >
                            Lado derecho
                            <input
                                type="text"
                                className='form-control'
                                name="right_side"
                                value={formData.right_side || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>


                        <label className='modal-lavel' hidden={hide} >
                            Trasera derecha
                            <input
                                type="text"
                                className='form-control'
                                name="back_right"
                                value={formData.back_right || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>


                        <label className='modal-lavel' hidden={hide} >
                            Parte trasera
                            <input
                                type="text"
                                className='form-control'
                                name="back"
                                value={formData.back || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>


                        <label className='modal-lavel' hidden={hide} >
                            Trasera izquierda
                            <input
                                type="text"
                                className='form-control'
                                name="back_left"
                                value={formData.back_left || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>


                        <label className='modal-lavel' hidden={hide} >
                            Lado izquierdo
                            <input
                                type="text"
                                className='form-control'
                                name="left_side"
                                value={formData.left_side || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>


                        <label className='modal-lavel' >
                            Diagonal izquierdo
                            <input
                                type="text"
                                className='form-control'
                                name="left_diagonal"
                                value={formData.left_diagonal || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>


                        <label className='modal-lavel' hidden={hide} >
                            Interior
                            <input
                                type="text"
                                className='form-control'
                                name="inside"
                                value={formData.inside || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>

                        <button onClick={handleSubmit}>Actualizar Imágenes</button>
                    </div>
                </>
            ) : (
                <>
                    <p>Imágenes actualizadas <BiCheck style={{ fontSize: '30px', color: 'green' }} /></p>
                    <button onClick={handleOpenForm}>Editar Imágenes</button>
                </>
            )}
        </div>
    );
};

export default UpdateImages;