import axios from 'axios';

const GetDataDetail = async (detail, link, id) => {
    try {
        const url = (`${link}/${id}`)
        const res = await axios.get(url)
        detail(res.data.body)
        
    } catch (error) {
        console.error(error);
        detail(null);
    }
    return detail
}

export default GetDataDetail