import React, { useState } from "react";
import { useLocation, Link, Outlet, useParams, NavLink } from "react-router-dom";
import {
  FaCar,
  FaSearchPlus,
  FaBalanceScale,
  FaLeaf,
  FaAngleDown,
  FaAngleRight,
  FaCogs
} from "react-icons/fa";
import { IoShieldCheckmark, IoStatsChart } from "react-icons/io5";
import { RiBarChartLine } from "react-icons/ri";
import { GiCarBattery } from "react-icons/gi";
import { MdMonetizationOn } from "react-icons/md";
import { AiFillHome, AiOutlineLineChart, AiTwotoneSetting } from "react-icons/ai";
import { BiWrench } from "react-icons/bi";
import VehicleList from "../Navbar";
import ModalParams from "../modals/ModalParams";
import Modal360 from "../modals/Modal360.js";
import { FaBars } from "react-icons/fa";
import "../../styles/body/sidenav.css";
import logo_black from '../../images/logo/logo-black.png'

const Sidebar = (props) => {
  const { id, type } = props;
  const [show, setShow] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  let closeSubMenuTimeout;

  const location = useLocation();

  const handleSelectType = (type) => {
    setSelectedType(type);
  };

  const openSubMenu = () => {
    clearTimeout(closeSubMenuTimeout);
    setShowSubMenu(true);
  };

  const closeSubMenu = () => {
    closeSubMenuTimeout = setTimeout(() => {
      setShowSubMenu(false);
    }, 100);
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <main className={show ? "space-toggle" : null}>
      <ModalParams id={id} />

      <header className={`header ${show ? "space-toggle" : null}`}>
        <div className="header-toggle" onClick={() => setShow(!show)}>
          <FaBars style={{ color: "white" }} />
        </div>
        <div className="header-cat">
          <div className="navbar-brand d-none d-lg-block">
            <VehicleList
              selectedType={selectedType}
              handleSelectType={handleSelectType}
            />
          </div>
          <div
            className="text-center justify-content-center d-block d-lg-none"
            style={{ width: "100%" }}
          >
            <NavLink to={`/admin/charts`}
              className="">
              <img src={logo_black} alt="Auteco-logo" width={50} />
            </NavLink>
          </div>
        </div>
        <div
          className="d-none d-sm-flex justify-content-end text-white"
          style={{ width: "100%", marginTop: "10px" }}
        >
          <NavLink to={`/admin/charts`}
            className="">
            <img src={logo_black} alt="Auteco-logo" width={50} />
          </NavLink>
        </div>
      </header>

      <aside className={`sidebar ${show ? "show" : null}`}>

        <nav className="nav">
          <div>
            <NavLink className="nav-li-modal" activeClassName="nav-li-active" title="Visual 360°">
              <Modal360 />
              <span className="nav-li-name">Visual 360°</span>
            </NavLink>

            <div className="nav-list">
              <NavLink className="nav-li-modal" title="Parámetros">
                <ModalParams
                  icon={<AiTwotoneSetting className="nav-logo-icon" />}
                  text={<span className="" style={{ marginLeft: "32px" }}>Parámetros</span>}
                  id={id}
                />
              </NavLink>

              <div>
                <div
                  className="nav-li"
                  onMouseEnter={openSubMenu}
                  onMouseLeave={closeSubMenu}
                  title="Subsistemas"
                >
                  <FaCar className="nav-logo-icon" />
                  <span className="nav-li-name" activeClassName="nav-li-active">
                  
                    Subsistemas{" "}
                    <FaAngleRight
                      className={`nav-li-icon ${showSubMenu ? "rotate" : ""}`}
                    />
                  </span>
                </div>

                <div onMouseLeave={closeSubMenu}>
                  {showSubMenu && (
                    <div
                      className={`submenu ${showSubMenu ? "show" : ""}`}
                      onMouseEnter={openSubMenu}
                      onMouseLeave={closeSubMenu}
                    >
                      <NavLink
                        to={`/home/${id}/${type}/subsystems/mecanic/${id}/measures/${id}`}
                        className="submenu-item"
                        activeClassName="nav-li-active"
                        onClick={closeSubMenu}
                      >
                        Mecánicos
                      </NavLink>
                      <NavLink
                        to={`/home/${id}/${type}/subsystems/features/${id}`}
                        className="submenu-item"
                        activeClassName="nav-li-active"
                        onClick={closeSubMenu}
                      >
                        Generalidades
                      </NavLink>
                      <NavLink
                        to={`/home/${id}/${type}/subsystems/electric_engine/${id}`}
                        className="submenu-item"
                        activeClassName="nav-li-active"
                        onClick={closeSubMenu}
                      >
                        Motor Eléctrico
                      </NavLink>
                      <NavLink
                        to={`/home/${id}/${type}/subsystems/batery_charger/${id}`}
                        className="submenu-item"
                        activeClassName="nav-li-active"
                        onClick={closeSubMenu}
                      >
                        Batería y sistema de carga
                      </NavLink>
                    </div>
                  )}
                </div>
              </div>

              <NavLink
                to={`/home/${id}/${type}/quoter`}
                className="nav-li"
                activeClassName="nav-li-active"
                title="Comparativo"
              >
                <FaBalanceScale className="nav-logo-icon" />
                <span className="nav-li-name">Comparativo</span>
              </NavLink>
              <NavLink
                to={`/home/${id}/${type}/environment`}
                className="nav-li"
                activeClassName="nav-li-active"
                title="Aporte Ambiental"
              >
                <FaLeaf className="nav-logo-icon" />
                <span className="nav-li-name">Aporte Ambiental</span>
              </NavLink>
              <NavLink
                to={`/home/${id}/${type}/financial`}
                className="nav-li"
                activeClassName="nav-li-active"
                title="Compra"
              >
                <RiBarChartLine className="nav-logo-icon" />
                <span className="nav-li-name">Compra</span>
              </NavLink>

              <NavLink
                to={`/home/${id}/${type}/consumption`}
                className="nav-li"
                activeClassName="nav-li-active"
                title="Consumo"
              >
                <MdMonetizationOn className="nav-logo-icon" />
                <span className="nav-li-name">Consumo</span>
              </NavLink>

              <NavLink
                to={`/home/${id}/${type}/maintenance`}
                className="nav-li"
                activeClassName="nav-li-active"
                title="Mantenimiento"
              >
                <BiWrench className="nav-logo-icon" />
                <span className="nav-li-name">Mantenimiento</span>
              </NavLink>

              <NavLink
                to={`/home/${id}/${type}/batery`}
                className="nav-li"
                activeClassName="nav-li-active"
                title="Batería"
              >
                <GiCarBattery className="nav-logo-icon" />
                <span className="nav-li-name">Batería</span>
              </NavLink>

              <NavLink
                to={`/home/${id}/${type}/assurance`}
                className="nav-li"
                activeClassName="nav-li-active"
                title="Aseguramiento"
              >
                <IoShieldCheckmark className="nav-logo-icon" />
                <span className="nav-li-name">Aseguramiento</span>
              </NavLink>

              <NavLink
                to={`/home/${id}/${type}/productivity`}
                className="nav-li"
                activeClassName="nav-li-active"
                title="Productividad"
              >
                <AiOutlineLineChart className="nav-logo-icon" />
                <span className="nav-li-name">Productividad</span>
              </NavLink>

              <NavLink
                to={`/home/${id}/${type}/total`}
                className="nav-li"
                activeClassName="nav-li-active"
                title="TCO"
              >
                <IoStatsChart className="nav-logo-icon" />
                <span className="nav-li-name">TCO</span>
              </NavLink>
            </div>
          </div>
        </nav>
      </aside>

      <Outlet />
    </main>
  );
};

export default Sidebar;
