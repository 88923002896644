import React, { useState, useEffect } from 'react';
import UpdateService from '../../../../services/admin/updates/updateService';
import GetDataDetail from '../../../../services/loaderService';
import { BiCheck } from 'react-icons/bi';
import config from '../../../../config';

const UpdateBatery = ({ id }) => {
    const [datas, setDatas] = useState([]);
    const domain = config.api_domain.domain;
    const url = `${domain}/batery`;

    useEffect(() => {
        GetDataDetail(setDatas, url, id);
    }, [setDatas, url, id]);

    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (datas.length > 0) {
            setFormData({
                material: datas[0].material || "",
                batery_config: datas[0].batery_config || "",
                cycles: datas[0].cycles,
                autonomy: datas[0].autonomy,
                nominal_cap: datas[0].nominal_cap,
                nominal_volt: datas[0].nominal_volt,
                nominal_energy: datas[0].nominal_energy,
            });
        }
    }, [datas]);

    const [showForm, setShowForm] = useState(false);
    const [showUpdatedText, setShowUpdatedText] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        UpdateService('updateBatery', id, formData)
            .then(() => {
                setShowForm(false);
                setShowUpdatedText(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleOpenForm = () => {
        setShowForm(true);
        setShowUpdatedText(false);
    };

    return (
        <div>
            {showForm ? (
                <>
                    <div>
                        <label className='modal-lavel'>
                            Química
                            <input
                                type="text"
                                className='form-control'
                                name="material"
                                value={formData.material}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Configuración de la batería
                            <input
                                type="text"
                                className='form-control'
                                name="batery_config"
                                value={formData.batery_config}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Número de ciclos
                            <input
                                type="number"
                                name="cycles"
                                className='form-control'
                                value={formData.cycles}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                        Autonomía [km]
                            <input
                                type="number"
                                name="autonomy"
                                className='form-control'
                                value={formData.autonomy}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                        Capacidad nominal [Ah]
                            <input
                                type="number"
                                name="nominal_cap"
                                className='form-control'
                                value={formData.nominal_cap}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                        Voltaje nominal [V]
                            <input
                                type="number"
                                name="nominal_volt"
                                className='form-control'
                                value={formData.nominal_volt}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                        Energía nominal [kWh]
                            <input
                                type="number"
                                name="nominal_energy"
                                className='form-control'
                                value={formData.nominal_energy}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <button onClick={handleSubmit}>Actualizar Batería</button>
                    </div>
                </>
            ) : (
                <>
                    <p>Batería actualizada <BiCheck style={{ fontSize: '30px', color: 'green' }} /></p>
                    <button onClick={handleOpenForm}>Editar Batería</button>
                </>
            )}
        </div>
    );
};

export default UpdateBatery;
