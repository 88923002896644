import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, Carousel } from 'react-bootstrap'
import { FaSearchPlus } from 'react-icons/fa'
import altImage from '../../images/logo/auteco-logo.png'
import LoaderUtilsService2 from '../../services/admin/utils/loaderUtilsService2'

const Modal360 = () => {
  const params = useParams()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const id = params.id
  const [front, setFront] = useState(altImage)
  const [rightDiagonal, setRightDiagonal] = useState(altImage)
  const [right, setRight] = useState(altImage)
  const [back, setBack] = useState(altImage)
  const [left, setLeft] = useState(altImage)
  const [leftDiagonal, setLeftDiagonal] = useState(altImage)
  const [backRight, setBackRight] = useState(altImage)
  const [backLeft, setBackLeft] = useState(altImage)
  const [inside, setInside] = useState(altImage)


  const getImageUrlOrAlt = (imageUrl) => {
    if (typeof(imageUrl) === "string") {
        return imageUrl;
    }
    return altImage;
  };

  useEffect(() => {
    const setDatas = async () => {
      try {
        const features_data = await LoaderUtilsService2(`features/${id}`);
        setFront(getImageUrlOrAlt(features_data[0].front));
        setRightDiagonal(getImageUrlOrAlt(features_data[0].right_diagonal));
        setRight(getImageUrlOrAlt(features_data[0].right_side))
        setBack(getImageUrlOrAlt(features_data[0].back))
        setLeft(getImageUrlOrAlt(features_data[0].left_side))
        setLeftDiagonal(getImageUrlOrAlt(features_data[0].left_diagonal))
        setBackLeft(getImageUrlOrAlt(features_data[0].back_left))
        setBackRight(getImageUrlOrAlt(features_data[0].back_right))
        setInside(getImageUrlOrAlt(features_data[0].inside))
      } catch (error) {
        console.error(error);
      }
    };
    setDatas();
  }, [id]);


  return (
    <>
      <FaSearchPlus className="nav-logo-icon" onClick={handleShow} />
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Body>
          <Carousel fade>
            <Carousel.Item>
              <img className="d-block w-100" src={front} alt="Vista frontal" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100" src={rightDiagonal} alt="Vista diagonal derecha" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100" src={right} alt="Vista derecha" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100" src={backRight} alt="Vista trasera derecha" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100" src={back} alt="Vista trasera" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100" src={backLeft} alt="Vista trasera izquierda" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100" src={left} alt="Vista izquierda" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100" src={leftDiagonal} alt="Vista diagonal izquierda" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-100" src={inside} alt="Vista diagonal izquierda" />
            </Carousel.Item>
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Modal360;
