import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import exportingInit from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
exportingInit(Highcharts);
ExportData(Highcharts);

const BarChart = ({ title, data }) => {
  const options = {
    lang: {
      downloadCSV:"Descarga CSV",       
      viewFullscreen:"Ver en pantalla completa",
      printChart: "Imprimir Gráfica",
      downloadPNG:"Descarga PNG",
      downloadJPEG:"Descarga JPEG",
      downloadPDF:"Descarga Documento PDF",
      downloadSVG:"Descarga vector SVG",
      downloadXLS:"Descarga XLS",
      viewData:"Ver datos de tabla",
  },
    chart: {
      type: 'bar',
    },
    title: {
      text: 'Comparativo',
    },
    xAxis: {
      categories: data.categories,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
          format: '{point.y} ({point.percentage:.1f}%)',
        },
        pointStart: 0,
        dataLabels: {
          verticalAlign: 'middle', 
        },
      },
    },
    tooltip: {
      valueDecimals: 2,
    },
    series: data.series,
    colorByPoint: false,
    colors: ['#4db6ac', '#544fc5',  '#009688',  '#26a69a'],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    const chart = Highcharts.chart('barChartContainer', options);
    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <div
      id="barChartContainer"
      style={{ height: '400px', maxWidth: '100%', position: 'inherit' }}
    ></div>
  );
};

export default BarChart;
