import React from 'react'
import NumberFormatSimple from '../../../components/Formaters/NumberFormatSimple'
const TableMeasures = ({ datas }) => {

  return (
    <div>
      <table className="table table-striped-columns p-2">
      {datas.map((datas, index) => (
        <tbody key={index}>
              <tr>
                <th scope="row">Longitud total <span className='units-lowercase'>[mm]</span></th>
                <td><NumberFormatSimple number={datas.total_length_measure}/></td>
              </tr>
              <tr>
                <th scope="row">Longitud de la cabina <span className='units-lowercase'>[mm]</span></th>
                <td><NumberFormatSimple number={datas.total_length_cab}/></td>
              </tr>
              <tr>
                <th scope="row">Altura total <span className='units-lowercase'>[mm]</span></th>
                <td><NumberFormatSimple number={datas.vehicle_height}/></td>
              </tr>
              <tr>
                <th scope="row">Ancho total <span className='units-lowercase'>[mm]</span></th>
                <td><NumberFormatSimple number={datas.vehicle_width}/></td>
              </tr>
              <tr>
                <th scope="row">Ancho de bastidor <span className='units-lowercase'>[mm]</span></th>
                <td><NumberFormatSimple number={datas.frame_width}/></td>
              </tr>
              <tr>
                <th scope="row">Ancho de cabina <span className='units-lowercase'>[mm]</span></th>
                <td><NumberFormatSimple number={datas.cab_width}/></td>
              </tr>
              <tr>
                <th scope="row">Distancia entre ejes <span className='units-lowercase'>[mm]</span></th>
                <td><NumberFormatSimple number={datas.axies_distance}/></td>
              </tr>
              <tr>
                <th scope="row">Voladizo anterior <span className='units-lowercase'>[mm]</span></th>
                <td><NumberFormatSimple number={datas.inner_cantilever}/></td>
              </tr>
              <tr>
                <th scope="row">Voladizo posterior <span className='units-lowercase'>[mm]</span></th>
                <td><NumberFormatSimple number={datas.rear_overhang}/></td>
              </tr>
              <tr>
                <th scope="row">Trocha delantera <span className='units-lowercase'>[mm]</span></th>
                <td><NumberFormatSimple number={datas.front_track}/></td>
              </tr>
              <tr>
                <th scope="row">Trocha trasera <span className='units-lowercase'>[mm]</span></th>
                <td><NumberFormatSimple number={datas.back_track}/></td>
              </tr>
              <tr>
                <th scope="row">Mínima altura al piso <span className='units-lowercase'>[mm]</span></th>
                <td><NumberFormatSimple number={datas.min_height_floor}/></td>
              </tr>
        </tbody>
        ))}
      </table>
    </div>
  )
}

export default TableMeasures