import React from 'react'
import "../../../styles/global.css"

const TableBrakes = ({ data }) => {
  if (!data) {
    return <p>No data available.</p>
  }
  return (
    <table className="table table-striped-columns p-2">
      {data.map((datas, index) => (
        <tbody key={index}>
          <th className='subtitle'>Tipo de freno</th>
          <tr>
            <th scope="row">Marca</th>
            <td>{datas.brakes_brand}</td>
          </tr>
          <tr>
            <th scope="row">Tipo</th>
            <td>{datas.brakes_type}</td>
          </tr>
          <tr>
            <th scope="row">Aspectos</th>
            <td>{datas.aspects}</td>
          </tr>
          <th className='subtitle'>Freno de parqueo</th>
          <tr>
            <th scope="row">Marca</th>
            <td>{datas.parking_brand}</td>
          </tr>
          <tr>
            <th scope="row">Tipo</th>
            <td>{datas.parking_type}</td>
          </tr>
          <th className='subtitle'>Sistema de ruedas</th>
          <tr>
            <th scope="row">Llantas</th>
            <td>{datas.weels}</td>
          </tr>
        </tbody>
      ))}
    </table>
  )
}

export default TableBrakes