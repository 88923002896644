import axios from 'axios';

const API_URL = 'https://backprod.appengine.lucia.com.co/runt/admin/mispermisos/';


const loaderSecure = async () => {
    try {
        const res = await axios.get(API_URL, {withCredentials: true });
        return res.data
    } catch (error) {
        console.error(error);
        return null;
    }
}

export default loaderSecure