import { useState, useEffect } from 'react';
import Spinner from '../../components/spinner/Spinner';
import TableEnvironment from './components/TableEnvironment';
import TitleComponent from '../../components/titleComponent/TitleComponent';
import TooltipModal from '../../components/modals/ToolTipModal';

const Env = () => {
    
const [cleanDataDetail, setCleanDataDetail] = useState([])
const [loading, setLoading] = useState(true)

useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("environment"));
    if (savedData) {
      setCleanDataDetail(savedData);
      setLoading(false)
    } else {
      setCleanDataDetail([]);
      setLoading(false)
    }
  }, []);

return (
    <div>
      <TitleComponent text={'Aporte ambiental'} />
      <div className='container'>
      {loading ? <Spinner /> : <TableEnvironment data={cleanDataDetail} />}
      </div>
    </div>
)
}

export default Env;