import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import exportingInit from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
// import './styles.css'

exportingInit(Highcharts);
ExportData(Highcharts);

const LineChart = ({ name, data, labels}) => {
    const options = {
      lang: {
        downloadCSV:"Descarga CSV",       
        viewFullscreen:"Ver en pantalla completa",
        printChart: "Imprimir Gráfica",
        downloadPNG:"Descarga PNG",
        downloadJPEG:"Descarga JPEG",
        downloadPDF:"Descarga Documento PDF",
        downloadSVG:"Descarga vector SVG",
        downloadXLS:"Descarga XLS",
        viewData:"Ver datos de tabla",
        category: 'Categoría'
    },
      title: {
        text: name,
      },
      xAxis: {
        categories: labels
      },
      yAxis: {
        title: {
          text: "Valores",
        },
      },
      series: data,
      tooltip: {
        valueDecimals: 2,
      },
    };
  
    useEffect(() => {
      const chart = Highcharts.chart('lineChartContainer', options);
      return () => {
        chart.destroy();
      };
    }, [data]);
  
    return <div id="lineChartContainer" style={{ height: '400px', maxWidth: '100%' }}></div>;
  };

export default LineChart