import React, { useState, useEffect } from "react";
import Spinner from "../../../components/spinner/Spinner";
import LineChart from "../../../components/charts/LineChart";
import ColumnsChart from "../../../components/charts/ColumnsChart";
import LoaderUtilsService from "../../../services/admin/utils/loaderUtilsService"
import LoaderUtilsService2 from "../../../services/admin/utils/loaderUtilsService2"
import TitleComponent from "../../../components/titleComponent/TitleComponent";
import NumberFormatter from "../../../components/Formarts";

const DetailChart = () => {
    const [loading, setLoading] = useState(true);
    const [tes, setTes] = useState([])
    const [counter, setCounter] = useState([])
    const [bateryPrice, setBateryPrice] = useState([]);
    const [scraping, setScraping] = useState([])
    const [lastTes, setLastTes] = useState()

    const setDatas = async () => {
        try {
            //Set RateTes
            const tesRes = await LoaderUtilsService('tes')
            const batery_data = await LoaderUtilsService('batery_prices')
            const scraping_data = await LoaderUtilsService2('scraping_data/0/0')
            const batery_price = batery_data[(batery_data.length - 1)].price
            const tesData = tesRes[0].data
            setLastTes(tesRes[0].date_time)
            const rates = Object.values(tesData).map(item => item.rate);
            const years = Object.values(tesData).map(item => item.year);
            setTes([rates, years])


            //Set Vehicle Counter
            const counterRes = await LoaderUtilsService('all_counter')
            const counterData = counterRes
            let counterCleanData = []
            for (let index = 0; index < counterData.length; index++) {
                let name = counterRes[index].name
                let count = parseFloat(counterRes[index].counter)
                counterCleanData.push([name, count])
            }

            setCounter(counterCleanData);
            setBateryPrice(batery_price);
            setScraping(scraping_data)
            setLoading(false)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        setDatas()
    }, [])

    if (loading) {
        return <Spinner />;
    }

    return (
        <div>
            <TitleComponent text={'Unidad administrativa'} />
            <br />
            <div className="container father-box">
                <div className="child-box">
                    <h4 className="subtitle">Curva de rentabilidad fija TES</h4>
                    <LineChart
                        data={[
                            {
                                name: "Tasa",
                                data: tes[0],
                            },
                        ]}
                        labels={tes[1]}
                    />
                    <p>*Actualizado por última vez: {lastTes.slice(0, 10)} </p>
                </div>
                <div className="child-box">
                    <div>
                        <h4 className="subtitle">Lista de valores en tiempo real</h4>
                        <br />
                        <p className='result-text'>Precio de batería [USD/kWh] <NumberFormatter number={bateryPrice} /></p>
                        <p className='result-text'>Precio de gasolina [$/gl] <NumberFormatter number={scraping[0].fuel_price_admin} /></p>
                        <p className='result-text'>Precio del diésel [$/gl] <NumberFormatter number={scraping[0].diesel_price_admin} /></p>
                        <p className='result-text'>Precio de la energía [$/kWh] <NumberFormatter number={scraping[0].energy_price_admin} /></p>
                        <p className='result-text'>IPC [%] <NumberFormatter number={scraping[0].ipc} /></p>
                        <p className='result-text'>Precio del dolar [$] <NumberFormatter number={scraping[0].dolar} /></p>
                    </div>
                </div>
            </div>
            <div className="father-box container">
            <div className="child-box">
                    <h4 className="subtitle">Contador de búsquedas</h4>
                    <ColumnsChart
                        title={'Contador'}
                        datas={counter}
                    />
                </div>
            </div>
        </div>

    );
};

export default DetailChart;