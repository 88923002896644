import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"

import GetDataDetail from '../../services/loaderService';
import TableSpeeds from './components/TableSpeeds';
import Spinner from '../../components/spinner/Spinner';
import TitleComponent from '../../components/titleComponent/TitleComponent';
import config from '../../config';

const domain = config.api_domain.domain

const Speeds = () => {
    const params = useParams()
    
    const id = params.id
    const url = `${domain}/speeds`
    const [cleanDataDetail, setcleanDataDetail] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        GetDataDetail(setcleanDataDetail, url, id)
        setLoading(false)
    }, [id, url, setcleanDataDetail])

    return (
        <div className='content'>

        <div className=''>
            <TitleComponent text={'Velocidades'} />
            {loading ? <Spinner /> : <TableSpeeds data={cleanDataDetail} />}
        </div>
    </div>
    )
}

export default Speeds;