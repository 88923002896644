// import "../../styles/main.css";
import "../../styles/animations/wickedcss.min.css";
// import "../../styles/global.css"
import logo from '../../images/logo/auteco-logo.png'
import logo_upb from '../../images/logo/logo-upb.png'
import TooltipModalTerms from "../../components/modals/ToolTipModalTerms";

const Main = () => {
  return (
    <div className="">
      <div className="fadeIn welcome">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ marginTop: "50px", fontSize: "50px" }}
        >
          <img src={logo} width={280} alt="Logo Auteco" />
        </div>
        <div
          className="d-flex justify-content-center align-items-center subtitle"
          style={{ marginTop: "20px", fontSize: "50px", fontWeight: "bold", textAlign: "center" }}
        >
          CALCULADORA BLUE
        </div>
        <div>
          <div className="mt-4 text-center">
            <p
              className="text-muted"
              style={{ maxWidth: "900px", margin: "0 auto" }}
            >
              Bienvenido a nuestra Calculadora Blue, una potente <strong>herramienta técnica</strong> y comercial en la administración y operación de nuestro portafolio de vehículos 100% eléctricos: Auteco.
            </p>
            <br />
            <p
              className="text-muted"
              style={{ maxWidth: "900px", margin: "0 auto" }}
            >

              Aquí podrás <strong>explorar un completo modelo medio ambiental y financiero</strong> de cada uno de los vehículos, con un amplio banco de información que permite abarcar la operación vehicular desde distintos frentes como su adquisición, su <strong>consumo energético</strong>, su mantenimiento, entre otros aspectos muy relevantes.

            </p>
            <br />
            <p
              className="text-muted"
              style={{ maxWidth: "900px", margin: "0 auto" }}
            >
              Nuestra Calculadora Blue en una poderosa herramienta en la gestión comercial de la compañía, permitiéndole al usuario explorar detalladamente el impacto que la <strong>movilidad sostenible</strong> genera en el medio ambiente, en sus vidas y en sus negocios, incentivando a través de información vital a aquellos potenciales clientes y generando una mayor tranquilidad en la toma de decisiones.

            </p>
            <br />
            <p
              className="text-muted"
              style={{ maxWidth: "900px", margin: "0 auto" }}
            >
              Este gran modelo matemático se desarrolló en conjunto con investigadores de la Facultad de Ingeniería Eléctrica y Electrónica de la Universidad Pontificia Bolivariana.
            </p>
            <br />
          </div>
          <div className="mt-4 d-flex align-items-center justify-content-center">
            <a href="/categories">
              <button>Comencemos</button>
            </a>
          </div>
          <br /><br /><br />
        </div>
      </div>
      <div className="main-footer">
        <div className="terms"><TooltipModalTerms /></div>
        <div className="upb-logo"><img src={logo_upb} width={50} alt="" /></div>
      </div>
    </div>

  );
};

export default Main;
