import { useState, useEffect } from 'react';
import Spinner from '../../components/spinner/Spinner';
import TitleComponent from '../../components/titleComponent/TitleComponent';
import TableProductivity from './components/TableProductivity';

const Env = () => {

const [cleanDataDetail, setCleanDataDetail] = useState([])
const [loading, setLoading] = useState(false)

useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("productivity"));
    if (savedData) {
      setCleanDataDetail(savedData);
      setLoading(false)
    } else {
      setCleanDataDetail([]);
      setLoading(false)
    }
  }, []);

return (
    <div>
        <TitleComponent text={'Productividad'} />
        {loading ? <Spinner /> : <TableProductivity data={cleanDataDetail} />}
    </div>
)
}

export default Env;