import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import TableBodyWork from './components/TableBodyWork';
import GetDataDetail from '../../services/loaderService';
import config from '../../config';

const domain = config.api_domain.domain

const BodyWork = () => {
    const params = useParams()
    const url = `${domain}/bodywork`

    const [cleanDataDetail, setcleanDataDetail] = useState([])

    const id = params.id
        
    useEffect(() => {
        GetDataDetail(setcleanDataDetail, url, id)
    }, [id, url, setcleanDataDetail])

    return (
        <div className='content'>
            <div className='axes-table'>
                <TableBodyWork data={cleanDataDetail} />
            </div>
        </div>
    )
}

export default BodyWork;