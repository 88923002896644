import axios from 'axios';
import config from '../config';

const domain = config.api_domain.domain

const GetMaintenanceData = async (id, type, anualUse, yearsUse, fuelId, scraping) => {
    try {
        const url = (`${domain}/maintenance/${id}/${type}/${anualUse}/${yearsUse}/${fuelId}/${scraping}`)
        const res = await axios.get(url);
        return res.data.body;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export default GetMaintenanceData;