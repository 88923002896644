import React, { useState, useEffect } from 'react';
import UpdateService from '../../../../services/admin/updates/updateService';
import GetDataDetail from '../../../../services/loaderService';
import { BiCheck } from 'react-icons/bi';
import config from '../../../../config';

const UpdateCharger = ({ id }) => {
    const [datas, setDatas] = useState([]);
    const domain = config.api_domain.domain;
    const url = `${domain}/charger`;

    useEffect(() => {
        GetDataDetail(setDatas, url, id);
    }, [setDatas, url, id]);

    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (datas.length > 0) {
            setFormData({
                id: datas[0].id,
                charge_type_1: datas[0].charge_type_1 || "",
                system_config_1: datas[0].system_config_1 || "",
                charge_time_1: datas[0].charge_time_1,
                charge_type_2: datas[0].charge_type_2 || "",
                system_config_2: datas[0].system_config_2 || "",
                charge_time_2: datas[0].charge_time_2,
            });
        }
    }, [datas]);

    const [showForm, setShowForm] = useState(false);
    const [showUpdatedText, setShowUpdatedText] = useState(true);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        UpdateService('updateCharger', id, formData)
            .then(() => {
                setShowForm(false);
                setShowUpdatedText(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleOpenForm = () => {
        setShowForm(true);
        setShowUpdatedText(false);
    };

    return (
        <div>
            {showForm ? (
                <>
                    <div>
                        <label className='modal-lavel'>
                            Cargador tipo 1
                            <input
                                type="text"
                                className='form-control'
                                name="charge_type_1"
                                value={formData.charge_type_1}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Configuración de sistema 1
                            <input
                                type="text"
                                className='form-control'
                                name="system_config_1"
                                value={formData.system_config_1}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Tiempo de carga 1 [h]
                            <input
                                type="number"
                                className='form-control'
                                name="charge_time_1"
                                value={formData.charge_time_1}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Cargador tipo 2
                            <input
                                type="text"
                                className='form-control'
                                name="charge_type_2"
                                value={formData.charge_type_2}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Configuración de sistema 2
                            <input
                                type="text"
                                className='form-control'
                                name="system_config_2"
                                value={formData.system_config_2}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Tiempo de carga 2 [h]
                            <input
                                type="number"
                                className='form-control'
                                name="charge_time_2"
                                value={formData.charge_time_2}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        

                        <button onClick={handleSubmit}>Actualizar Cargador</button>
                    </div>
                </>
            ) : null}

            {showUpdatedText ? (
                <>
                    <p>Cargador actualizado <BiCheck style={{ fontSize: '30px', color: 'green' }} /></p>
                    <button onClick={handleOpenForm}>Editar Cargador</button>
                </>
            ) : null}
        </div>
    );
};

export default UpdateCharger;