import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from "react-router-dom"
import axios from 'axios';
import config from '../../config';
import TitleComponent from '../../components/titleComponent/TitleComponent';
import TableAssurance from '../assurance/components/TableAssurance';
import TableMeasures from './components/TableMeasures';

const domain = config.api_domain.domain
const Measures = () => {
    const params = useParams()
    const [cleanDataDetail, setcleanDataDetail] = useState([])
    const getDataDetail = async () => {
        const id = params.id
        const url = `${domain}/measures/${id}`
        const res = await axios.get(url)
        setcleanDataDetail(res.data.body)
    }

    useEffect(() => {
        getDataDetail()
    })

    return (
        <div className='text-dark'>
            <TitleComponent text={'Dimensiones'} />
            <TableMeasures datas={cleanDataDetail} />
        </div>
    )
}

export default Measures;