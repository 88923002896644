import React, { useState, useEffect } from 'react';
import UpdateService from '../../../../services/admin/updates/updateService';
import GetDataDetail from '../../../../services/loaderService';
import { BiCheck } from 'react-icons/bi';
import config from '../../../../config';

const UpdateBrakes = ({ id }) => {
    const [datas, setDatas] = useState([]);
    const domain = config.api_domain.domain;
    const url = `${domain}/brakes`;

    useEffect(() => {
        GetDataDetail(setDatas, url, id);
    }, [setDatas, url, id]);

    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (datas.length > 0) {
            setFormData({
                brakes_brand: datas[0].brakes_brand || "",
                brakes_type: datas[0].brakes_type || "",
                aspects: datas[0].aspects || "",
                parking_brand: datas[0].parking_brand || "",
                parking_type: datas[0].parking_type || "",
                weels: datas[0].weels || "",
            });
        }
    }, [datas]);

    const [showForm, setShowForm] = useState(false);
    const [showUpdatedText, setShowUpdatedText] = useState(true);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        UpdateService('updateBrakes', id, formData)
            .then(() => {
                setShowForm(false);
                setShowUpdatedText(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleOpenForm = () => {
        setShowForm(true);
        setShowUpdatedText(false);
    };

    return (
        <div>
            {showForm ? (
                <>
                    <div>
                        <label className='modal-lavel'>
                            Marca
                            <input
                                type="text"
                                className='form-control'
                                name="brakes_brand"
                                value={formData.brakes_brand}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Tipo
                            <input
                                type="text"
                                className='form-control'
                                name="brakes_type"
                                value={formData.brakes_type}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                            Aspectos
                            <input
                                type="text"
                                className='form-control'
                                name="aspects"
                                value={formData.aspects}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                        Marca de freno de parqueo
                            <input
                                type="text"
                                className='form-control'
                                name="parking_brand"
                                value={formData.parking_brand}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                        Tipo de freno de parqueo
                            <input
                                type="text"
                                className='form-control'
                                name="parking_type"
                                value={formData.parking_type}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                        Llantas
                            <input
                                type="text"
                                className='form-control'
                                name="weels"
                                value={formData.weels}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        

                        <button onClick={handleSubmit}>Actualizar Frenos</button>
                    </div>
                </>
            ) : null}

            {showUpdatedText ? (
                <>
                    <p>Frenos actualizados <BiCheck style={{ fontSize: '30px', color: 'green' }} /></p>
                    <button onClick={handleOpenForm}>Editar Frenos</button>
                </>
            ) : null}
        </div>
    );
};

export default UpdateBrakes;