import React, { useState, useEffect } from "react";
import LoaderUtilsService from "../../../services/admin/utils/loaderUtilsService";
import UpdateService from "../../../services/admin/updates/updateUtils";
import Spinner from "../../../components/spinner/Spinner";
import { Link } from "react-router-dom";
import UpperFormatt from "../../../components/UpperFormatt";
import TitleComponent from "../../../components/titleComponent/TitleComponent";
import NumberFormatter from "../../../components/Formarts";
import TooltipModalConsts from "../../../components/modals/ToolTipModalConsts";

const ListConst = () => {
  const [loading, setLoading] = useState(true);
  const [consts, setConsts] = useState([]);

  const options = {
    useGrouping: true,
    minimumFractionDigits: 0,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await LoaderUtilsService("consts");
        setConsts(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleEdit = (index) => {
    const updatedConsts = [...consts];
    updatedConsts[index].editMode = true;
    setConsts(updatedConsts);
  };

  const handleUpdate = async (index) => {
    const updatedConsts = [...consts];
    const { id, value } = updatedConsts[index];
    
    try {
      let dataValue = {value: value}
      await UpdateService("update_consts", id, dataValue);
      updatedConsts[index].editMode = false;
      setConsts(updatedConsts);
    } catch (error) {
      console.error(error);
    }
  };

  const handleValue = (index, e) => {
    const updatedConsts = [...consts];
    updatedConsts[index].value = e.target.value;
    setConsts(updatedConsts);
  };

  const handleCancel = (index) => {
    const updatedConsts = [...consts];
    updatedConsts[index].editMode = false;
    setConsts(updatedConsts);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div >
      <TitleComponent text={'Lista de constantes del modelo'} />
      <div className="container">
      <br />
      <div className="row">
        <div className="">
          <h3 className="subtitle">Constantes <TooltipModalConsts/> </h3>
          <br />
          <table className="table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Valor</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              {consts.map((datas, index) => (
                <tr key={index}>
                  <td><UpperFormatt string={datas.name}/> </td>
                  <td>
                    <input
                      type="number"
                      className='form-control'
                      onChange={(e) => handleValue(index, e)}
                      value={datas.value.toLocaleString(undefined, options).replace(/,/g, '.')}
                      disabled={!datas.editMode}
                    />
                  </td>
                  <td className="col-options">
                    {datas.editMode ? (
                      <>
                        <button
                          className="btn btn-primary m-1"
                          onClick={() => handleUpdate(index)}
                        >
                          Actualizar
                        </button>
                        <button
                          className="btn btn-secondary m-1"
                          onClick={() => handleCancel(index)}
                        >
                          Cancelar
                        </button>
                      </>
                    ) : (
                      <button
                        className="btn btn-primary"
                        onClick={() => handleEdit(index)}
                      >
                        Editar
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      </div>
    </div>
  );
};

export default ListConst;
