import React from 'react'
import './styleTitleComponent.css'

const TitleComponent = ({text}) => {
  return (
    <div>
      <h2 className='titleComponent'>{text}</h2>
    </div>
  )
}

export default TitleComponent