import React, { useState, useEffect } from 'react';
import UpdateService from '../../../../services/admin/updates/updateService';
import GetDataDetail from '../../../../services/loaderService';
import { BiCheck } from 'react-icons/bi';
import config from '../../../../config';

const UpdateFeature = ({ id, fields }) => {
const [datas, setDatas] = useState([]);
const domain = config.api_domain.domain;
const url = `${domain}/features`;

useEffect(() => {
    GetDataDetail(setDatas, url, id);
}, [setDatas, url, id]);

const [formData, setFormData] = useState({});
useEffect(() => {
    if (datas.length > 0) {
        setFormData({
            feature_brand: datas[0].feature_brand || "",
            line: datas[0].line || "",
            model: datas[0].model || "",
            service: datas[0].service || "",
            operation: datas[0].operation || "",
            axies: datas[0].axies,
            number_weels: datas[0].number_weels,
            weels_measure: datas[0].weels_measure || "",
            weels_factor: datas[0].weels_factor,
            weight: datas[0].weight,
            front_axle_weight: datas[0].front_axle_weight,
            back_axle_weight: datas[0].back_axle_weight,
            gvwr: datas[0].gvwr,
            gross_weigh_combined: datas[0].gross_weigh_combined,
            max_load: datas[0].max_load,
            hill_start_ability: datas[0].hill_start_ability,
            max_speed: datas[0].max_speed,
            total_length: datas[0].total_length,
            axies_distance_TANDEM: datas[0].axies_distance_tandem,
            back_axle_distance_kingpin: datas[0].back_axle_distance_kingpin,
            num_passengers: datas[0].num_passengers || "",
            fuel_cap: datas[0].fuel_cap,
        });
    }
}, [datas]);

const [showForm, setShowForm] = useState(false);
const [showUpdatedText, setShowUpdatedText] = useState(true);

const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
};

const handleSubmit = (e) => {
    e.preventDefault();

    UpdateService('updateFeature', id, formData)
        .then(() => {
            setShowForm(false);
            setShowUpdatedText(true);
        })
        .catch((error) => {
            console.log(error);
        });
};

const handleOpenForm = () => {
    setShowForm(true);
    setShowUpdatedText(false);
};

return (
    <div>
        {showForm ? (
            <>
                <div>
                    <label className='modal-lavel' hidden={fields} >
                        Marca
                        <input
                            type="text"
                            className='form-control'
                            name="feature_brand"
                            value={formData.feature_brand}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel' hidden={fields}>
                        Linea
                        <input
                            type="text"
                            className='form-control'
                            name="line"
                            value={formData.line}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel' hidden={fields}>
                        Año del modelo
                        <input
                            type="text"
                            className='form-control'
                            name="model"
                            value={formData.model}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel' hidden={fields}>
                        Servicio
                        <input
                            type="text"
                            className='form-control'
                            name="service"
                            value={formData.service}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel' hidden={fields}>
                        Operación
                        <input
                            type="text"
                            className='form-control'
                            name="operation"
                            value={formData.operation}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel' hidden={fields}>
                        Número de ejes
                        <input
                            type="number"
                            className='form-control'
                            name="axies"
                            value={formData.axies}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel' hidden={fields}>
                        Número de llantas
                        <input
                            type="text"
                            className='form-control'
                            name="number_weels"
                            value={formData.number_weels}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel'>
                        Medida de llantas
                        <input
                            type="text"
                            className='form-control'
                            name="weels_measure"
                            value={formData.weels_measure}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel' hidden={fields}>
                        Factor llanta
                        <input
                            type="number"
                            className='form-control'
                            name="weels_factor"
                            value={formData.weels_factor}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel' hidden={fields}>
                        Peso [kg]
                        <input
                            type="number"
                            className='form-control'
                            name="weight"
                            value={formData.weight}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel' hidden={fields}>
                        Peso eje delantero [kg]
                        <input
                            type="number"
                            className='form-control'
                            name="front_axle_weight"
                            value={formData.front_axle_weight}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel' hidden={fields}>
                        Peso eje trasero [kg]
                        <input
                            type="number"
                            className='form-control'
                            name="back_axle_weight"
                            value={formData.back_axle_weight}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel'>
                        Peso bruto vehicular [kg] 
                        <input
                            type="number"
                            className='form-control'
                            name="gvwr"
                            value={formData.gvwr}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel' hidden={fields}>
                        Peso bruto combinado [kg]
                        <input
                            type="number"
                            className='form-control'
                            name="gross_weigh_combined"
                            value={formData.gross_weigh_combined}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel'>
                        Carga disponible máxima [kg]
                        <input
                            type="number"
                            className='form-control'
                            name="max_load"
                            value={formData.max_load}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel' hidden={fields}>
                        Capacidad de arranque en pendiente [%]
                        <input
                            type="number"
                            className='form-control'
                            name="hill_start_ability"
                            value={formData.hill_start_ability}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel' hidden={fields}>
                        Velocidad máxima [kph]
                        <input
                            type="number"
                            className='form-control'
                            name="max_speed"
                            value={formData.max_speed}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel' hidden={fields}>
                        Distancia entre ejes TANDEM <span className='units-lowercase'>[mm]</span>
                        <input
                            type="number"
                            className='form-control'
                            name="axies_distance_TANDEM"
                            value={formData.axies_distance_TANDEM}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel' hidden={fields}>
                        Distancia King-pin al centro eje trasero <span className='units-lowercase'>[mm]</span>
                        <input
                            type="number"
                            className='form-control'
                            name="back_axle_distance_kingpin"
                            value={formData.back_axle_distance_kingpin}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <label className='modal-lavel'>
                        Capacidad del tanque [gl]
                        <input
                            type="number"
                            className='form-control'
                            name="fuel_cap"
                            value={formData.fuel_cap}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    <br /><br />
                    <button onClick={handleSubmit}>Actualizar Generalidades</button>
                </div>
                <br /><br />
            </>
        ) : null}

        {showUpdatedText ? (
            <>
                <p>Generalidades actualizadas <BiCheck style={{ fontSize: '30px', color: 'green' }} /></p>
                <button onClick={handleOpenForm}>Editar Generalidades</button>
            </>
        ) : null}
    </div>
);
};

export default UpdateFeature;