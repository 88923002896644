
import React, { useState, useEffect } from "react";
import { AiFillHome } from "react-icons/ai";
import LoaderUtilsService from "../../services/admin/utils/loaderUtilsService"
import { Outlet, NavLink } from "react-router-dom";
import { FaBars, FaCogs, FaCar, FaTags, FaToolbox, FaWrench, FaShieldAlt, FaBatteryFull } from "react-icons/fa";
import logo_black from '../../images/logo/logo-black.png'

import "../../styles/body/sidenav.css";

import { Link } from "react-router-dom";

const MainAdmin = () => {
  const [show, setShow] = useState(false);
  const [tes, setTes] = useState([])
  const [counter, setCounter] = useState([])

  const setDatas = async () => {
    try {
      //Set RateTes
      const tesRes = await LoaderUtilsService('tes')
      const tesData = tesRes[0].data
      const rates = Object.values(tesData).map(item => item.rate);
      const years = Object.values(tesData).map(item => item.year);
      setTes([rates, years])


      //Set Vehicle Counter
      const counterRes = await LoaderUtilsService('all_counter')
      const counterData = counterRes
      let counterCleanData = []
      for (let index = 0; index < counterData.length; index++) {
        let name = counterRes[index].name
        let count = parseFloat(counterRes[index].counter)
        counterCleanData.push([name, count])
      }

      setCounter(counterCleanData);

    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    setDatas()
  }, [])


  return (
    <main className={show ? "space-toggle" : null}>
      <header className={`header ${show ? "space-toggle" : null}`}>
        <div className="header-toggle" onClick={() => setShow(!show)}>
          <FaBars style={{ color: "white" }} />
        </div>
        <div className="container-fluid d-flex"></div>
        <div
          className="d-none d-sm-flex justify-content-end text-white"
          style={{ width: "100%", marginTop: "10px" }}
        >
          <NavLink to={`/categories`}
            className="">
            <img src={logo_black} alt="logo-auteco" width={50} />
          </NavLink>
        </div>
      </header>

      <aside className={`sidebar ${show ? "show" : null}`}>
        <nav className="nav">
          <div>
            <div className="nav-list">
              <NavLink 
              to={`charts`} 
              className="nav-li"
              activeClassName="nav-li-active"
              title="Inicio">
                <AiFillHome className="nav-logo-icon" />
                <span className="nav-li-name">Inicio</span>
              </NavLink>
              <NavLink 
                to={`list`} 
                className="nav-li"
                activeClassName="nav-li-active"
                title="Vehículos">
                <FaCar className="nav-logo-icon" />
                <span className="nav-li-name">Vehículos</span>
              </NavLink>
              <NavLink to={`list_categories`} className="nav-li"
                activeClassName="nav-li-active"
                title="Categorías">
                <FaTags className="nav-logo-icon" />
                <span className="nav-li-name">Categorías</span>
              </NavLink>
              <NavLink to={`consts`} className="nav-li"
                activeClassName="nav-li-active"
                title="Constantes">
                <FaWrench className="nav-logo-icon" />
                <span className="nav-li-name">Constantes</span>
              </NavLink>
              <NavLink to={`list_consumables`} className="nav-li"
                activeClassName="nav-li-active"
                title="Consumibles">
                <FaToolbox className="nav-logo-icon" />
                <span className="nav-li-name">Consumibles</span>
              </NavLink>
              <NavLink to={`list_soat`} className="nav-li"
                activeClassName="nav-li-active"
                title="SOAT">
                <FaShieldAlt className="nav-logo-icon" />
                <span className="nav-li-name">SOAT</span>
              </NavLink>
              <NavLink to={`list_batery`} className="nav-li"
                activeClassName="nav-li-active"
                title="Precio de batería">
                <FaBatteryFull className="nav-logo-icon" />
                <span className="nav-li-name">Precio de batería</span>
              </NavLink>
            </div>
          </div>
        </nav>
      </aside>
      <br />
      <Outlet />
    </main>
  );
};

export default MainAdmin;
