import React from "react";
import LoaderAllVehicles from "../../../services/loaderAllVehicles";
import LoaderElectricalVehicles from "../../../services/loaderElectricalVehicles";
import Spinner from "../../../components/spinner/Spinner";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaEdit, FaSearch } from "react-icons/fa";
import TitleComponent from "../../../components/titleComponent/TitleComponent";
import UpperFormatt from "../../../components/UpperFormatt";

const AllVehiclesAdmin = () => {
  const [loading, setLoading] = useState(true);
  const [cleanDataDetail, setcleanDataDetail] = useState([]);
  const [cleanDataElectrical, setcleanDataElectrical] = useState([]);

  useEffect(() => {
    LoaderAllVehicles(setcleanDataDetail).then(() => setLoading(false));
    LoaderElectricalVehicles(setcleanDataElectrical).then(() =>
      setLoading(false)
    );
  }, [setcleanDataDetail, setcleanDataElectrical, loading]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div >
      <TitleComponent text={'Administrador de vehículos'}/>
      <br />
      <div className="father-box container">
        <div className="child-box">
          <h3>Eléctricos</h3>
          <table className="table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Categoría</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              {cleanDataElectrical.map((datas, index) => (
                <tr key={index}>
                  <td>{datas.name}</td>
                  <td className="category-uppercase"><UpperFormatt string={datas.type}/></td>
                  <td className="col-options">
                    {/* <Link to={`/admin/detail/${datas.id}`}><FaSearch className="options-logo" /></Link>
                    <br /> */}
                    <Link to={`/admin/update/${datas.type}/${datas.fuel}/${datas.id}`}><FaEdit className="options-logo" /></Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="child-box">
          <h3>Combustión</h3>
          <table className="table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Categoría</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              {cleanDataDetail.map((datas, index) => (
                <tr key={index}>
                  <td>{datas.name}</td>
                  <td className="category-uppercase"><UpperFormatt string={datas.type}/></td>
                  <td className="col-options">
                    {/* <Link to={`/admin/detail/${datas.id}`}><FaSearch className="options-logo" /></Link>
                    <br /> */}
                    <Link to={`/admin/update/${datas.type}/${datas.fuel}/${datas.id}`}><FaEdit className="options-logo" /></Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <br />
      <br />
      <div className="btn-new">
        <Link to={'/admin/new'}><button>Agregar Vehículo</button></Link>
      </div>
    </div>
  );
};

export default AllVehiclesAdmin;
