import React from 'react';
import DownloadPdf from '../../../components/DownloadPdf';
import NumberFormatter from '../../../components/Formarts';
import BarChart from '../../../components/charts/BarChart';
import LineChart from '../../../components/charts/LineChart';
import TooltipModal from '../../../components/modals/ToolTipModal';

const TableConsumption = ({ data }) => {

  if (!data || data.length <= 0 || data.error || data === null || data === []) {
    return <p className="subtitle">Sin parámetros asignados</p>;
  }

  const extractChartData = (data) => {
    const values = data.map(item => item.present_value);
    return values;
  };

  let chartData;
  let secondChartData;

  if (data.length > 0) {
    chartData = extractChartData(data[0].annual_fuel_proyection.annual_proyection);
    secondChartData = extractChartData(data[0].annual_proyection.annual_proyection);
  }

  return (
    <div>
      {data.length > 0 && (
        <>

          <div className="father-box">
            <div className="child-box">
              <div className="title">
                <h4 className='subtitle'>VEHÍCULO ELÉCTRICO </h4>
                <br />
              </div>
              <p>Energía nominal [kWh] <span className='result-text' ><NumberFormatter number={data[0].nominal_energy} /></span></p>
              <p>Autonomía [km]  <span className='result-text' >{data[0].autonomy} </span></p>
              <p>Consumo de energía [kWh/km] <span className='result-text' ><NumberFormatter number={data[0].electrical_consumption} /></span> <TooltipModal text={'Cantidad de energía consumida por el vehículo en un (1) kilómetro recorrido'} title={'Consumo de energía [kWh/km]'} /></p>
              <p>Costo de energía por kilómetro [$/km] <span className='result-text' >$ <NumberFormatter number={data[0].electrical_cost_km} /></span></p>
              <p>Costo de energía mensual [$] <span className='result-text' >$ <NumberFormatter number={data[0].month_energy_cost} /></span></p>
              <br />
              <div className="title">
                <h5>PROYECCIÓN DE CONSUMO DE VEHÍCULOS ELÉCTRICOS</h5>
                <br />
              </div>
              <table className="table table-striped-columns p-2">
                <tbody>
                  <tr>
                    <td className="table-head">Año</td>
                    <td className="table-head">Proyección anual</td>
                  </tr>
                  {data[0].annual_proyection.annual_proyection.map((datas, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>$ <NumberFormatter number={datas['present_value']} /></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <h4 className="big-result-text">Total: $ <NumberFormatter number={data[0].annual_proyection.total} /></h4>
            </div>
            <div className="child-box">
              <div className="title">
                <h4 className='subtitle'>VEHÍCULO A COMBUSTIÓN</h4>
                <br />
              </div>
              <p>Consumo de energía [kWh/km] <span className='result-text' ><NumberFormatter number={data[0].combustion_consumption} /></span></p>
              <p>Consumo de combustible [gl/km] <span className='result-text' ><NumberFormatter number={data[0].fuel_consumption} /></span> <TooltipModal text={'Cantidad de galones de combustible consumidos por el vehículo durante un (1) kilómetro de recorrido'} title={'Consumo de combustible [gl/km]'} /></p>
              <p>Rendimiento de combustible [km/gl] <span className='result-text' ><NumberFormatter number={data[0].fuel_efficiency} /></span> <TooltipModal text={'Cantidad de kilómetros posibles de recorrer con un (1) galón de combustible'} title={'Rendimiento de combustible [km/gl]'} /></p>
              <p>Costo de combustible por kilómetro [$/km] <span className='result-text' >$ <NumberFormatter number={data[0].fuel_cost_km} /></span></p>
              <p>Costo de combustible mensual [$] <span className='result-text' >$ <NumberFormatter number={data[0].monthFuelCost} /></span></p>
              <br />
              <div className="title">
                <h5>PROYECCIÓN DE CONSUMO DE VEHÍCULOS A COMBUSTIÓN</h5>
                <br />
              </div>
              <table className="table table-striped-columns p-2">
                <tbody>
                  <tr>
                    <td className="table-head">Año</td>
                    <td className="table-head">Proyección anual</td>
                  </tr>
                  {data[0].annual_fuel_proyection.annual_proyection.map((datas, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>$ <NumberFormatter number={datas['present_value']} /></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <h4 className="big-result-text">Total: $ <NumberFormatter number={data[0].annual_fuel_proyection.total} /> </h4>
            </div>
          </div>
          <div className="father-box">
            <div className="child-box">
              <BarChart data={{
                categories: ['Consumo'],
                series: [
                  {
                    name: 'Eléctrico',
                    data: [parseFloat(data[0].annual_proyection.total)],
                  },
                  {
                    name: 'Combustión',
                    data: [parseFloat(data[0].annual_fuel_proyection.total)],
                  }
                ],
              }} />
            </div>

            <div className="child-box">
              {chartData && secondChartData && (
                <LineChart data={[
                  {
                    name: 'Combustión',
                    data: chartData,
                  },
                  {
                    name: 'Eléctrico',
                    data: secondChartData,
                  }
                ]} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TableConsumption;
