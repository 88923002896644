import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import GetDataDetail from '../../services/loaderService';
import TableCompany from './components/TableCompany';
import TitleComponent from '../../components/titleComponent/TitleComponent';
import config from '../../config';

const domain = config.api_domain.domain

const Company = () => {
    const params = useParams()
    const id = params.id
    const url = `${domain}/company`
    const [cleanDataDetail, setcleanDataDetail] = useState([])
    
    useEffect(() => {
        GetDataDetail(setcleanDataDetail, url, id)
    }, [id, url, setcleanDataDetail])

    return (
        <div className='content'>
            <div className=''>
                <TitleComponent text={'Compañia'} />
                {/* <TableCompany data={cleanDataDetail} /> */}
            </div>
        </div>
    )
}

export default Company