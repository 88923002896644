import React from 'react'
import jsPDF from 'jspdf';
import "../styles/contens.css"

function DownloadPdf( { dataPdf } ) {

    const handleDownload = () => {
        const pdf = new jsPDF();
        pdf.text('Comparativo medio ambiental', 10, 10);
        let position = 20;
        for (const key in dataPdf) {
            pdf.text(`${key}: ${dataPdf[key]}`, 10, position);
            position += 10;
        }
        
        pdf.save("Comparativo Medio Ambiental Auteco .pdf");
      };
  return (
    <div><button onClick={handleDownload}className='boton'>Descargar PDF</button></div>
  )
}

export default DownloadPdf