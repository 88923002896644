import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import LoaderTypes from "../../../services/loaderTypes";
import Spinner from "../../../components/spinner/Spinner";
import { useMediaQuery } from "react-responsive";
import LocalStorageCleaner from "../../../scripts/LocalStorageCleaner";
import "../../../styles/contens.css";
import "../../../styles/global.css";

import UpdaterCounter from "../../../services/updateCounter";

const CardsTypes = () => {
  const [data, setData] = useState(null);
  const { type } = useParams();
  const [currentGroup, setCurrentGroup] = useState(0);
  const [loading, setLoading] = useState(true);

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1100px)" });
  const isLargeScreen = useMediaQuery({ query: "(max-width: 1600px)" });
  const itemsPerGroup = isSmallScreen ? 1 : isLargeScreen ? 2 : 3;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const categoriesData = await LoaderTypes(type);
        setData(categoriesData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [type]);

  const handlePrevClick = () => {
    setCurrentGroup((prevGroup) => prevGroup - 1);
  };

  const handleNextClick = () => {
    setCurrentGroup((prevGroup) => prevGroup + 1);
  };

  const HandleCounter = (id, name) => {
    LocalStorageCleaner()
    UpdaterCounter(id, name)
  }

  const renderCategories = () => {
    if (loading) {
      return <Spinner />;
    }

    if (!data || data.body.length === 0) {
      return <h2>Sin vehículos de esta categoría</h2>;
    }

    const startIndex = currentGroup * itemsPerGroup;
    const endIndex = startIndex + itemsPerGroup;
    const categoriesToShow = data.body.slice(startIndex, endIndex);

    return categoriesToShow.map((item) => (
      <>
        <div className="categories" key={item.id}>
          <div className="card m-5" id="types">
            <Link to={`/home/${item.id_vehicle}/${item.type}/subsystems/features/${item.id_vehicle}`} onClick={() => HandleCounter(item.id, item.name)}>
              <p className="category text-center mt-3" id="textCategories">
                {item.name}
              </p>
              <div className="vehicle-image">
                <img src={item.left_diagonal} alt="" />
              </div>
            </Link>
          </div>
        </div>
      </>
    ));
  };

  return (
    <div>
      <div
        className="d-flex justify-content-center align-items-center subtitle"
        style={{ marginTop: "100px", marginBottom: "-100px", fontSize: "50px", fontWeight: "bold", textAlign: "center" }}
      >
        SELECCIÓN DE VEHÍCULO
      </div>
      <div
        id="carouselExampleDark"
        className="carousel carousel-dark slide d-flex justify-content-center align-items-center vh-100"
        data-bs-ride="carousel"
        data-bs-interval="false"
      >
        <div className="carousel-indicators"></div>
        <div className="carousel-inner d-flex justify-content-center align-items-center">
          {renderCategories()}
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="prev"
          onClick={handlePrevClick}
          disabled={currentGroup === 0 || !data || data.body.length === 0}
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="next"
          onClick={handleNextClick}
          disabled={
            !data ||
            data.body.length === 0 ||
            (currentGroup + 1) * itemsPerGroup >= data.body.length
          }
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default CardsTypes;