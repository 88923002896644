import React from 'react'
import DownloadPdf from '../../../components/DownloadPdf'
import NumberFormatter from '../../../components/Formarts';
import TooltipModal from '../../../components/modals/ToolTipModal';
import BarChart from '../../../components/charts/BarChart';
import NumberFormatSimple from '../../../components/Formaters/NumberFormatSimple';


const TableBatery = ({ data }) => {

  let totalSumChange = 0;
  let totalSumSell = 0;

  if (!data || data.length <= 0 || data.error || data === null || data === []) {
    return <p className="subtitle">Sin parámetros asignados</p>;
  }

  data[0].saleBateryValueProyected.forEach((datas) => {
    totalSumSell += datas;
  });

  data[0].changeBateryValueProyected.forEach((datas) => {
    totalSumChange += datas;
  });

  return (
    <div>{data.length > 0 && (
      <>

        <div className="father-box">
          <div className="child-sm-box">
            <div className="title">
              <h4 className='subtitle'>VEHÍCULO ELÉCTRICO </h4>
              <br />
            </div>

            <h4 className="subtitle">Autonomía por kWh [km/kWh] <TooltipModal text={'Cantidad de kilómetros recorridos por cada unidad de energía almacenada en la batería'} title={'Autonomía por kWh [km/kWh]'} /></h4>
            <p className='big-result-text'> <NumberFormatter number={data[0]['autonomyKwh']} /></p>
            <br />

            <h4 className="subtitle">Kilómetros totales de la batería [km] <TooltipModal text={'Cantidad total disponible para recorrer en el tiempo de vida útil de la batería, antes de que llegue a su 80% de SOH (estado de salud)'} title={'Kilómetros totales de la batería [km]'} /></h4>
            <p className='big-result-text'> <NumberFormatter number={data[0]['totalkm']} /></p>
            <br />

            <h4 className="subtitle">Precio de batería <TooltipModal text={'Costo estimado de la batería del vehículo'} title={'Precio de batería'} /></h4>
            <p className='big-result-text'>$ <NumberFormatter number={data[0]['bateryPrice']} /></p>
            <br />
            <h4 className="subtitle">Precio de carga <TooltipModal text={'Costo equivalente a un ciclo de carga de la batería del 20% al 100%'} title={'Precio de carga'} /></h4>
            <p className='big-result-text'>$ <NumberFormatter number={data[0]['chargePrice']} /></p>
          </div>
          <div className="child-sm-box">
            <h4 className="subtitle">Total despreciable <TooltipModal text={'Valor que pierde la batería desde el comienzo de su operación hasta el final de su vida útil'} title={'Total despreciable'} /></h4>
            <p className='big-result-text'>$ <NumberFormatter number={data[0]['totalNegligible']} /></p>
            <br />
            <h4 className="subtitle">Vida útil <TooltipModal text={'Tiempo estimado en años que opera la batería bajo las condiciones simuladas. Este tiempo se considera hasta que la batería llega a un 80% de SOH (estado de salud)'} title={'Vida útil'} /></h4>
            <p className='big-result-text'>{data[0]['usefulLife']} años</p>
            <br />
            <h4 className="subtitle">Depreciación anual <TooltipModal text={'Valor anual que se deprecia la batería en su operación'} title={'Depreciación anual'} /></h4>
            <p className='big-result-text'>$ <NumberFormatter number={data[0]['annualNegligible']} /></p>
            <br />
            <h4 className="subtitle">Valor residual <TooltipModal text={'Valor que tendrá la batería al final de su vida útil'} title={'Valor residual'} /></h4>
            <p className='big-result-text'>$ <NumberFormatter number={data[0]['residualValue']} /></p>
          </div>
        </div>


        <div className="father-box">
          <div className="child-sm-box">
            <h5>VALOR DE BATERÍA NUEVA <TooltipModal text={'Costo que tendría la compra de una batería nueva, de las mismas condiciones en términos energéticos, en cada año de proyección. Este valor depende de la variación del precio por kWh en el mercado'} title={'Valor de batería nueva'} /></h5>
            <br />
            <table className="table table-striped-columns p-2">
              <tbody>
                <tr>
                  <td className="table-head">Año</td>
                  <td className="table-head">Proyección</td>
                </tr>
                {data[0].bateryValueProyected.map((datas, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>$ <NumberFormatter number={datas} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="child-box">
            <h5>VALOR DE REFERENCIA <TooltipModal text={'Valor de referencia utilizado en el cálculo del recambio de batería. Si en un año determinado no se debe cambiar la batería, este valor seguirá constante. Una vez se haga un cambio, tomará el valor del año inmediatamente anterior del parámetro "Valor de batería nueva"'} title={'Valor de referencia'} /></h5>
            <br />
            <table className="table table-striped-columns p-2">
              <tbody>
                <tr>
                  <td className="table-head">Año</td>
                  <td className="table-head">Proyección</td>
                </tr>
                {data[0].bateryValueReferencingProyected.map((datas, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>$ <NumberFormatter number={datas} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>


          <div className="child-box">
            <h5>DEPRECIACIÓN ACUMULADA <TooltipModal text={'Depreciación acumulada de la batería en cada año de proyección del modelo'} title={'Depreciación acumulada'} /></h5>
            <br />
            <table className="table table-striped-columns p-2">
              <tbody>
                <tr>
                  <td className="table-head">Año</td>
                  <td className="table-head">Proyección</td>
                </tr>
                {data[0].acomulatedNegligibleProyected.map((datas, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>$ <NumberFormatter number={datas} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="child-box">
            <h5>VALOR ACTUAL DE BATERÍA <TooltipModal text={'Valor actualizado de la batería en uso del vehículo en cada año de proyección y depreciación. En el año en que finalice la vida útil de la batería, se tendrá el valor residual calculado'} title={'Valor actual de la batería'} /></h5>
            <br />
            <table className="table table-striped-columns p-2">
              <tbody>
                <tr>
                  <td className="table-head">Año</td>
                  <td className="table-head">Proyección</td>
                </tr>
                {data[0].actualBateryValue.map((datas, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>$ <NumberFormatter number={datas} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="child-box">
            <h5>VALOR DE CAMBIO DE BATERÍA <TooltipModal text={'Corresponde al valor y al año en que se debe realizar un cambio de batería de tracción, ya que la actual llegó al fin de su vida útil y se requiere de una adicional. Si el tiempo de vida útil es mayor al tiempo del modelo financiero, todos los valores estarán en 0'} title={'Valor de cambio de batería'} /></h5>
            <br />
            <table className="table table-striped-columns p-2">
              <tbody>
                <tr>
                  <td className="table-head">Año</td>
                  <td className="table-head">Proyección</td>
                </tr>
                {data[0].changeBateryValueProyected.map((datas, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>$ <NumberFormatter number={datas} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="child-box">
            <h5>VALOR DE VENTA DE BATERÍA <TooltipModal text={'Corresponde al valor por el cual se puede vender la batería actual, la cual, aunque ha finalizado su tiempo de vida útil en el vehículo, cuenta con un valor residual que permite su venta en el mercado'} title={'Valor de venta de batería'} /></h5>
            <br />
            <table className="table table-striped-columns p-2">
              <tbody>
                <tr>
                  <td className="table-head">Año</td>
                  <td className="table-head">Proyección</td>
                </tr>
                {data[0].saleBateryValueProyected.map((datas, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>$ <NumberFormatter number={datas} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <h4 className='big-result-text'>Valor total de adquisición de batería: $ <NumberFormatter number={totalSumChange - totalSumSell} /> <TooltipModal text={'Valor total para compra de batería de nueva, con la posibilidad de venta de la batería actual'} title={'Valor total de adquisición de batería'} /></h4>
        <br /><br />
        <div className="col-sm-6">
            <div className="title">
              <h4 className='subtitle'>VEHÍCULO A COMBUSTIÓN <TooltipModal text={'En esta sección se analiza el costo de revisiones y/o reparaciones del motor de combustión, como equivalencia a un cambio de batería de tracción en el vehículo eléctrico'} title={'Vehículo a combustión'} /></h4>
              <br />
            </div>
            <h5>COSTO DE REPARACIÓN Y MANTENIMIENTO</h5>
            <br />
            <table className="table table-striped-columns p-2">
              <tbody>
                <tr>
                  <td className="table-head">Año</td>
                  <td className="table-head">Proyección</td>
                </tr>
                {data[0].fixValueProyected.fixProyected.map((datas, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>$ <NumberFormatter number={datas} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        <br />
        
        <h4 className='big-result-text'>Valor total de reparación de motor: $ <NumberFormatter number={data[0].fixValueProyected['totalFixProyected']} /></h4>
        <br />
        <div className="charts">
          <div className="chart">
            <BarChart name={'Comparativa de valores'} data={{
              categories: ['Total'],
              series: [
                {
                  name: 'Eléctrico',
                  data: [(totalSumChange - totalSumSell)],
                },
                {
                  name: 'Combustión',
                  data: [data[0].fixValueProyected['totalFixProyected']],
                }
              ],
            }} />
          </div>
        </div>
        <br /><br />
      </>
    )}
    </div>
  )
}

export default TableBatery