import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import Spinner from '../../components/spinner/Spinner';
import TitleComponent from '../../components/titleComponent/TitleComponent';
import TableMaintenance from './components/TableMaintenance';

const Maintenance = () => {
    
const params = useParams()
const type = params.type
const [cleanDataDetail, setCleanDataDetail] = useState([])
const [loading, setLoading] = useState(true)

useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("maintenance"));
    if (savedData) {
      setCleanDataDetail(savedData);
      setLoading(false)
    } else {
      setCleanDataDetail([]);
      setLoading(false)
    }
  }, []);

return (
    <div>
        <TitleComponent text={'Mantenimiento'} />
        {loading ? <Spinner /> : <TableMaintenance data={cleanDataDetail} type={type} />}
    </div>
)
}

export default Maintenance;