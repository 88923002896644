const UpperFormatt = ({ string }) => {
  const palabras = [
    { original: "camion", changed: "Camión" },
    { original: "automovil", changed: "Automóvil" },
    { original: "checked_percentage", changed: "Porcentaje de revisión" },
    { original: "autonomy_factor", changed: "Factor de autonomía" },
    { original: "operation_factor", changed: "Factor de operación" },
    { original: "combustion_engine_efficiency", changed: "Eficiencia del motor de combustión" },
    { original: "gasoline_energy", changed: "Energía en relación a la gasolina [kWh/gl]" },
    { original: "diesel_energy", changed: "Energía en relación al diésel [kWh/gl]" },
    { original: "emision_factor_gasoline", changed: "Factor de emisión de la gasolina [kg CO2/GJ] " },
    { original: "emision_factor_diesel", changed: "Factor de emisión del diésel [kg CO2/GJ] " },
    { original: "young_tree", changed: "Factor de árbol joven [kg CO2/año]" },
    { original: "old_tree", changed: "Factor de árbol maduro [kg CO2/año]" },
    { original: "base_weight", changed: "Peso base [kg]" },
    { original: "base_km", changed: "Kilometraje base [km]" },
    { original: "cell_fuel_eficiency_factor", changed: "Factor de eficiencia en celda de combustible" },
    { original: "compresor_eficiency_factor", changed: "Factor de eficiencia compresión - suministro de H2" },
    { original: "electrolysis_eficiency_factor", changed: "Factor de eficiencia en la electrólisis" },
    { original: "hydrogen_energy_density", changed: "Densidad energética del hidrógeno [kWh/kg]" },
    { original: "water_h2_weight", changed: "Factor de cantidad de agua por peso de H2 [lt/kg]" },
    { original: "km_checked", changed: "Kilometraje de revisión [km]" },
    { original: "residual_factor", changed: "Factor residual [%]" },
    { original: "oil_compresor", changed: "Aceite del compresor" },
    { original: "oil_direction", changed: "Aceite de la dirección" },
    { original: "oil_filter_compresor", changed: "Filtro de aceite del compresor" },
    { original: "air_compresor_entry_filter", changed: "Filtro de entrada del compresor de aire" },
    { original: "air_dryer_filter", changed: "Filtro del secador de aire" },
    { original: "oil_direction_filter", changed: "Filtro de dirección del aceite" },
    { original: "water_separators_filter", changed: "Filtro de separadores de agua" },
    { original: "oil_engine", changed: "Aceite del motor" },
    { original: "oil_filter_engine", changed: "Filtro de aceite del motor" },
    { original: "fuel_filter", changed: "Filtro de combustible" },
    { original: "refrigerant", changed: "Refrigerante" },
    { original: "fat", changed: "Grasa" },
    { original: "pasta_back_brake", changed: "Pasta de freno trasero" },
    { original: "liquid_brakes", changed: "Líquido de frenos" },
    { original: "batery_12v", changed: "Batería de 12V" },
    { original: "pasta_front_brake", changed: "Pasta de freno delantero" },
    { original: "corrective_parts", changed: "Partes correctivas" },
    { original: "wheels_retread", changed: "Neumáticos de reencauche" },
    { original: "new_back_wheels", changed: "Nuevos neumáticos traseros" },
    { original: "new_front_wheels", changed: "Nuevos neumáticos delanteros" },
    { original: "hydrogen_energy_density", changed: "Densidad energética del hidrógeno [kWh/kg]" },
    { original: "water_h2_weight", changed: "Factor de cantidad de agua por peso de H2 [lt/kg]" },
    { original: "km_checked", changed: "Kilometraje de revisión" },
    { original: "residual_factor", changed: "Factor residual" },
    { original: "active", changed: "Activo" },
    { original: "inactive", changed: "Inactivo" },
    { original: "avg_speed", changed: "Velocidad promedio en ciudad [km/h]" },
  ];

  for (let i = 0; i < palabras.length; i++) {
    const { original, changed } = palabras[i];
    if (string.toLowerCase() === original.toLowerCase()) {
      return changed;
    }
  }

  return string; 
};

export default UpperFormatt
