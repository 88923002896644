import { Outlet, useParams } from "react-router-dom"
import Sidebar from "../../components/sidenav/Sidenav";
const Home = () => {
  const { id, type } = useParams();

  return (
    <Sidebar id={id} type={type}></Sidebar>
  );
};


export default Home;

