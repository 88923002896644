import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const TooltipModalTerms = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="link" onClick={handleShow}>
        Términos y condiciones
      </Button>

      <Modal className='modal-tooltip' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Términos y condiciones de uso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>1.	Aceptación de los Términos y Condiciones</h5>
          <p>
          Al acceder y utilizar la plataforma "Calculadora Blue", usted acepta los siguientes términos y condiciones en su totalidad. Si no está de acuerdo con alguno de estos términos, se le solicita que no utilice la plataforma.
          </p>
          <br />
          <h5>2.	Uso de la Plataforma</h5>
          <p>
          La plataforma "Calculadora Blue" es proporcionada por Auteco Blue con el propósito de ofrecer herramientas y servicios relacionados con cálculos y estimaciones. Usted acepta utilizar la plataforma únicamente para fines lícitos y de acuerdo con todas las leyes y regulaciones aplicables.
          </p>
          <br />
          <h5>3.	Responsabilidad del Usuario</h5>
          <p>
          Usted es el único responsable de la exactitud y veracidad de la información proporcionada al utilizar la plataforma "Calculadora Blue". Auteco Blue no se hace responsable por cualquier error o inexactitud en los cálculos o resultados generados a partir de la información proporcionada por el usuario.
          </p>
          <br />
          <h5>4.	Propiedad Intelectual</h5>
          <p>
          Todo el contenido de la plataforma, incluidos, entre otros, logotipos, textos, gráficos, imágenes, videos, software y otros materiales, están protegidos por derechos de autor y otras leyes de propiedad intelectual. Usted reconoce que no adquiere ningún derecho de propiedad sobre dicho contenido al utilizar la plataforma.
          </p>
          <br />
          <h5>5.	Modificaciones en los Términos y la Plataforma</h5>
          <p>
          Auteco Blue se reserva el derecho de modificar o suspender la plataforma, así como estos Términos y Condiciones, en cualquier momento y sin previo aviso. Es responsabilidad del usuario revisar periódicamente los términos actualizados.
          </p>
          <br />
          <h5>6.	Enlaces Externos</h5>
          <p>
          La plataforma puede contener enlaces a sitios web de terceros. Auteco Blue no se responsabiliza por el contenido, políticas o prácticas de privacidad de dichos sitios.
          </p>
          <br />
          <h5>7.	Uso Adecuado</h5>
          <p>
          Queda prohibido el uso de la plataforma "Calculadora Blue" para actividades ilegales, fraudulentas, difamatorias, ofensivas o que violen los derechos de terceros.
          </p>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TooltipModalTerms;
