import axios from 'axios';
import config from '../config';

const domain = config.api_domain.domain

const GetFinancialData = async (id, financing, loan, interestEM, interestEM_fuel, installments, fuelID) => {
    parseInt(fuelID)
    try {
        const url = (`${domain}/financial/${id}/${financing}/${loan}/${interestEM}/${interestEM_fuel}/${installments}/${fuelID} `)
        
        const res = await axios.get(url);
        return res.data.body;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export default GetFinancialData;