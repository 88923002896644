import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import TableDirection from './components/TableDirection';
import GetDataDetail from '../../services/loaderService';
import TitleComponent from '../../components/titleComponent/TitleComponent';
import config from '../../config';

const domain = config.api_domain.domain

const Direction = () => {
    const params = useParams()
    const id = params.id
    const url = `${domain}/direction`

    const [cleanDataDetail, setcleanDataDetail] = useState([])
      
    useEffect(() => {
        GetDataDetail(setcleanDataDetail, url, id)
    }, [id, url, setcleanDataDetail])

    return (
        <div className='content'>
            <div className=''>
                <TitleComponent text={'Dirección'} />
                <TableDirection data={cleanDataDetail} />
            </div>
        </div>
    )
}

export default Direction;