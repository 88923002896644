import React from 'react'
import DownloadPdf from '../../../components/DownloadPdf'
import NumberFormatter from '../../../components/Formarts';
import TooltipModal from '../../../components/modals/ToolTipModal';
import BarChart from '../../../components/charts/BarChart';


const TableAssurance = ({ data }) => {

  if (!data || data.length <= 0 || data.error || data === null || data === []) {
    return <p className="subtitle">Sin parámetros asignados</p>;
  }
  return (
    <div>{data.length > 0 && (
      <>
        <div className="father-box">
          <div className="child-box">
            <h4 className="subtitle">IMPUESTO DE CIRCULACIÓN <TooltipModal text={'Impuesto anual que se aplica a los vehículos automotores nuevos, usados y los que ingresen temporalmente al territorio Nacional'} title={'Impuesto de circulacíon'} /></h4>
            <br />
            <h4>Eléctrico</h4>
            <p className='big-result-text'>$ <NumberFormatter number={data[0]['electricRollTax']} /></p>
            <h4>Combustión</h4>
            <p className='big-result-text'>$ <NumberFormatter number={data[0]['fuelRollTax']} /> </p>
          </div>
          <div className="child-box">
            <h4 className="subtitle">SOAT <TooltipModal text={'Seguro obligatorio que todo usuario con un vehículo automotor debe adquirir para la circulación de este'} title={'SOAT'} /></h4>
            <br />
            <h4>Eléctrico</h4>
            <p className='big-result-text'>$ <NumberFormatter number={data[0]['SOAT']['electric']} /></p>
            <h4>Combustión</h4>
            <p className='big-result-text'>$ <NumberFormatter number={data[0]['SOAT']['fuel']} /></p>
          </div>
        </div>
        <div className="father-box">
          <div className="child-box">
          <h5>PROYECCIÓN DE IMPUESTOS Y SEGUROS PARA VEHÍCULO ELÉCTRICO</h5>
            <br />
            <table className="table table-striped-columns p-2">
              <tbody>
                <tr>
                  <td className="table-head">Año</td>
                  <td className="table-head">Proyección <TooltipModal text={'Este valor comprende el impuesto de circulación, el valor del SOAT y el seguro todo riesgo ingresado en la ventana de parámetros'} title={'Proyección'}/></td>
                </tr>
                {data[0].electricAnnualCost.valueProyected.map((datas, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>$ <NumberFormatter number={datas} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
            <h4 className='big-result-text'>Total: $ <NumberFormatter number={data[0]['electricAnnualCost']['total']} /></h4>
          </div>
          <div className="child-box">
            <h5>PROYECCIÓN DE IMPUESTOS Y SEGUROS PARA VEHÍCULO A COMBUSTIÓN</h5>
            <br />
            <table className="table table-striped-columns p-2">
              <tbody>
                <tr>
                  <td className="table-head">Año</td>
                  <td className="table-head">Proyección <TooltipModal text={'Este valor comprende el impuesto de circulación, el valor del SOAT y el seguro todo riesgo ingresado en la ventana de parámetros'} title={'Proyección'}/></td>
                </tr>
                {data[0].fuelAnnualCost.valueProyected.map((datas, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>$ <NumberFormatter number={datas} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
            <h4 className='big-result-text'>Total: $ <NumberFormatter number={data[0]['fuelAnnualCost']['total']} /></h4>
          </div>
        </div>

        <BarChart data={{
          categories: ['Aseguramiento'],
          series: [
            {
              name: 'Eléctrico',
              data: [parseFloat(data[0]['electricAnnualCost']['total'])],
            },
            {
              name: 'Combustión',
              data: [parseFloat(data[0]['fuelAnnualCost']['total'])],
            }
          ],
        }} />
      </>
    )}
      <br /><br />
    </div>
  )
}

export default TableAssurance