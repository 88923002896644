import { Outlet } from "react-router-dom"

const SubSystem = () => {
    return (
        <div className="subsistems">
        <Outlet />
        </div>
    )
}

export default SubSystem;