
import React from 'react';
import GetDataDetail from "../../../services/loaderService"
import Spinner from '../../../components/spinner/Spinner';
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import config from '../../../config';

const domain = config.api_domain.domain

const DetailVehicle = () => {
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [cleanDataDetail, setcleanDataDetail] = useState([])

    const id = params.id
    const url = `${domain}/vehicle`

    useEffect(() => {
        GetDataDetail(setcleanDataDetail, url, id)
            .then(() => setLoading(false))
    }, [setcleanDataDetail, loading])

    if (loading) {
        return <Spinner />
    }

    const data = cleanDataDetail[0]

    return (
        <div className=''>
            <h2 className='titleComponent'>Detalle del vehículo</h2>
            <br />
            <div className="container">

            <br />
            <table className='col-sm-8'>
                <tr>
                    <td>Nombre</td>
                    <td>{data.name}</td>
                </tr>
                <tr>
                    <td>Tipo</td>
                    <td>{data.type}</td>
                </tr>
                <tr>
                    <td>Combustible</td>
                    <td>{data.fuel}</td>
                </tr>
                <tr>
                    <td>Ratio de acción</td>
                    <td>{data.action_ratio}</td>
                </tr>
                <tr>
                    <td>Aspectos</td>
                    <td>{data.aspects}</td>
                </tr>
                <tr>
                    <td>Aspiración</td>
                    <td>{data.aspiration}</td>
                </tr>
                <tr>
                    <td>Autonomía</td>
                    <td>{data.autonomy}</td>
                </tr>
                <tr>
                    <td>Ejes</td>
                    <td>{data.axies}</td>
                </tr>
                <tr>
                    <td>Distancia entre ejes</td>
                    <td>{data.axies_distance}</td>
                </tr>
                <tr>
                    <td>Distancia entre ejes (tándem)</td>
                    <td>{data.axies_distance_tandem}</td>
                </tr>
                <tr>
                    <td>Marca del eje trasero</td>
                    <td>{data.back_axle_brand}</td>
                </tr>
                <tr>
                    <td>Distancia entre eje trasero y quinta rueda</td>
                    <td>{data.back_axle_distance_kingpin}</td>
                </tr>
                <tr>
                    <td>Carga en eje trasero</td>
                    <td>{data.back_axle_loaded}</td>
                </tr>
                <tr>
                    <td>Referencia del eje trasero</td>
                    <td>{data.back_axle_ref}</td>
                </tr>
                <tr>
                    <td>Peso del eje trasero</td>
                    <td>{data.back_axle_weight}</td>
                </tr>
                <tr>
                    <td>Anchura de la pista trasera</td>
                    <td>{data.back_track}</td>
                </tr>
                <tr>
                    <td>Anchura de las ruedas traseras</td>
                    <td>{data.back_weels_width}</td>
                </tr>
                <tr>
                    <td>Altura del respaldo</td>
                    <td>{data.backrest_height}</td>
                </tr>
                <tr>
                    <td>Configuración de la batería</td>
                    <td>{data.batery_config}</td>
                </tr>
                <tr>
                    <td>Marca de la carrocería</td>
                    <td>{data.bodywork_brand}</td>
                </tr>
                <tr>
                    <td>Referencia de la carrocería</td>
                    <td>{data.bodywork_ref}</td>
                </tr>
                <tr>
                    <td>Servicio de la carrocería</td>
                    <td>{data.bodywork_service}</td>
                </tr>
                <tr>
                    <td>Tipo de carrocería</td>
                    <td>{data.bodywork_type}</td>
                </tr>
                <tr>
                    <td>Altura de visibilidad inferior</td>
                    <td>{data.bottom_visibility_height}</td>
                </tr>
                <tr>
                    <td>Marca de los frenos</td>
                    <td>{data.brakes_brand}</td>
                </tr>
                <tr>
                    <td>Tipo de frenos</td>
                    <td>{data.brakes_type}</td>
                </tr>
                <tr>
                    <td>Anchura de la cabina</td>
                    <td>{data.cab_width}</td>
                </tr>
                <tr>
                    <td>Carga máxima</td>
                    <td>{data.cap_load}</td>
                </tr>
                <tr>
                    <td>Capacidad del eje trasero en suspensión</td>
                    <td>{data.cap_spsn_back_axle}</td>
                </tr>
                <tr>
                    <td>Capacidad del eje delantero en suspensión</td>
                    <td>{data.cap_spsn_front_axle}</td>
                </tr>
                <tr>
                    <td>Capacidad de almacenamiento</td>
                    <td>{data.cap_storage}</td>
                </tr>
                <tr>
                    <td>Ratios de cambio</td>
                    <td>{data.case_ratios}</td>
                </tr>
                <tr>
                    <td>Tiempo de carga 1</td>
                    <td>{data.charge_time_1}</td>
                </tr>
                <tr>
                    <td>Tiempo de carga 2</td>
                    <td>{data.charge_time_2}</td>
                </tr>
                <tr>
                    <td>Tipo de carga 1</td>
                    <td>{data.charge_type_1}</td>
                </tr>
                <tr>
                    <td>Tipo de carga 2</td>
                    <td>{data.charge_type_2}</td>
                </tr>
                <tr>
                    <td>Aprobación de chasis</td>
                    <td>{data.chassis_approval}</td>
                </tr>
                <tr>
                    <td>Clase</td>
                    <td>{data.class}</td>
                </tr>
                <tr>
                    <td>Nombre de la empresa</td>
                    <td>{data.company_name}</td>
                </tr>
                <tr>
                    <td>Sistema de refrigeración</td>
                    <td>{data.cooling_system}</td>
                </tr>
                <tr>
                    <td>Anchura del pasillo</td>
                    <td>{data.corridor_width}</td>
                </tr>
                <tr>
                    <td>Ciclos</td>
                    <td>{data.cycles}</td>
                </tr>
                <tr>
                    <td>Cilindros</td>
                    <td>{data.cylinders}</td>
                </tr>
                <tr>
                    <td>Marca de la dirección</td>
                    <td>{data.direction_brand}</td>
                </tr>
                <tr>
                    <td>Ubicación de la dirección</td>
                    <td>{data.direction_location}</td>
                </tr>
                <tr>
                    <td>Referencia de la dirección</td>
                    <td>{data.direction_ref}</td>
                </tr>
                <tr>
                    <td>Tipo de dirección</td>
                    <td>{data.direction_type}</td>
                </tr>
                <tr>
                    <td>Desplazamiento</td>
                    <td>{data.displacement}</td>
                </tr>
                <tr>
                    <td>Marca del motor</td>
                    <td>{data.engine_brand}</td>
                </tr>
                <tr>
                    <td>Configuración del motor</td>
                    <td>{data.engine_config}</td>
                </tr>
                <tr>
                    <td>Ubicación del motor</td>
                    <td>{data.engine_location}</td>
                </tr>
                <tr>
                    <td>Referencia del motor</td>
                    <td>{data.engine_ref}</td>
                </tr>
                <tr>
                    <td>Fax</td>
                    <td>{data.fax}</td>
                </tr>
                <tr>
                    <td>Marca de la característica</td>
                    <td>{data.feature_brand}</td>
                </tr>
                <tr>
                    <td>Anchura del bastidor</td>
                    <td>{data.frame_width}</td>
                </tr>
                <tr>
                    <td>Altura libre de la puerta</td>
                    <td>{data.free_door_height}</td>
                </tr>
                <tr>
                    <td>Anchura libre de la puerta</td>
                    <td>{data.free_door_width}</td>
                </tr>
                <tr>
                    <td>Marca del eje delantero</td>
                    <td>{data.front_axle_brand}</td>
                </tr>
                <tr>
                    <td>Carga en eje delantero</td>
                    <td>{data.front_axle_loaded}</td>
                </tr>
                <tr>
                    <td>Referencia del eje delantero</td>
                    <td>{data.front_axle_ref}</td>
                </tr>
                <tr>
                    <td>Peso del eje delantero</td>
                    <td>{data.front_axle_weight}</td>
                </tr>
                <tr>
                    <td>Anchura de la pista delantera</td>
                    <td>{data.front_track}</td>
                </tr>
                <tr>
                    <td>Capacidad del depósito de combustible</td>
                    <td>{data.fuel_cap}</td>
                </tr>
                <tr>
                    <td>Área de la puerta de cristal</td>
                    <td>{data.glass_door_area}</td>
                </tr>
                <tr>
                    <td>Peso bruto combinado</td>
                    <td>{data.gross_weigh_combined}</td>
                </tr>
                <tr>
                    <td>Peso bruto del vehículo</td>
                    <td>{data.gvwr}</td>
                </tr>
                <tr>
                    <td>Capacidad de arranque en pendiente</td>
                    <td>{data.hill_start_ability}</td>
                </tr>
                <tr>
                    <td>Tipo de homologación</td>
                    <td>{data.homologation_type}</td>
                </tr>
                <tr>
                    <td>ID</td>
                    <td>{data.id}</td>
                </tr>
                <tr>
                    <td>ID del vehículo</td>
                    <td>{data.id_vehicle}</td>
                </tr>
                <tr>
                    <td>Voladizo interior</td>
                    <td>{data.inner_cantilever}</td>
                </tr>
                <tr>
                    <td>Altura libre interna</td>
                    <td>{data.internal_free_height}</td>
                </tr>
                <tr>
                    <td>Línea</td>
                    <td>{data.line}</td>
                </tr>
                <tr>
                    <td>Ubicación</td>
                    <td>{data.location}</td>
                </tr>
                <tr>
                    <td>Material</td>
                    <td>{data.material}</td>
                </tr>
                <tr>
                    <td>Capacidad máxima en eje trasero</td>
                    <td>{data.max_cap_back_axle}</td>
                </tr>
                <tr>
                    <td>Capacidad máxima en eje delantero</td>
                    <td>{data.max_cap_front_axle}</td>
                </tr>
                <tr>
                    <td>Carga máxima</td>
                    <td>{data.max_load}</td>
                </tr>
                <tr>
                    <td>Potencia máxima</td>
                    <td>{data.max_power}</td>
                </tr>
                <tr>
                    <td>RPM de potencia máxima</td>
                    <td>{data.max_power_rpm}</td>
                </tr>
                <tr>
                    <td>Velocidad máxima</td>
                    <td>{data.max_speed}</td>
                </tr>
                <tr>
                    <td>Par máximo</td>
                    <td>{data.max_torque}</td>
                </tr>
                <tr>
                    <td>RPM de par máximo</td>
                    <td>{data.max_torque_rpm}</td>
                </tr>
                <tr>
                    <td>Altura mínima del suelo</td>
                    <td>{data.min_height_floor}</td>
                </tr>
                <tr>
                    <td>Modelo</td>
                    <td>{data.model}</td>
                </tr>
                <tr>
                    <td>Capacidad nominal</td>
                    <td>{data.nominal_cap}</td>
                </tr>
                <tr>
                    <td>Energía nominal</td>
                    <td>{data.nominal_energy}</td>
                </tr>
                <tr>
                    <td>Potencia nominal</td>
                    <td>{data.nominal_power}</td>
                </tr>
                <tr>
                    <td>Voltaje nominal</td>
                    <td>{data.nominal_volt}</td>
                </tr>
                <tr>
                    <td>Número de puertas</td>
                    <td>{data.num_doors}</td>
                </tr>
                <tr>
                    <td>Número de pasajeros</td>
                    <td>{data.num_passengers}</td>
                </tr>
                <tr>
                    <td>Número de tragaluces</td>
                    <td>{data.num_skylights}</td>
                </tr>
                <tr>
                    <td>Operación</td>
                    <td>{data.operation}</td>
                </tr>
                <tr>
                    <td>Marca del aparcamiento</td>
                    <td>{data.parking_brand}</td>
                </tr>
                <tr>
                    <td>Tipo de aparcamiento</td>
                    <td>{data.parking_type}</td>
                </tr>
                <tr>
                    <td>Teléfono</td>
                    <td>{data.phone}</td>
                </tr>
                <tr>
                    <td>Código postal</td>
                    <td>{data.postal_code}</td>
                </tr>
                <tr>
                    <td>Voladizo trasero</td>
                    <td>{data.rear_overhang}</td>
                </tr>
                <tr>
                    <td>Distribución de asientos</td>
                    <td>{data.seating_arrangement}</td>
                </tr>
                <tr>
                    <td>Profundidad del asiento</td>
                    <td>{data.seating_deep}</td>
                </tr>
                <tr>
                    <td>Altura del asiento</td>
                    <td>{data.seating_height}</td>
                </tr>
                <tr>
                    <td>Separación de asientos</td>
                    <td>{data.seating_separating}</td>
                </tr>
                <tr>
                    <td>Anchura del asiento</td>
                    <td>{data.seating_width}</td>
                </tr>
                <tr>
                    <td>Servicio</td>
                    <td>{data.service}</td>
                </tr>
                <tr>
                    <td>Espacio frente a los asientos</td>
                    <td>{data.space_facing_seatings}</td>
                </tr>
                <tr>
                    <td>Número de velocidades</td>
                    <td>{data.speeds}</td>
                </tr>
                <tr>
                    <td>Marca de las velocidades</td>
                    <td>{data.speeds_brand}</td>
                </tr>
                <tr>
                    <td>Referencia de las velocidades</td>
                    <td>{data.speeds_ref}</td>
                </tr>
                <tr>
                    <td>Tipo de velocidades</td>
                    <td>{data.speeds_type}</td>
                </tr>
                <tr>
                    <td>Número estándar de pasajeros</td>
                    <td>{data.stand_num_passengers}</td>
                </tr>
                <tr>
                    <td>Configuración del sistema 1</td>
                    <td>{data.system_config_1}</td>
                </tr>
                <tr>
                    <td>Configuración del sistema 2</td>
                    <td>{data.system_config_2}</td>
                </tr>
                <tr>
                    <td>Altura de visibilidad superior</td>
                    <td>{data.top_visibility_height}</td>
                </tr>
                <tr>
                    <td>Convertidor de par</td>
                    <td>{data.torque_converter}</td>
                </tr>
                <tr>
                    <td>Longitud total</td>
                    <td>{data.total_length}</td>
                </tr>
                <tr>
                    <td>Longitud total de la cabina</td>
                    <td>{data.total_length_cab}</td>
                </tr>
                <tr>
                    <td>Tracción</td>
                    <td>{data.traction}</td>
                </tr>
                <tr>
                    <td>Eje tractor</td>
                    <td>{data.tractor_axis}</td>
                </tr>
                <tr>
                    <td>Relación de ejes del tractor</td>
                    <td>{data.tractor_axle_relation}</td>
                </tr>
                <tr>
                    <td>Caja de transferencia</td>
                    <td>{data.transfer_case}</td>
                </tr>
                <tr>
                    <td>Relación de transmisión</td>
                    <td>{data.transmission_ratio}</td>
                </tr>
                <tr>
                    <td>Tipo de transmisión</td>
                    <td>{data.transmission_type}</td>
                </tr>
                <tr>
                    <td>Referencia del tren de rodaje</td>
                    <td>{data.undercarriage_ref}</td>
                </tr>
                <tr>
                    <td>Tipo de vehículo</td>
                    <td>{data.vehicle_type}</td>
                </tr>
                <tr>
                    <td>Visibilidad</td>
                    <td>{data.visibility}</td>
                </tr>
                <tr>
                    <td>Referencia de la rueda</td>
                    <td>{data.wheel_ref}</td>
                </tr>
                <tr>
                    <td>Anchura de la rueda</td>
                    <td>{data.wheel_width}</td>
                </tr>
                <tr>
                    <td>Base de rueda</td>
                    <td>{data.wheelbase}</td>
                </tr>
                <tr>
                    <td>Peso en vacío</td>
                    <td>{data.weight_empty}</td>
                </tr>
                <tr>
                    <td>Peso en carga</td>
                    <td>{data.weight_loaded}</td>
                </tr>
                <tr>
                    <td>Tipo de rueda</td>
                    <td>{data.wheeltype}</td>
                </tr>
                <tr>
                    <td>Anchura</td>
                    <td>{data.width}</td>
                </tr>
                <tr>
                    <td>Longitud</td>
                    <td>{data.length}</td>
                </tr>
            </table>
            </div>

        </div>
    )
}

export default DetailVehicle;