import React from 'react';

const NumberFormatter = ({ number }) => {
  parseFloat(number)
  const formatNumber = (number) => {
    return number.toLocaleString('es-ES', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    });
  };

  return <>{formatNumber(number)}</>;
};

export default NumberFormatter;
