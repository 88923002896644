import axios from 'axios';
import config from '../../../config'

const domain = config.api_domain.domain;

const AddUtilsService = async (endpoint, data) => {
  try {
    const url = `${domain}/admin/${endpoint}`;
    const res = await axios.post(url, data, {
      headers: { 'Content-Type': 'application/json' },
    });
    return res.data.body;

  } catch (error) {
    console.error(error);
    return null;
  }
};

export default AddUtilsService;