import React from 'react'
import NumberFormatSimple from '../../../components/Formaters/NumberFormatSimple'

const TableElectricEngine = ({data}) => {
  if (!data) {
    return <p>No data available.</p>
  }

  return (
    <table className="table table-striped-columns p-2">
      {data.map((datas, index) => (
        <tbody key={index}>
          <tr>
            <th scope="row">Marca</th>
            <td>{datas.engine_brand}</td>
          </tr>
          <tr>
            <th scope="row">Referencia</th>
            <td>{datas.engine_ref}</td>
          </tr>
          <tr>
            <th scope="row">Configuración</th>
            <td>{datas.engine_config}</td>
          </tr>
          <tr>
            <th scope="row">Potencia Nominal</th>
            <td>{datas.nominal_power}</td>
          </tr>
          <tr>
            <th scope="row">Potencia máxima</th>
            <td>{datas.max_power}</td>
          </tr>
          <tr>
            <th scope="row">Régimen a máxima potencia <span className='units-lowercase'>[rpm]</span></th>
            <td><NumberFormatSimple number={datas.max_power_rpm}/></td>
          </tr>
          <tr>
            <th scope="row">Torque máximo</th>
            <td>{datas.max_torque}</td>
          </tr>
          <tr>
            <th scope="row">Régimen a máximo torque <span className='units-lowercase'>[rpm]</span></th>
            <td><NumberFormatSimple number={datas.max_torque_rpm}/></td>
          </tr>
          <tr>
            <th scope="row">Número de cilindros y disposición</th>
            <td>{datas.cylinders}</td>
          </tr>
          <tr>
            <th scope="row">Desplazamiento <span className='units-lowercase'>[cm³]</span></th>
            <td>{datas.displacement}</td>
          </tr>
          <tr>
            <th scope="row">Combustible</th>
            <td>{datas.fuel_engine}</td>
          </tr>
          <tr>
            <th scope="row">Aspiración</th>
            <td>{datas.aspiration}</td>
          </tr>
          <tr>
            <th scope="row">Sistema de enfriamiento</th>
            <td>{datas.cooling_system}</td>
          </tr>
          <tr>
            <th scope="row">Tracción</th>
            <td>{datas.traction}</td>
          </tr>
        </tbody>
      ))}
    </table>
  )
}

export default TableElectricEngine