import React from 'react';
import './selectQuoter.css'
import LoaderAllVehicles from '../../../../services/loaderAllVehicles';
import Spinner from '../../../../components/spinner/Spinner';
import { useEffect, useState } from 'react';
import UpperFormatt from '../../../../components/UpperFormatt';

const SelectQuoter = ({ value, onChange, type }) => {

    const [loading, setLoading] = useState(true)
    const [cleanDataDetail, setcleanDataDetail] = useState([])

    const handleLoader = async () => {
        await LoaderAllVehicles(setcleanDataDetail)
        setLoading(false)
    }

    useEffect(() => {
        try {
            handleLoader()
        } catch (error) {
            console.log(error)
        }
    }, [])
    
    if (loading) {
        return <Spinner />
    }

    return (
        <div className={'selector-quoter'}>
            <select className='form-select' value={value} onChange={onChange}>
                <option value={0} selected>Seleccione un vehículo</option>
                {(cleanDataDetail.filter(item => item.state === 'active')).map((datas, index) => (
                    <option key={index} value={datas.id}>
                        <div>{datas.name} (<UpperFormatt string={datas.type} /> {datas.fuel}) </div>
                    </option>
                ))}
            </select>
        </div>
    )
}

export default SelectQuoter;