import axios from 'axios';
import config from '../config';

const domain = config.api_domain.domain

const loaderProductivity = async (id, fuel, anual_use, years_use, op_range, hour_value, pp_hours, pp_days, id_fuel, scraping) => {
    try {
        const url = (`${domain}/productivity/${id}/${fuel}/${anual_use}/${years_use}/${op_range}/${hour_value}/${pp_hours}/${pp_days}/${id_fuel}/${scraping}`)
        const res = await axios.get(url);
        return res.data.body;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export default loaderProductivity;