import axios from 'axios';
import config from '../config';

const domain = config.api_domain.domain

const loaderTotals = async (
    id,
    financialTable,
    financialTotal,
    fuelFinancialTable,
    fuelFinancialTotal,

    consuptionTable,
    consuptionTotal,
    fuelConsuptionTable,
    fuelConsuptionTotal,

    maintenanceTable,
    maintenanceTotal,
    fuelMaintenanceTable,
    fuelMaintenanceTotal,

    bateryTable,
    bateryTotal,

    assuranceTable,
    assuranceTotal,
    fuelAssuranceTable,
    fuelAssuranceTotal,

    productivityTable,
    productivityTotal,
    fuelProductivityTable,
    fuelProductivityTotal,

    consumablesTable,
    consumablesTotal,
    fuelConsumablesTable,
    fuelConsumablesTotal
) => {
    try {
        const url = (`${domain}/totals/${id}/${financialTable}/${financialTotal}/${fuelFinancialTable}/${fuelFinancialTotal}/${consuptionTable}/${consuptionTotal}/${fuelConsuptionTable}/${fuelConsuptionTotal}/${maintenanceTable}/${maintenanceTotal}/${fuelMaintenanceTable}/${fuelMaintenanceTotal}/${bateryTable}/${bateryTotal}/${assuranceTable}/${assuranceTotal}/${fuelAssuranceTable}/${fuelAssuranceTotal}/${productivityTable}/${productivityTotal}/${fuelProductivityTable}/${fuelProductivityTotal}/${consumablesTable}/${consumablesTotal}/${fuelConsumablesTable}/${fuelConsumablesTotal}`)
        const res = await axios.get(url);
        return res.data.body;
    } catch (error) {
        console.error(error)
        return null;
    }
}

export default loaderTotals;