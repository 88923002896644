import React from 'react'
import '../../../styles/sidebaraux.css'
import '../../../styles/contens.css'

const TableHomologation = ({ data }) => {
  if (!data) {
    return <p>No data available.</p>
  }
  return (
      <table className=" table table-striped-columns p-2">

      {data.map((datas, index) => (
        <tbody key={index}>
          <tr>
            <th scope="row" >Tipo de Homologación</th>
            <td >{datas.homologation_type}</td>
          </tr>
          <tr>
            <th scope="row">Clase de vehículo</th>
            <td>{datas.class}</td>
          </tr>
          <tr>
            <th scope="row">Tipo de carrocería</th>
            <td>{datas.bodywork_type}</td>
          </tr>
        </tbody>
      ))}
    </table>
  )
}

export default TableHomologation