import "../../../styles/global.css"
import NumberFormatSimple from "../../../components/Formaters/NumberFormatSimple";
import NumberFormatter from "../../../components/Formarts";

const TableAxes = ({ data }) => {
    if (!data) {
        return <p>No data available.</p>
    }

    return (
        <table className="table table-striped-columns p-2">
            {data.map((datas, index) => (
                <tbody key={index}>
                    <tr>
                        <th scope="row">Eje tractor</th>
                        <td>{datas.tractor_axis}</td>
                    </tr>
                    <tr>
                        <th scope="row">Relación eje tractor</th>
                        <td><NumberFormatter number={datas.tractor_axle_relation}/></td>
                    </tr>
                    <th className="subtitle">Eje Delantero</th>
                    <tr>
                        <th scope="row">Marca</th>
                        <td>{datas.front_axle_brand}</td>
                    </tr>
                    <tr>
                        <th scope="row">Referencia o tipo</th>
                        <td>{datas.front_axle_ref}</td>
                    </tr>
                    <tr>
                        <th scope="row">Capacidad máxima <span className='units-lowercase'>[kg]</span></th>
                        <td><NumberFormatSimple number={datas.max_cap_front_axle}/></td>
                    </tr>
                    <tr>
                        <th scope="row">Capacidad de suspensión <span className='units-lowercase'>[kg]</span></th>
                        <td><NumberFormatSimple number={datas.cap_spsn_front_axle}/></td>
                    </tr>
                    <th className="subtitle">Eje Trasero</th>
                    <tr>
                        <th scope="row">Marca</th>
                        <td>{datas.back_axle_brand}</td>
                    </tr>
                    <tr>
                        <th scope="row">Referencia o tipo</th>
                        <td>{datas.back_axle_ref}</td>
                    </tr>
                    <tr>
                        <th scope="row">Capacidad máxima <span className='units-lowercase'>[kg]</span></th>
                        <td><NumberFormatSimple number={datas.max_cap_back_axle}/></td>
                    </tr>
                    <tr>
                        <th scope="row">Capacidad de suspensión <span className='units-lowercase'>[kg]</span></th>
                        <td><NumberFormatSimple number={datas.cap_spsn_back_axle}/></td>
                    </tr>
                </tbody>
            ))}
        </table>
    );
};

export default TableAxes;