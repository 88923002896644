import React from 'react'

const TableSpeeds = ({ data }) => {
  if (!data) {
    return <p>No data available.</p>
  }
  return (
    <table className="table table-striped-columns p-2">
      {data.map((datas, index) => (
        <tbody key={index}>
          <tr>
            <th scope="row">Marca</th>
            <td>{datas.speeds_brand}</td>
          </tr>
          <tr>
            <th scope="row">Referencia</th>
            <td>{datas.speeds_ref}</td>
          </tr>
          <tr>
            <th scope="row">Tipo</th>
            <td>{datas.speeds_type}</td>
          </tr>
          <tr>
            <th scope="row">Número de velocidades</th>
            <td>{datas.speeds}</td>
          </tr>
          <tr>
            <th scope="row">Relaciones caja de transferencia</th>
            <td>{datas.transfer_case}</td>
          </tr>
          <tr>
            <th scope="row">Relaciones del convertidor de torque</th>
            <td>{datas.torque_converter}</td>
          </tr>
          <tr>
            <th scope="row">Eficiencia de transmisión [%]</th>
            <td>{datas.transmission_efficiency}</td>
          </tr>
          <tr>
            <th scope="row">Relaciones de caja</th>
            <td>{datas.case_ratios}</td>
          </tr>
        </tbody>
      ))}
    </table>
  )
}

export default TableSpeeds