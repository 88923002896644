import axios from 'axios';
import config from '../config';

const domain = config.api_domain.domain

const GetConsumptionData = async (id, fuel, anualUse, yearsUse, scraping) => {
    try {
        const url = (`${domain}/consumption/${id}/${fuel}/${anualUse}/${yearsUse}/${scraping}`)
        const res = await axios.get(url);
        return res.data.body;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export default GetConsumptionData;