import '../../../styles/contens.css';
import NumberFormatter from '../../../components/Formarts';
import TooltipModal from '../../../components/modals/ToolTipModal';
import NumberFormatSimple from '../../../components/Formaters/NumberFormatSimple';

const TableNamesQuoter = ({ datas, datas2 }) => {

    if (!datas || !datas2 || datas === null || datas2 === null || datas === [] || datas2 === [] ) {
        return <h4>Selecciona un vehículo para comparar</h4>;
    }

    return (
        <div>
            <table>
                <tbody>
                    <tr>
                        <td className=''></td>
                        <td className='ContentTables'><img src={datas["left_diagonal"]} alt="" width={'200vw'} height={'150vw'} /></td>
                        <td className='ContentTables'><img src={datas2["left_diagonal"]} alt="" width={'200vw'} height={'150vw'} /></td>
                    </tr>
                    <tr>
                        <th className=''>Combustible</th>
                        <td className='ContentTables'>{datas["fuel"]}</td>
                        <td className='ContentTables'>{datas2["fuel"]}</td>
                    </tr>
                    <tr>
                        <th className=''>Potencia máxima</th>
                        <td className='ContentTables'>{datas["max_power"]}</td>
                        <td className='ContentTables'>{datas2["max_power"]}</td>
                    </tr>
                    <tr>
                        <th className=''>Torque máximo</th>
                        <td className='ContentTables'>{datas["max_torque"]}</td>
                        <td className='ContentTables'>{datas2["max_torque"]}</td>
                    </tr>
                    <tr>
                        <th className=''>Peso bruto vehicular <span className='units-lowercase'>[kg]</span></th>
                        <td className='ContentTables'><NumberFormatSimple number={datas["gvwr"]}/></td>
                        <td className='ContentTables'><NumberFormatSimple number={datas2["gvwr"]}/></td>
                    </tr>
                    <tr>
                        <th className=''>Dirección</th>
                        <td className='ContentTables'>{datas["direction_type"]}</td>
                        <td className='ContentTables'>{datas2["direction_type"]}</td>
                    </tr>
                    <tr>
                        <th className=''>Nivel de emisiones</th>
                        <td className='ContentTables'>{datas["emissions"]}</td>
                        <td className='ContentTables'>{datas2["emissions"]}</td>
                    </tr>
                    <tr>
                        <th className=''>Capacidad de carga <span className='units-lowercase'>[kg]</span></th>
                        <td className='ContentTables'><NumberFormatSimple number={datas["max_load"]}/></td>
                        <td className='ContentTables'><NumberFormatSimple number={datas2["max_load"]}/></td>
                    </tr>
                    <tr>
                        <th className=''>Relación Peso - Potencia <TooltipModal title={'Relación Peso - Potencia'} text={'Factor que divide el peso bruto vehicular entre la potencia máxima en kW'}/> </th>
                        <td className='ContentTables'>{datas["weight_power_relation"]}</td>
                        <td className='ContentTables'>{datas2["weight_power_relation"]}</td>
                    </tr>
                    <tr>
                        <th className=''>Relación Peso - Torque <TooltipModal title={'Relación Peso - Torque'} text={'Factor que divide el peso bruto vehicular entre el torque máximo en Nm'}/></th>
                        <td className='ContentTables'>{datas["weight_torque"]}</td>
                        <td className='ContentTables'>{datas2["weight_torque"]}</td>
                    </tr>
                    <tr>
                        <th className=''>Largo del vehículo <span className='units-lowercase'>[mm]</span></th>
                        <td className='ContentTables'><NumberFormatSimple number={datas["total_length_measure"]}/></td>
                        <td className='ContentTables'><NumberFormatSimple number={datas2["total_length_measure"]}/></td>
                    </tr>
                    <tr>
                        <th className=''>Ancho del vehículo <span className='units-lowercase'>[mm]</span></th>
                        <td className='ContentTables'><NumberFormatSimple number={datas["vehicle_width"]}/></td>
                        <td className='ContentTables'><NumberFormatSimple number={datas2["vehicle_width"]}/></td>
                    </tr>
                    <tr>
                        <th className=''>Alto del vehículo <span className='units-lowercase'>[mm]</span></th>
                        <td className='ContentTables'><NumberFormatSimple number={datas["vehicle_height"]}/></td>
                        <td className='ContentTables'><NumberFormatSimple number={datas2["vehicle_height"]}/></td>
                    </tr>
                </tbody>
            </table>
            <br /><br /><br />
        </div>
    )
}

export default TableNamesQuoter