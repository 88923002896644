import React from 'react'
import NumberFormatter from '../../../components/Formarts';
import BarChart from '../../../components/charts/BarChart';
import TooltipModal from '../../../components/modals/ToolTipModal';


const TableProductivity = ({ data }) => {
  
  if (!data || data.length <= 0 || data.error || data === null || data === []) {
    return <p className="subtitle">Sin parámetros asignados</p>;
  }

  return (
    <div>{data.length > 0 && (
      <>
        <div className="father-box">
          <div className="child-box">
            <div className="title">
              <h4 className='subtitle'>Valores de vehículo eléctrico</h4>
              <br />
            </div>

            <div className="father-box">
              <div className="child-sm-box">
              <h4 className='subtitle'>Recorrido de batería</h4>
              <br />
                <p>Días disponibles <span className='result-text'>{data[0]['aviabilityDays']}</span> <TooltipModal text={'Cantidad de días disponibles al año de acuerdo al rango de operación seleccionado en la ventana de parámetros'} title={'Días disponibles'}/></p>
                <p>Recorrido de carga completa [km] <span className='result-text'>{data[0]['fullTour']}</span> <TooltipModal text={'Recorrido que puede hacer un ciclo completo de descarga de la batería, del 100% al 20%'} title={'Recorrido de carga completa [km]'}/></p>

                <p>Cantidad de ciclos de carga al año <span className='result-text'>{data[0]['chargesPerYear']}</span> <TooltipModal text={'Cantidad de ciclos de carga que se deben realizar para cumplir el uso anual proyectado en la ventana de parámetros'} title={'Cantidad de ciclos de carga al año'}/></p>
                <p>Recorrido diario [km] <span className='result-text'><NumberFormatter number={data[0]['dailyTour']} /></span> <TooltipModal text={'Es el recorrido diario que se debe realizar para cumplir el uso anual proyectado en la ventana de parámetros, con base en los días disponibles de operación'} title={'Recorrido diario [km]'}/></p>
                <br /><br />
                <h4 className='subtitle'>Tiempos no operativos</h4>
                <br />
                <p>Tiempo extra de carga al día [h] <span className='result-text'><NumberFormatter number={data[0]['extraTime']} /></span> <TooltipModal text={'Tiempo que se debe tomar la batería para cargar al día, con el objetivo de cumplir con el recorrido diario mínimo'} title={'Tiempo extra de carga al día [h]'}/></p>
                <p>Pérdidas por ciclo de carga del vehículo [$] <span className='result-text'>$ <NumberFormatter number={data[0]['lossCharge']} /></span> <TooltipModal text={'Valor de pérdidas por tiempos no operativos en cada ciclo de carga del vehículo'} title={'Pérdidas por ciclo de carga del vehículo [$]'}/></p>
              </div>
              <div className="child-sm-box">
              <h4 className='subtitle'>Disponibilidad diaria <TooltipModal text={'Tiempos operativos fuera de los procesos de carga del vehículo'} title={'Disponibilidad diaria'}/></h4>
              <br />
              <p>Con carga lenta [h] <span className='result-text'><NumberFormatter number={data[0]['aviabilityChargeSlow']}/></span></p>
                <p>Porcentaje de disponiblidad diaria carga lenta [%] <span className='result-text'><NumberFormatter number={data[0]['aviabilityPercentageSlow'] * 100} /></span></p>

                <p>Con carga rápida [h] <span className='result-text'>{data[0]['charge_time_2'] === null ? 'N/A' : <NumberFormatter number={data[0]['aviabilityChargeFast']} />}</span> </p>
                <p>Porcentaje de disponiblidad diaria carga rápida [%] <span className='result-text'>{data[0]['charge_time_2'] === null ? 'N/A' : <NumberFormatter number={data[0]['aviabilityPercentageFast'] * 100}/>}</span></p>

              </div>
            </div>

            <div className="father-box">
              <div className="child-sm-box">
              <h5>PROYECCIÓN DE NIVEL DE PÉRDIDAS DEL VEHÍCULO ELÉCTRICO</h5>
            <br />
                <table className="table table-striped-columns p-2">
                  <tbody>
                    <tr>
                      <td className="table-head">Año</td>
                      <td className="table-head">Valor presente <TooltipModal text={'Corresponde al Valor Presente Neto de cada uno de los años en ejecución, el cual permite establecer un costo total a día de hoy en una proyección financiera'} title={'Valor presente'}/></td>
                    </tr>
                    {data[0].annualLoss.presentValue.map((datas, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>$ <NumberFormatter number={datas} /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <br />
            <h4 className="big-result-text" >Total en pérdidas: $ <NumberFormatter number={data[0]['annualLoss']['total']} /></h4>
          </div>
          
          <div className="child-box">
            <div className="title">
              <h4 className='subtitle' >Valores de vehículo a combustión</h4>
              <br />
            </div>
            <div className="father-box">
              <div className="child-sm-box">
              <h4 className='subtitle'>Parámetros generales</h4>
              <br />
              <p>Consumo de energía [kWh/km] <span className='result-text'><NumberFormatter number={data[0]['combustionConsumption']} /></span> </p>
              <p>Rendimiento de combustible [km/gl] <span className='result-text'><NumberFormatter number={data[0]['fuelEfficiency']} /></span> </p>
              <p>Recorrido de tanque lleno [km] <span className='result-text'><NumberFormatter number={data[0]['tourFullTank']} /></span> <TooltipModal text={'Autonomía nominal del vehículo'} title={'Recorrido de tanque lleno [km]'}/></p>
              <p>Número de tanqueadas completas al año <span className='result-text'>{data[0]['tanksAmount']}</span> <TooltipModal text={'Cantidad de tanqueadas por año para cumplir con el uso anual proyectado en la ventana de parámetros'} title={'Número de tanqueadas completas al año'}/></p>
              <p>Duración del tanque completo [días] <span className='result-text'><NumberFormatter number={data[0]['tankDaysLife']}/></span> <TooltipModal text={'Cantidad de días con combustible con base en el recorrido diario mínimo'} title={'Duración del tanque completo [días]'}/></p>
              <p>Tanqueo de combustible diario [gl] <span className='result-text'><NumberFormatter number={data[0]['daylyTanking']}/></span> <TooltipModal text={'Cantidad de combustible al día que se debe ingresar al vehículo para evitar tiempos no operativos'} title={'Tanqueo de combustible diario [gl]'}/></p>
              </div>
              <div className="child-sm-box">
              <h4 className='subtitle'>Tiempos no operativos</h4>
              <br />
                <p>Tiempo de llenado del tanque [h] <span className='result-text'><NumberFormatter number={data[0]['tankingTime']} /></span> <TooltipModal text={'Tiempo que dura un ciclo completo de llenado del tanque de combustible'} title={'Tiempo de llenado del tanque [h]'}/></p>
                <p>Tiempo disponible de operación [h] <span className='result-text'><NumberFormatter number={data[0]['aviabilityTime']} /></span> <TooltipModal text={'Tiempo al día disponible exceptuando el tiempo de llenado del tanque'} title={'Tiempo disponible de operación [h]'}/></p>
                <p>Porcentaje disponible de operación [%] <span className='result-text'><NumberFormatter number={data[0]['fuelAviabilityPercentage'] * 100} /></span> </p>
                <p>Tiempo de tanqueo diario [h] <span className='result-text'><NumberFormatter number={data[0]['daylyTankingTime']}/></span> <TooltipModal text={'Tiempo en que toma el vehículo hacer el tanqueo de combustible diario'} title={'Tiempo de tanqueo diario [h]'}/></p>
                <p>Tiempo de pico y placa [h] <span className='result-text'><NumberFormatter number={data[0]['ppTime']}/></span> <TooltipModal text={'Tiempo no operativo al día por penalización de pico y placa, con base en las normativas vigentes de cada ciudad'} title={'Tiempo de pico y placa [h]'}/></p>
                <p>Pérdidas por tiempos no operativos al día [$] <span className='result-text'>$ <NumberFormatter number={data[0]['lossPerDay']}/></span></p>
              </div>
            </div>

            <div className="father-box">
              <div className="child-sm-box">
              <h5>PROYECCIÓN DE NIVEL DE PÉRDIDAS DEL VEHÍCULO A COMBUSTIÓN</h5>
            <br />
                <table className="table table-striped-columns p-2">
                  <tbody>
                    <tr>
                      <td className="table-head">Año</td>
                      <td className="table-head">Valor presente <TooltipModal text={'Corresponde al Valor Presente Neto de cada uno de los años en ejecución, el cual permite establecer un costo total a día de hoy en una proyección financiera'} title={'Valor presente'}/></td>
                    </tr>
                    {data[0].fuelAnnualLoss.proyectedValue.map((datas, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>$ <NumberFormatter number={datas} /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <br />
            <h4 className="big-result-text" >Total en pérdidas: $ <NumberFormatter number={data[0]['fuelAnnualLoss']['total']} /></h4>
          </div>
        </div>


        <BarChart data={{
          categories: ['Productividad'],
          series: [
            {
              name: 'Eléctrico',
              data: [parseFloat(data[0]['annualLoss']['total'])],
            },
            {
              name: 'Combustión',
              data: [parseFloat(data[0]['fuelAnnualLoss']['total'])],
            }
          ],
        }} />
      </>
    )}
      <br /><br />
    </div>
  )
}

export default TableProductivity;