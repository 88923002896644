import { useState, useEffect } from 'react';
import Spinner from '../../components/spinner/Spinner';
import TableFinancial from './components/TableFinancial';
import TitleComponent from '../../components/titleComponent/TitleComponent'


const Financial = () => {
    const [cleanDataDetail, setCleanDataDetail] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const savedData = JSON.parse(localStorage.getItem("financial"));
        if (savedData) {
          setCleanDataDetail(savedData);
          setLoading(false)
        } else {
          setCleanDataDetail([]);
          setLoading(false)
        }
      }, []);

    return (
        <div>
            <TitleComponent text={'Compra'} />
            {loading ? <Spinner /> : <TableFinancial data={cleanDataDetail} />}
        </div>
    )
}

export default Financial;