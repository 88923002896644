import React, { useState, useEffect } from 'react';
import UpdateService from '../../../../services/admin/updates/updateService';
import GetDataDetail from '../../../../services/loaderService';
import { BiCheck } from 'react-icons/bi';
import config from '../../../../config';
import TooltipModal from '../../../../components/modals/ToolTipModal';

const UpdateEngine = ({ id, fields }) => {

    const [datas, setDatas] = useState([]);
    const domain = config.api_domain.domain;
    const url = `${domain}/engine`;

    useEffect(() => {
        GetDataDetail(setDatas, url, id);
    }, [setDatas, url, id]);

    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (datas.length > 0) {
            setFormData({
                engine_brand: datas[0].engine_brand || "",
                engine_ref: datas[0].engine_ref || "",
                engine_config: datas[0].engine_config || "",
                nominal_power: datas[0].nominal_power || "",
                max_power: datas[0].max_power || "",
                max_power_rpm: (datas[0].max_power_rpm === null ? 0 : datas[0].max_power_rpm),
                max_torque: datas[0].max_torque || "",
                max_torque_rpm: datas[0].max_torque_rpm === null ? 0 : datas[0].max_torque_rpm,
                cylinders: datas[0].cylinders === null ? 0 : datas[0].cylinders,
                displacement: datas[0].displacement || "",
                fuel_engine: datas[0].fuel_engine || "",
                aspiration: datas[0].aspiration || "",
                cooling_system: datas[0].cooling_system || "",
                traction: datas[0].traction || "",
                weight_power_relation: datas[0].weight_power_relation || "",
                weight_torque: datas[0].weight_torque || ""
            });
        }
    }, [datas]);


    const [showForm, setShowForm] = useState(false);
    const [showUpdatedText, setShowUpdatedText] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        UpdateService('updateEngine', id, formData)
            .then(() => {
                setShowForm(false);
                setShowUpdatedText(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleOpenForm = () => {
        setShowForm(true);
        setShowUpdatedText(false);
    };

    return (
        <div>
            {showForm ? (
                <>
                    <div>
                        <label className='modal-lavel' hidden={fields}>
                            Marca
                            <input
                                type="text"
                                className='form-control'
                                name="engine_brand"
                                value={formData.engine_brand || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        <label className='modal-lavel' hidden={fields}>
                            Referencia
                            <input
                                type="text"
                                className='form-control'
                                name="engine_ref"
                                value={formData.engine_ref || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        <label className='modal-lavel' hidden={fields}>
                            Configuración del motor
                            <input
                                type="text"
                                className='form-control'
                                name="engine_config"
                                value={formData.engine_config || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        <label className='modal-lavel' hidden={fields}>
                            Potencia nominal
                            <input
                                type="text"
                                className='form-control'
                                name="nominal_power"
                                value={formData.nominal_power || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        <label className='modal-lavel'>
                            Potencia máxima <TooltipModal text={'Escribir valor en kW y HP, formato ### kW / ### HP'} title={'Potencia máxima'} />
                            <input
                                type="text"
                                className='form-control'
                                name="max_power"
                                value={formData.max_power || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        <label className='modal-lavel' hidden={fields}>
                            Régimen a máxima potencia [rpm]
                            <input
                                type="number"
                                className='form-control'
                                name="max_power_rpm"
                                value={formData.max_power_rpm === null ? 0 : formData.max_power_rpm}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        <label className='modal-lavel'>
                            Torque máximo <TooltipModal text={'Escribir valor en kgm y Nm, formato ### kgm / ### Nm'} title={'Torque máximo'} />
                            <input
                                type="text"
                                className='form-control'
                                name="max_torque"
                                value={formData.max_torque || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        <label className='modal-lavel' hidden={fields}>
                            Régimen a máximo torque [rpm]
                            <input
                                type="number"
                                className='form-control'
                                name="max_torque_rpm"
                                value={formData.max_torque_rpm === null ? 0 : formData.max_torque_rpm}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        <label className='modal-lavel'>
                            Cilindraje [c.c] <TooltipModal text={'En los vehículos eléctricos poner valor 0'} title={'Cilindraje'} />
                            <input
                                type="number"
                                className='form-control'
                                name="cylinders"
                                value={formData.cylinders === null ? 0 : formData.cylinders}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        <label className='modal-lavel' hidden={fields}>
                            Desplazamiento <span className='units-lowercase'>[cm³] </span>
                            <input
                                type="text"
                                className='form-control'
                                name="displacement"
                                value={formData.displacement || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        <label className='modal-lavel' hidden={fields}>
                            Tipo de combustible
                            <input
                                type="text"
                                className='form-control'
                                name="fuel_engine"
                                value={formData.fuel_engine || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        <label className='modal-lavel' hidden={fields}>
                            Aspiración
                            <input
                                type="text"
                                className='form-control'
                                name="aspiration"
                                value={formData.aspiration || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        <label className='modal-lavel' hidden={fields}>
                            Sistema de enfriamiento
                            <input
                                type="text"
                                className='form-control'
                                name="cooling_system"
                                value={formData.cooling_system || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        <label className='modal-lavel' hidden={fields}>
                            Tracción
                            <input
                                type="text"
                                className='form-control'
                                name="traction"
                                value={formData.traction || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        <label className='modal-lavel'>
                            Relación peso potencia <TooltipModal text={'Factor que divide el peso bruto vehicular entre la potencia máxima en kW'} title={'Relación Peso - Potencia'} />
                            <input
                                type="text"
                                className='form-control'
                                name="weight_power_relation"
                                value={formData.weight_power_relation || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        <label className='modal-lavel'>
                            Relación peso torque <TooltipModal text={'Factor que divide el peso bruto vehicular entre el torque máximo en Nm'} title={'Relación Peso - Torque'} />
                            <input
                                type="text"
                                className='form-control'
                                name="weight_torque"
                                value={formData.weight_torque || ""}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        <br /><br />
                        <button onClick={handleSubmit}>Actualizar Motor</button>
                    </div>
                </>
            ) : (
                <>
                    <p>Motor actualizado <BiCheck style={{ fontSize: '30px', color: 'green' }} /></p>
                    <button onClick={handleOpenForm}>Editar Motor</button>
                </>
            )}
        </div>
    );
};

export default UpdateEngine;
