import React from 'react'

const TableDirection = ( {data} ) => {
    if (!data) {
        return <p>No data available.</p>
    }
      
  return (
    <table className="table table-striped-columns p-2">
    {data.map((datas, index) => (
      <tbody key={index}>
        <tr>
          <th scope="row">Marca</th>
          <td>{datas.direction_brand}</td>
        </tr>
        <tr>
          <th scope="row">Tipo</th>
          <td>{datas.direction_type}</td>
        </tr>
        <tr>
          <th scope="row">Referencia</th>
          <td>{datas.direction_ref}</td>
        </tr>
      </tbody>
    ))}
  </table>
  )
}

export default TableDirection