import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import axios from 'axios';
import config from '../../config';

const domain = config.api_domain.domain
const ElectriSystem = () => {
    const params = useParams()
    const [cleanDataDetail, setcleanDataDetail] = useState([])
    const getDataDetail = async () => {
        const id = params.id
        const url = `${domain}/vehicle/${id}`
        const res = await axios.get(url)
        setcleanDataDetail(res.data.body)
      }
      
    useEffect(() => {
        getDataDetail()
    })
    return (
        <div className='text-dark'>
        <h1>Sistema Electrico</h1>
        {cleanDataDetail.map((datas, index) => (
            <div key={index}>
                <p className={datas.type === 'Moto' ? 'text-success' : 'text-danger'} >Id: {datas.id_vehicle} - <span>Name: {datas.type}</span></p>
            </div>
        ))}
        </div>
    )
}

export default ElectriSystem;