import React, { useState, useEffect } from 'react';
import UpdateService from '../../../../services/admin/updates/updateService';
import GetDataDetail from '../../../../services/loaderService';
import { BiCheck } from 'react-icons/bi';
import config from '../../../../config';
import TooltipModal from '../../../../components/modals/ToolTipModal';

const UpdateSpeeds = ({ id }) => {
const [datas, setDatas] = useState([]);
const domain = config.api_domain.domain;
const url = `${domain}/speeds`;

useEffect(() => {
    GetDataDetail(setDatas, url, id);
}, [setDatas, url, id]);

const [formData, setFormData] = useState({});

useEffect(() => {
    if (datas.length > 0) {
        setFormData({
            speeds_brand: datas[0].speeds_brand || "",
            speeds_ref: datas[0].speeds_ref || "",
            speeds_type: datas[0].speeds_type || "",
            speeds: datas[0].speeds,
            transfer_case: datas[0].transfer_case || "",
            torque_converter: datas[0].torque_converter || "",
            transmission_efficiency: datas[0].transmission_efficiency,
            case_ratios: datas[0].case_ratios || "",
        });
    }
}, [datas]);

const [showForm, setShowForm] = useState(false);
const [showUpdatedText, setShowUpdatedText] = useState(true);

const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
};

const handleSubmit = (e) => {
    e.preventDefault();

    UpdateService('updateSpeeds', id, formData)
        .then(() => {
            setShowForm(false);
            setShowUpdatedText(true);
        })
        .catch((error) => {
            console.log(error);
        });
};

const handleOpenForm = () => {
    setShowForm(true);
    setShowUpdatedText(false);
};

return (
    <div>
        {showForm ? (
            <>
                <div>
                    <label className='modal-lavel'>
                        Marca
                        <input
                            type="text"
                            className='form-control'
                            name="speeds_brand"
                            value={formData.speeds_brand}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    
                    <label className='modal-lavel'>
                        Referencia
                        <input
                            type="text"
                            className='form-control'
                            name="speeds_ref"
                            value={formData.speeds_ref}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    
                    <label className='modal-lavel'>
                        Tipo
                        <input
                            type="text"
                            className='form-control'
                            name="speeds_type"
                            value={formData.speeds_type}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    
                    <label className='modal-lavel'>
                    Número de velocidades
                        <input
                            type="number"
                            className='form-control'
                            name="speeds"
                            value={formData.speeds}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    
                    <label className='modal-lavel'>
                    Relaciones caja de transferencia 
                        <input
                            type="text"
                            className='form-control'
                            name="transfer_case"
                            value={formData.transfer_case}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    
                    <label className='modal-lavel'>
                    Relaciones del convertidor de torque 
                        <input
                            type="text"
                            className='form-control'
                            name="torque_converter"
                            value={formData.torque_converter}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    
                    <label className='modal-lavel'>
                    Eficiencia de transmisión [%]
                        <input
                            type="number"
                            className='form-control'
                            name="transmission_efficiency"
                            value={formData.transmission_efficiency}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    
                    <label className='modal-lavel'>
                    Relaciones de caja
                        <input
                            type="text"
                            className='form-control'
                            name="case_ratios"
                            value={formData.case_ratios}
                            onChange={handleChange}
                        />
                    <br /><br />
                    </label>
                    
                    <button onClick={handleSubmit}>Actualizar Velocidades</button>
                </div>
            </>
        ) : null}

        {showUpdatedText ? (
            <>
                <p>Velocidades actualizadas <BiCheck style={{ fontSize: '30px', color: 'green' }} /></p>
                <button onClick={handleOpenForm}>Editar Velocidades</button>
            </>
        ) : null}
    </div>
);
};

export default UpdateSpeeds;