import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TableFeatures from "./components/TableFeatures";
import GetDataDetail from "../../services/loaderService";
import TitleComponent from "../../components/titleComponent/TitleComponent";
import config from '../../config';

const domain = config.api_domain.domain
const Features = () => {
  const params = useParams();
  const id = params.id;
  const url = `${domain}/features`;
  const [cleanDataDetail, setcleanDataDetail] = useState([]);

  useEffect(() => {
    GetDataDetail(setcleanDataDetail, url, id);
  }, [id, url, setcleanDataDetail]);

  return (
    <div className="">
      <div className="">
          <TableFeatures data={cleanDataDetail} />
      </div>
    </div>
  );
};

export default Features;
