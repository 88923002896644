import axios from 'axios';
import config from '../config';

const domain = config.api_domain.domain

const GetBateryData = async (id, type, residual_factor, annualUse, years_use, scraping) => {
    try {
        const url = (`${domain}/batery_model/${id}/${type}/${residual_factor}/${annualUse}/${years_use}/${scraping}`)
        const res = await axios.get(url);
        return res.data.body;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export default GetBateryData;