import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import GetDataDetail from '../../services/loaderService';
import TableHomologation from './components/TableHomologation';
import TitleComponent from '../../components/titleComponent/TitleComponent';
import config from '../../config';
import '../../styles/sidebaraux.css'



const domain = config.api_domain.domain
const Homologation = () => {
    const params = useParams()
    const [cleanDataDetail, setcleanDataDetail] = useState([])
    const id = params.id
    const url = `${domain}/homologation`

    useEffect(() => {
        GetDataDetail(setcleanDataDetail, url, id)
    }, [id, url, setcleanDataDetail])

    return (
        <div className="content">
            <div className='axes-table'>
                <TitleComponent text={'Homologación'} />
                <TableHomologation className='prueba' data={cleanDataDetail} />
            </div>
        </div>
    )
}

export default Homologation;