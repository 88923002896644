import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import TitleComponent from '../../components/titleComponent/TitleComponent';
import GetDataDetail from '../../services/loaderService';
import TableAxes from './components/TableAxes';
import config from '../../config';
const domain = config.api_domain.domain

const Axes = () => {
    const params = useParams()
    const url = `${domain}/axes`
    const id = params.id
    const [cleanDataDetail, setcleanDataDetail] = useState([])

    useEffect(() => {
        GetDataDetail(setcleanDataDetail, url, id)
    }, [id, url, setcleanDataDetail])
    
    return (
        <div className='content'>
            <TitleComponent text={'Ejes'} />
            <div className='axes-table'>
                <TableAxes data={cleanDataDetail} />
            </div>
        </div>
    )
}

export default Axes;