import { NavLink } from "react-router-dom"
import '../styles/sidebaraux.css'
import Cards from "./Cards";

export default function SidebarAux({ id, type }) {
  var vehicleType = null;

  if (type === "minitruck" || type === "camion") {
    vehicleType = true;
  }

  return (
    <div className="sidenav-aux">
      <div className="nav_list">
        <Cards ><NavLink to={`/home/${id}/${type}/subsystems/mecanic/${id}/bodywork/${id}`} className={vehicleType !== true ? 'nav_link_content' : 'd-none'} activeClassName="nav-li-active">Carrocería</NavLink></Cards>
        <Cards ><NavLink to={`/home/${id}/${type}/subsystems/mecanic/${id}/measures/${id}`} className="nav_link_content" activeClassName="nav-li-active">Dimensiones</NavLink></Cards>
        <Cards ><NavLink to={`/home/${id}/${type}/subsystems/mecanic/${id}/direction/${id}`} className="nav_link_content" activeClassName="nav-li-active">Dirección</NavLink></Cards>
        <Cards ><NavLink to={`/home/${id}/${type}/subsystems/mecanic/${id}/axes/${id}`} className="nav_link_content" activeClassName="nav-li-active">Ejes</NavLink></Cards>
        <Cards ><NavLink to={`/home/${id}/${type}/subsystems/mecanic/${id}/brakes/${id}`} className="nav_link_content" activeClassName="nav-li-active">Frenos</NavLink></Cards>
        <Cards ><NavLink to={`/home/${id}/${type}/subsystems/mecanic/${id}/homologation/${id}`} className="nav_link_content" activeClassName="nav-li-active">Homologación </NavLink></Cards>
        <Cards ><NavLink to={`/home/${id}/${type}/subsystems/mecanic/${id}/speeds/${id}`} className="nav_link_content" activeClassName="nav-li-active">Velocidades</NavLink></Cards>
        {/* <Cards ><NavLink to={`/home/${id}/${type}/subsystems/mecanic/${id}/company/${id}`} className="nav_link_content" activeClassName="nav-li-active">Compañia</NavLink></Cards> */}
      </div>
    </div>
  )
}
