import React, { useState, useEffect } from 'react';
import UpdateService from '../../../../services/admin/updates/updateService';
import GetDataDetail from '../../../../services/loaderService';
import { BiCheck } from 'react-icons/bi';
import config from '../../../../config';

const UpdateMeasures = ({ id, fields }) => {

    const [datas, setDatas] = useState([]);
    const domain = config.api_domain.domain;
    const url = `${domain}/features`;
    
    useEffect(() => {
        GetDataDetail(setDatas, url, id);
    }, [setDatas, url, id]);
    
    const [formData, setFormData] = useState({});
    


    useEffect(() => {
        if (datas.length > 0) {
            setFormData({
                total_length_measure: datas[0].total_length_measure === null ? 0 : datas[0].total_length_measure,
                total_length_cab: datas[0].total_length_cab === null ? 0 : datas[0].total_length_cab,
                axies_distance: datas[0].axies_distance === null ? 0 : datas[0].axies_distance,
                inner_cantilever: datas[0].inner_cantilever === null ? 0 : datas[0].inner_cantilever,
                rear_overhang: datas[0].rear_overhang === null ? 0 : datas[0].rear_overhang,
                vehicle_height: datas[0].vehicle_height === null ? 0 : datas[0].vehicle_height,
                vehicle_width: datas[0].vehicle_width === null ? 0 : datas[0].vehicle_width,
                frame_width: datas[0].frame_width === null ? 0 : datas[0].frame_width,
                cab_width: datas[0].cab_width === null ? 0 : datas[0].cab_width,
                front_track: datas[0].front_track === null ? 0 : datas[0].front_track,
                back_track: datas[0].back_track === null ? 0 : datas[0].back_track,
                min_height_floor: datas[0].min_height_floor === null ? 0 : datas[0].min_height_floor
            });
        }
    }, [datas]);
    
    const [showForm, setShowForm] = useState(false);
    const [showUpdatedText, setShowUpdatedText] = useState(true);
    
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
    
        UpdateService('updateMeasures', id, formData)
            .then(() => {
                setShowForm(false);
                setShowUpdatedText(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    
    const handleOpenForm = () => {
        setShowForm(true);
        setShowUpdatedText(false);
    };
    
    return (
        <div>
            {showForm ? (
                <>
                    <div>
                        <label className='modal-lavel'>
                            Largo total del vehículo [mm]
                            <input
                                type="number"
                                className='form-control'
                                name="total_length_measure"
                                value={formData.total_length_measure}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel' hidden={fields}>
                            Largo total de la cabina [mm]
                            <input
                                type="number"
                                className='form-control'
                                name="total_length_cab"
                                value={formData.total_length_cab}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                        Alto del vehículo [mm]
                            <input
                                type="number"
                                className='form-control'
                                name="vehicle_height"
                                value={formData.vehicle_height}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel'>
                        Ancho del vehículo [mm]
                            <input
                                type="number"
                                className='form-control'
                                name="vehicle_width"
                                value={formData.vehicle_width}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel' hidden={fields}>
                        Ancho de bastidor [mm]
                            <input
                                type="number"
                                className='form-control'
                                name="frame_width"
                                value={formData.frame_width}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel' hidden={fields}>
                        Ancho de cabina [mm]
                            <input
                                type="number"
                                className='form-control'
                                name="cab_width"
                                value={formData.cab_width}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel' hidden={fields}>
                            Distancia entre ejes [mm]
                            <input
                                type="number"
                                className='form-control'
                                name="axies_distance"
                                value={formData.axies_distance}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel' hidden={fields}>
                            Voladizo anterior [mm]
                            <input
                                type="number"
                                className='form-control'
                                name="inner_cantilever"
                                value={formData.inner_cantilever}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel' hidden={fields}>
                        Voladizo posterior [mm]
                            <input
                                type="number"
                                className='form-control'
                                name="rear_overhang"
                                value={formData.rear_overhang}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel' hidden={fields}>
                        Trocha delantera [mm]
                            <input
                                type="number"
                                className='form-control'
                                name="front_track"
                                value={formData.front_track}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel' hidden={fields}>
                        Trocha trasera [mm]
                            <input
                                type="number"
                                className='form-control'
                                name="back_track"
                                value={formData.back_track}
                                onChange={handleChange}
                            />
                        <br /><br />
                        </label>
                        
                        <label className='modal-lavel' hidden={fields}>
                        Mínima altura al piso [mm]
                            <input
                                type="number"
                                className='form-control'
                                name="min_height_floor"
                                value={formData.min_height_floor}
                                onChange={handleChange}
                            />
                        </label>
                        <br /><br />
                        <button onClick={handleSubmit}>Actualizar Dimensiones</button>
                    </div>
                </>
            ) : null}
            {showUpdatedText ? (
                <>
                    <p>Dimensiones actualizadas <BiCheck style={{ fontSize: '30px', color: 'green' }} /></p>
                    <button onClick={handleOpenForm}>Editar Dimensiones</button>
                </>
            ) : null}
        </div>
    );
    };
    
export default UpdateMeasures;
