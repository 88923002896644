import React from "react";

import LoaderUtilsService from "../../../services/admin/utils/loaderUtilsService";
import Spinner from "../../../components/spinner/Spinner";
import { useEffect, useState } from "react";
import TitleComponent from "../../../components/titleComponent/TitleComponent";
import UpperFormatt from "../../../components/UpperFormatt";
import UpdateService from "../../../services/admin/updates/updateUtils";

const AllVehiclesAdmin = () => {
    const [loading, setLoading] = useState(true);
    const [consumables, setConsumables] = useState([]);

    const options = {
        useGrouping: false,
        minimumFractionDigits: 0
    };
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await LoaderUtilsService('consumables');
                setConsumables(data.filter(item => item.km_cost > 0).sort((a, b) => a.km_cost - b.km_cost));
                setLoading(false)
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const handleEdit = (index) => {
        const updatedConsumables = [...consumables];
        updatedConsumables[index].editMode = true;
        setConsumables(updatedConsumables);
    };

    const handleUpdate = async (index) => {
        const updatedConsumables = [...consumables];
        const { id, km_cost } = updatedConsumables[index];

        try {
            let dataValue = { value: km_cost }
            await UpdateService("update_consumables", id, dataValue);
            updatedConsumables[index].editMode = false;
            setConsumables(updatedConsumables);
        } catch (error) {
            console.error(error);
        }
    };

    const handleValue = (index, e) => {
        const updatedConsumables = [...consumables];
        updatedConsumables[index].km_cost = e.target.value;
        setConsumables(updatedConsumables);
    };

    const handleCancel = (index) => {
        const updatedConsumables = [...consumables];
        updatedConsumables[index].editMode = false;
        setConsumables(updatedConsumables);
    };


    if (loading) {
        return <Spinner />;
    }

    return (
        <div>
            <TitleComponent text={'Lista de consumibles del vehículo'} />
            <br />
            <div className="container">
                <div className="">
                    <h3 className="subtitle">Lista de consumibles</h3>
                    <br />
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Costo</th>
                                <th className="col-2">Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {consumables.map((data, index) => (
                                <tr key={index}>
                                    <td><UpperFormatt string={data.item} /></td>
                                    <td col-2>                                    
                                    <input
                                        type="number"
                                        className='form-control'
                                        onChange={(e) => handleValue(index, e)}
                                        value={data.km_cost.toLocaleString('es', options).replace(/,/g, '.')}
                                        disabled={!data.editMode}
                                    /></td>
                                    <td className="col-options">
                                        {data.editMode ? (
                                            <>
                                                <button
                                                    className="btn btn-primary m-1"
                                                    onClick={() => handleUpdate(index)}
                                                >
                                                    Actualizar
                                                </button>
                                                <button
                                                    className="btn btn-secondary m-1"
                                                    onClick={() => handleCancel(index)}
                                                >
                                                    Cancelar
                                                </button>
                                            </>
                                        ) : (
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => handleEdit(index)}
                                            >
                                                Editar
                                            </button>
                                        )}
                                    </td>
                                </tr>

                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <br />
        </div>
    );
};

export default AllVehiclesAdmin;
