import axios from 'axios';
import config from '../config';

const domain = config.api_domain.domain

const UpdaterCounter = async (id, name) => {
    try {
        const url = (`${domain}/admin/counter`)
        const data = {
          "id_vehicle": id,
          "name": name
        }
        const res = await axios.post(url, data, {headers: { 'Content-Type': 'application/json' }})
        return (res.data.body)
        
    } catch (error) {
        console.error(error);
        return null;
    }
}

export default UpdaterCounter