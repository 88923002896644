import React, { useState, useEffect } from 'react';
import UpdateService from '../../../../services/admin/updates/updateService';
import GetDataDetail from '../../../../services/loaderService';
import { BiCheck } from 'react-icons/bi';
import config from '../../../../config';

const UpdateHomologation = ({ id }) => {

    const [datas, setDatas] = useState([]);
    const domain = config.api_domain.domain;
    const url = `${domain}/homologation`;

    useEffect(() => {
        GetDataDetail(setDatas, url, id);
    }, [setDatas, url, id]);

    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (datas.length > 0) {
            setFormData({
                homologation_type: datas[0].homologation_type || "",
                classData: datas[0].class || "",
                bodywork_type: datas[0].bodywork_type || ""
            });
        }
    }, [datas]);

    const [showForm, setShowForm] = useState(false);
    const [showUpdatedText, setShowUpdatedText] = useState(false);

    
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        UpdateService('updateHomologation', id, formData)
            .then(() => {
                setShowForm(false);
                setShowUpdatedText(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    
    const handleOpenForm = () => {
        setShowForm(true);
        setShowUpdatedText(false);
    };
    
    return (
        <div>
            {showForm ? (
                <>
                    <div>
                        <label className='modal-lavel'>
                            Tipo de homologación
                            <input
                                type="text"
                                className='form-control'
                                name="homologation_type"
                                value={formData.homologation_type || ""}
                                onChange={handleChange}
                            />
                        <br /><br />    
                        </label>
                        
                        <label className='modal-lavel'>
                            Clase de vehículo
                            <input
                                type="text"
                                className='form-control'
                                name="classData"
                                value={formData.classData}
                                onChange={handleChange}
                            />
                        <br /><br />    
                        </label>
                        
                        <label className='modal-lavel'>
                            Tipo de carrocería
                            <input
                                type="text"
                                className='form-control'
                                name="bodywork_type"
                                value={formData.bodywork_type || ""}
                                onChange={handleChange}
                            />
                        <br /><br />    
                        </label>
                        <br /><br />
                        <button onClick={handleSubmit}>Actualizar Homologación</button>
                    </div>
                </>
            ) : (
                <>
                    <p>Homologación actualizado <BiCheck style={{ fontSize: '30px', color: 'green' }} /></p>
                    <button onClick={handleOpenForm}>Editar Homologación</button>
                </>
            )}
        </div>
    );
};

export default UpdateHomologation;