import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";

const TableBodyWork = ({ data }) => {

  const params = useParams()
  const category = params.type
  const [showField, setShowField] = useState('false')

  useEffect(()=> {
    if (category === 'van de carga') {
      setShowField(true)
    }
    else {
      setShowField(false)
    }
  }, [category])

  if (!data) {
    return <p>No data available.</p>
  }

  return (
    <table className="table table-striped-columns p-2">
      {data.map((datas, index) => (
        <tbody key={index}>
          <tr>
            <th scope="row">Número de homologación del chasis</th>
            <td>{datas.chassis_approval}</td>
          </tr>
          <tr>
            <th scope="row">Marca</th>
            <td>{datas.bodywork_brand}</td>
          </tr>
          <tr>
            <th scope="row">Referencia</th>
            <td>{datas.bodywork_ref}</td>
          </tr>
          <tr>
            <th scope="row">Tipo</th>
            <td>{datas.bodywork_type}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Radio de acción</th>
            <td>{datas.action_ratio}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Servicio</th>
            <td>{datas.bodywork_service}</td>
          </tr>
          <tr>
            <th scope="row">Pasajeros sentados</th>
            <td>{datas.stand_num_passengers}</td>
          </tr>
          <tr>
            <th scope="row">Peso vehículo cargado eje delantero <span className='units-lowercase'>[kg]</span></th>
            <td>{datas.front_axle_loaded}</td>
          </tr>
          <tr>
            <th scope="row">Peso vehículo cargado eje trasero <span className='units-lowercase'>[kg]</span></th>
            <td>{datas.back_axle_loaded}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Número de puertas para pasajeros</th>
            <td>{datas.num_doors}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Ancho libre de puertas <span className='units-lowercase'>[mm]</span></th>
            <td>{datas.free_door_width}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Altura libre de puertas <span className='units-lowercase'>[mm]</span></th>
            <td>{datas.free_door_height}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Altura interna libre <span className='units-lowercase'>[mm]</span></th>
            <td>{datas.internal_free_height}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Altura de visibilidad superior <span className='units-lowercase'>[mm]</span></th>
            <td>{datas.top_visibility_height}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Altura de visibilidad inferior <span className='units-lowercase'>[mm]</span></th>
            <td>{datas.bottom_visibility_height}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Ancho del pasillo <span className='units-lowercase'>[mm]</span></th>
            <td>{datas.corridor_width}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Ancho de llantas traseras <span className='units-lowercase'>[mm]</span></th>
            <td>{datas.back_weels_width}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Ubicación del motor</th>
            <td>{datas.engine_location}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Ubicación de la dirección</th>
            <td>{datas.direction_location}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Disposición de los asientos</th>
            <td>{datas.seating_arrangement}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Separación de los asientos <span className='units-lowercase'>[mm]</span></th>
            <td>{datas.space_facing_seatings}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Espacio asientos enfrentados <span className='units-lowercase'>[mm]</span></th>
            <td>{datas.seating_separating}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Profundidad de los asientos <span className='units-lowercase'>[mm]</span></th>
            <td>{datas.seating_deep}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Ancho del asiento <span className='units-lowercase'>[mm]</span></th>
            <td>{datas.seating_width}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Altura del asiento <span className='units-lowercase'>[mm]</span></th>
            <td>{datas.seating_height}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Altura del espaldar <span className='units-lowercase'>[mm]</span></th>
            <td>{datas.backrest_height}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Área en vidrio de puertas <span className='units-lowercase'>[%]</span></th>
            <td>{datas.glass_door_area}</td>
          </tr>
          <tr hidden={showField}>
            <th scope="row">Número de claraboyas</th>
            <td>{datas.num_skylights}</td>
          </tr>
          <tr>
            <th scope="row">Capacidad de bodega <span className='units-lowercase'>[m³]</span></th>
            <td>{datas.cap_storage}</td>
          </tr>
          <tr>
            <th scope="row">Capacidad de carga <span className='units-lowercase'>[kg]</span></th>
            <td>{datas.cap_load}</td>
          </tr>
          <tr>
            <th scope="row">Centro de gravedad del equipo <span className='units-lowercase'>[mm]</span></th>
            <td>{datas.gravity_center_device}</td>
          </tr>
          <tr>
            <th scope="row">Centro de gravedad de la carga <span className='units-lowercase'>[mm]</span></th>
            <td>{datas.gravity_center_load}</td>
          </tr>
        </tbody>
      ))}
    </table>
  )
}

export default TableBodyWork